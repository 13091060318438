@import "styles/constants";

.system-node-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
    border: 3px solid $nodeSourceBorderColor;
    color: white;
    background: $mainLightGreyColor;
    border-radius: 17px;
    margin: 5px;
    font-size: 12px;

    @each $nodeStatus in $nodeStatuses {
        &.system-node-status-#{$nodeStatus} {
            background: map-get($colorByNodeStatus, $nodeStatus);
        }
    }

    &.system-node-status-size-sm {
        width: 25px;
        height: 25px;
        min-width: 25px;
        min-height: 25px;
        border-radius: 14px;
        font-size: 10px;
        line-height: 11px;
    }

    &.system-node-status-size-xl {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        border-radius: 25px;
    }
}

.legend-item-profile {
    border: solid 5px $profileNodeColor;
}

.legend-item-sc {
    border: solid 5px $scNodeColor;
}

.legend-item-skill {
    border: solid 5px $skillNodeColor;
}

.legend-item-domain {
    border: solid 5px $domainNodeColor;
}

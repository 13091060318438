.block-loader {
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: rgba(202, 201, 201, 0.137);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@import "styles/constants";

.applicant-jobs-container {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    > div {
        display: flex;
        flex-direction: row;
    }
    &__title {
        margin: 2rem 0;
        text-align: center;
        color: $colorPrimary;
        font-size: 30px;
        font-weight: bold;
        white-space: pre-line;
    }
    .inner-block-container {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #a9a9a9;
        background-color: #ffffff;
        border-radius: 5px;
        padding: 10px;
        margin: 5px;
        height: 100%;
        position: relative;
    }
    .delimiter {
        width: 80%;
        height: 2px;
        background-color: #75b444;
        align-self: center;
        margin: 1rem 0;
    }
}

.section {
    &.application-overview,
    &.rich-media,
    &.job-details {
        width: 50%;
        > .block {
            justify-content: center;
        }
    }
    &.skills-map {
        width: 50%;
        > .block {
            justify-content: space-between;
            > .skills-map {
                height: 60%;
            }
        }
    }
    &.rich-media {
        > .block {
            justify-content: center;
        }
    }
    > .block {
        position: relative;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin: 5px;
        padding: 10px;
        border: 1px solid #a9a9a9;
        border-radius: 5px;
        background-color: #f9f9f9;
        justify-content: flex-start;
        > h6 {
            text-align: center;
            margin: 1rem 0;
            color: $brainChainLimeColor;
            position: absolute;
            top: -25px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 1) 50%, #f9f9f9 50%);
            padding: 0 1rem;
        }
        .form-group {
            margin: 2rem 0;
        }
    }
}

.main-container {
    display: flex;
    flex-direction: row;
    width: 75%;
}

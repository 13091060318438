@import "styles/constants";

.modal-content-wrapper {
  display: flex;
  flex-direction: row;

  .buttons-pane {
    display: flex;
    flex-direction: column;

    &.candidate-pane {
      flex-direction: column;

      .pane-button {
        flex-direction: column;

        &:first-child {
          border-top-left-radius: 0.3rem;
          border-top-right-radius: 0.3rem;
        }

        &:last-child {
          border-bottom-left-radius: 0.3rem;
          border-bottom-right-radius: 0.3rem;
        }

        &:not(:last-child) {
          border-bottom: 0;
        }
      }
    }

    .pane-button {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      border: 1px solid $mainLightGreyColor;
      color: $defaultFontColor;
      cursor: pointer;

      &:hover:not(.active) {
        background: #e8e8e8;
      }

      &.active {
        background: $mainLightColor;
      }

      .pane-button-label {
        font-size: 12px;
      }

      &.ready-for-drop {
        z-index: 10000;
      }

      &.pane-title {
        cursor: default;
        padding: 0 3px;
        font-weight: 600;
        font-size: 12px;

        &:hover {
          background: #f9f9f9;
        }
      }

      &.with-link {
        padding: 0;
      }
    }

    .pane-button-link {
      flex-direction: column;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: $defaultFontColor;
    }

    .with-bottom-margin {
      margin-bottom: 10px;
    }
  }
}

@import "styles/constants";

.hf1-custom-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    position: relative;
    margin: 0 20px;
    border-radius: 18px;
    border: 1px solid #343a40;
    background-color: $colorPrimary;

    .hf1-custom-switch-item {
        cursor: pointer;
        text-align: center;
        z-index: 2;
        padding: 5px 15px;
        color: white;
        display: flex;
        align-items: center;
    }

    .hf1-custom-switch-indicator {
        height: 100%;
        width: 100px;
        background: #343a40;
        border-radius: 16px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transition: left 0.5s ease, width 0.5s ease;
        box-shadow: #343a40 0px 1px 5px 1px;
    }
}

@import "styles/constants";

$columnBackgroundColor: #f9f9f9;
$cardBorder: 1px solid #a9a9a9;
$cardBoxShadow: 0 0 0 1px #a9a9a9;
$cardBorderRadius: 5px;
$cardBackgroundColor: #ffffff;
$cardPadding: 10px;
$cardMargin: 5px;
$subCardBorder: 1px solid #ced4dd;

.candidates-comparison {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 0;

    .candidates-comparison-content-container {
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 100%;
        min-height: 0;

        .content-column {
            max-height: 100%;

            &.candidates-stack-column {
                padding: 0 5px 5px 5px;
                min-width: 200px;
                max-width: 260px;
            }

            &.details-column {
                padding: 0 5px 5px 0;
                max-width: calc(100% - 260px);
            }
        }

        .candidates-stack-wrapper {
            display: flex;
            flex-direction: column;
            padding: $cardPadding;
            padding-bottom: 0;
            border: $cardBorder;
            background-color: $columnBackgroundColor;
            border-radius: $cardBorderRadius;
            height: 100%;
            max-height: 100%;
        }

        .details-wrapper {
            display: flex;
            flex-direction: column;
            padding: $cardPadding;
            border: $cardBorder;
            background-color: $columnBackgroundColor;
            border-radius: $cardBorderRadius;
            height: 100%;
            max-height: 100%;
            position: relative;
            padding: 10px;
            padding-top: 65px;
            padding-left: 110px;

            &.hard-skills-mode {
                padding-right: 110px;
            }

            &.soft-skills-mode {
                padding-right: 110px;
                padding-bottom: 65px;
            }

            .buttons-pane {
                display: flex;
                position: absolute;
                justify-content: center;

                &.mode-pane {
                    top: 0;
                    left: 0;
                    right: 0;

                    .pane-button-dropdown {
                        .pane-button {
                            border-top: 0;
                            border-bottom-right-radius: 3px;
                            box-shadow: none;
                        }

                        .pane-button-dropdown-item,
                        .pane-button-dropdown-item {
                            color: $defaultFontColor;
                            outline: none;
                            cursor: pointer;
                            padding: 5px 10px;
                            font-size: $smallFontSize;
                            display: flex;
                            align-items: center;

                            .icon-container {
                                margin-right: 5px;
                            }
                        }

                        .dropdown-menu {
                            outline: none;
                        }

                        .pane-button-dropdown-item.active,
                        .pane-button-dropdown-item:active {
                            color: $defaultFontColor;
                            background: #f9f9f9;
                        }

                        .comparing-mode-icon {
                            margin-right: 0;
                            margin-left: 5px;
                        }
                    }

                    > .pane-button {
                        border-top: 0;
                        border-right: 0;

                        &:first-child {
                            border-bottom-left-radius: 3px;
                        }
                    }

                    .pane-button .icon-container {
                        margin-right: 5px;
                    }
                }

                &.groups-pane {
                    flex-direction: column;
                    top: 0;
                    bottom: 0;
                    left: 0;

                    .pane-button {
                        position: relative;
                        flex-direction: column;
                        width: 100px;
                        max-width: 100px;
                        border-left: 0;
                        padding: 10px 6px;
                        padding-top: 15px;

                        &:first-child {
                            border-top-right-radius: 3px;
                        }

                        &:last-child {
                            border-bottom-right-radius: 3px;
                        }

                        &:not(:last-child) {
                            border-bottom: 0;
                        }

                        .icon-container {
                            margin-bottom: 5px;
                        }

                        .pane-button-label {
                            text-align: center;
                        }

                        .count-badge {
                            position: absolute;
                            top: 2px;
                            right: 2px;
                            padding: 3px 6px;
                        }
                    }

                    .pane-button-collapse {
                        display: flex;
                        position: absolute;
                        overflow: hidden;
                        height: calc(100% + 2px);
                        left: 100px;
                        top: -1px;
                        width: 0;
                        transition: width 0.5s ease-in-out;
                        z-index: 10;

                        .pane-sub-button {
                            position: relative;
                            flex-direction: column;
                            display: flex;
                            align-items: center;
                            background: #f9f9f9;
                            border: 1px solid $mainLightGreyColor;
                            border-left: 0;
                            padding: 10px 6px;
                            padding-top: 15px;
                            min-width: 100px;
                            width: 100px;
                            max-width: 100px;
                            height: 100%;

                            &:hover {
                                background: #e8e8e8;
                            }

                            &:last-child {
                                border-top-right-radius: 3px;
                                border-bottom-right-radius: 3px;
                            }

                            .info-icon {
                                position: absolute;
                                top: 2px;
                                right: 2px;
                            }
                        }
                    }

                    .pane-button:hover,
                    .pane-button.ready-for-drop {
                        border-bottom-right-radius: 0px !important;

                        .pane-button-collapse {
                            // TODO: remove hardcoded value
                            width: 300px;
                        }
                    }
                }

                &.soft-skills-pane {
                    bottom: 0;
                    left: 0;
                    right: 0;
                    justify-content: space-evenly;

                    .pane-buttons-container {
                        display: flex;
                    }

                    .pane-button {
                        border-bottom: 0;
                        padding: 10px 15px;

                        &:first-child {
                            border-top-left-radius: 3px;
                        }

                        &:last-child {
                            border-top-right-radius: 3px;
                        }

                        &:not(:last-child) {
                            border-right: 0;
                        }
                    }

                    .icon-container {
                        margin-right: 5px;
                    }
                }

                &.candidate-pane {
                    flex-direction: column;
                    justify-content: space-evenly;
                    top: 0;
                    bottom: 0;
                    right: 0;

                    .pane-button {
                        flex-direction: column;
                        width: 100px;
                        max-width: 100px;
                        border-right: 0;
                        padding: 10px 6px;
                        padding-bottom: 8px;

                        &:first-child {
                            border-top-left-radius: 3px;
                        }

                        &:last-child {
                            border-bottom-left-radius: 3px;
                        }

                        &:not(:last-child) {
                            border-bottom: 0;
                        }

                        .pane-button-label {
                            text-align: center;
                        }
                    }

                    .pane-button-link {
                        flex-direction: column;
                        padding: 10px 6px;
                        padding-bottom: 8px;
                    }
                }

                .pane-button {
                    padding: 10px 15px;
                    display: flex;
                    align-items: center;
                    border: 1px solid $mainLightGreyColor;
                    background: #f9f9f9;
                    color: $defaultFontColor;
                    cursor: pointer;

                    &:hover:not(.active) {
                        background: #e8e8e8;
                    }

                    &.active {
                        background: $mainLightColor;
                    }

                    .pane-button-label {
                        font-size: $smallFontSize;
                    }

                    &.ready-for-drop {
                        z-index: 10000;
                    }

                    &.pane-title {
                        cursor: default;
                        padding: 0 3px;
                        font-weight: 600;
                        font-size: $smallFontSize;

                        &:hover {
                            background: #f9f9f9;
                        }
                    }

                    &.with-link {
                        padding: 0;
                    }
                }

                .pane-button-link {
                    padding: 10px 15px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    text-decoration: none;
                    color: $defaultFontColor;
                }
            }
        }
    }

    .drop-overlay {
        z-index: 9999;
        background-color: #bdbdbd6e;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    .select-candidate-hint {
        display: flex;
        justify-content: center;
        padding-top: 30px;

        &.right-padding {
            padding-right: 100px;
        }
    }
}

.phone-number-popover {
    .phone-number {
        padding: 15px;
        font-weight: 600;
    }
}

@import "styles/constants";

.modal-dialog.skill-notes-modal {
    .modal-header {
        .icon-container {
            display: inline-block;
            padding: 0 5px;
        }
    }

    .modal-footer {
        justify-content: flex-end;
    }

    .button-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
    }

    .notes-container {
        overflow-y: auto;
        max-height: 40vh;
        border: 1px solid #ced4dd;
        border-radius: 5px;

        .note-date {
            color: $mainLightGreyColor;
            font-size: $smallFontSize;
        }

        .note {
            display: flex;
            justify-content: space-between;
        }
    }
}

@import "styles/constants";

.media-container {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    h6 {
        text-align: center;
        color: $mainLightGreyColor;
        font-style: italic;
        display: flex;
        align-self: center;
        &:before {
            content: "";
            display: inline-block;
            width: 50px;
            background-color: $brainChainLimeColor;
            height: 2px;
            align-self: center;
            margin: 0 0.5rem;
        }
        &:after {
            content: "";
            display: inline-block;
            width: 50px;
            background-color: $brainChainLimeColor;
            height: 2px;
            align-self: center;
            margin: 0 0.5rem;
        }
    }
    > .links-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        > .icon-container {
            margin: 0 0.5rem;
            cursor: pointer;
            > svg {
                width: 32px;
                height: 32px;
            }
        }
    }
}

.rich-media {
    &__title {
        font-weight: bold;
        font-size: 16px;
        align-items: center;
        align-content: center;
        display: flex;
        padding: 15px;
        width: 50%;
        font-style: italic;
        border-right: solid $brainChainLimeColor 1px;
    }

    &__avatar-box {
        flex: 0 1 auto;
    }

    &__org-box {
        flex: 1 1 auto;
    }

    &__media-viewer {
        border: solid $brainChainLimeColor 2px;
        width: 80%;
    }

    &__table-cell {
        &--top-border {
            border-top: solid black 1px;
        }
        &--bottom-border {
            border-bottom: solid black 1px;
        }
    }

    &__row {
        border-bottom: dashed black 1px;

        &--no-border {
            border: none;
        }
    }

    &__container {
        border-left: none;
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__media-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 50%;
    }

    &__media-container-content {
        width: 42px;
        height: 42px;
    }

    &__selector-box {
        padding: 17px;
        border: solid $mainLightGreyColor 2px;
        margin-bottom: 15px;
        align-items: flex-start;
        border-radius: 15%;

        &--active {
            border-color: $mainBlueColor;
            border-width: 4px;
            padding: 15px;
        }
    }

    &__tab-title {
        top: 6px;
        background: white;
        left: auto;
        width: 100px;
        right: auto;
        text-align: center;
        white-space: nowrap;
    }

    &__media-avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    // &__media-item {
    //     width: 100%;
    //     height: 100px;
    //     // border-radius: 50%;
    // }

    &__media-container {
        width: 150px;
        height: auto;
    }

    &__selector-box-container {
        width: 20%;
    }
}

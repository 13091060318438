@import "styles/constants";

.job-quick-find {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
  padding: 0 20px;

  .job-pull {
    max-width: 1000px;
    width: 100%;
    margin-top: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  .balance-icon-description-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 20px;

    > .icon-container {
      margin: 0 10px;
    }
  }

  .section-header {
    border-bottom: 2px solid $colorPrimary;
    color: $colorPrimary;
    padding-bottom: 5px;
  }

  .section-subheader {
    margin-top: 20px;
  }

  .jobs-wrapper {
    display: flex;
    width: 100%;
    border: solid 1px #a9a9a9;
    border-radius: 5px;
    height: 100%;
    max-height: 520px;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 20px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .no-jobs-message {
    padding: 15px 0;
    margin: 0;
  }
}

.hf1-custom-select {
    position: relative;

    .react-select {
        position: relative;
    }

    .hf1-input-required {
        position: absolute;
        border: 0;
        color: transparent;
        background-color: transparent;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        outline: none;
    }
}

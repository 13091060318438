@import "styles/constants";

.staff-and-departments {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-divider {
        width: 80vw;
    }
}

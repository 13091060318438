@import "styles/constants";

$primary: $colorPrimary;
$columnBackgroundColor: #f9f9f9;
$cardBorder: 1px solid #a9a9a9;
$cardBoxShadow: 0 0 0 1px #a9a9a9;
$cardBorderRadius: 5px;
$cardBackgroundColor: #ffffff;
$cardPadding: 10px;
$cardMargin: 5px;
$subCardBorder: 1px solid #ced4dd;

.skills-setup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 0;

    .alert {
        font-size: $smallFontSize;
        padding: 0.5rem 0.75rem;

        &.overflow-auto {
            overflow-y: auto;
        }

        &.alert-dismissible {
            padding-right: 2rem;

            .close {
                padding: 0.5rem;
                padding-top: 0.25rem;
            }
        }

        ul {
            margin-bottom: 0;
        }
    }

    .skills-setup-content {
        display: flex;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        min-height: 0;

        .menu-column-container {
            width: 25%;
            max-width: 25%;
            padding: 0 5px 5px 5px;

            @media (min-width: 1500px) {
                max-width: 400px;
                width: 400px;
            }
        }

        .skills-map-column {
            display: flex;
            flex-direction: column;
            padding: 0;
            height: 100%;
            max-height: 100%;
            position: relative;
            padding-bottom: 5px;
        }
    }

    .hint-info-icon {
        position: absolute;
        display: inline;
        margin-left: 5px;
        cursor: pointer;
    }

    .hint-info-icon-with-arrow {
        margin-left: 20px;
    }

    .profiles-settings-container,
    .skills-map-container,
    .skill-settings-container {
        display: flex;
        flex-direction: column;
        padding: $cardPadding;
        border: $cardBorder;
        background-color: $columnBackgroundColor;
        border-radius: $cardBorderRadius;
        height: 100%;
        max-height: 100%;

        .info-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 5px;
            background-color: #878787;
            margin-top: 10px;

            > h6 {
                color: white;
            }

            > div {
                display: flex;
                flex-direction: row;
                align-items: center;

                > button {
                    margin-right: 10px;
                    background-color: #75b444;
                    border-radius: 50px;
                    min-width: 100px;
                }

                > h6 {
                    margin-right: 10px;
                    color: white;

                    > a {
                        color: #75b444;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .profiles-settings-container {
        .profile-card {
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 0;
            border: $cardBorder;
            background-color: $cardBackgroundColor;
            border-radius: $cardBorderRadius;
            margin: $cardMargin;
            padding: $cardPadding;

            .list-group-item {
                display: flex;
                padding: 10px 5px;
                align-items: center;
                justify-content: space-between;
                min-height: fit-content;

                .icon-container {
                    margin-left: 5px;
                    margin-right: 5px;
                    cursor: pointer;
                }
            }
        }

        .profile-items-container {
            height: 100%;
            border: $subCardBorder;
            border-radius: $cardBorderRadius;
            margin-top: 5px;
            overflow: auto;
        }

        .profile-item-with-checkbox-container {
            display: flex;
            align-items: center;
        }

        .profile-item {
            margin-left: 5px;
        }

        .search-profiles-input-group {
            padding-bottom: 5px;
            min-height: fit-content;
        }
    }

    .skills-map-component-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        padding: $cardPadding;
        margin: $cardMargin;
        box-shadow: $cardBoxShadow;
        background-color: $cardBackgroundColor;
        border-radius: $cardBorderRadius;
    }

    .skills-map-container {
        .legend {
            justify-content: space-evenly;
            margin: 0;
            margin-top: 5px;
        }
    }

    .skill-settings-container {
        .skill-settings-content-container {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            height: 100%;
        }

        .skill-settings-card {
            display: flex;
            flex-direction: column;
            border: $cardBorder;
            background-color: $cardBackgroundColor;
            border-radius: $cardBorderRadius;
            margin: $cardMargin;
            padding: $cardPadding;
            min-height: fit-content;

            &.card-close {
                min-height: 55px;
            }

            &.semantic-context {
                height: auto;
                overflow-y: auto;
                max-height: 50%;

                &.card-open {
                    min-height: 290px;

                    &.without-skills {
                        min-height: 170px;
                    }
                }
            }

            &.search-skills {
                height: auto;
                overflow-y: auto;
                max-height: 50%;

                &.card-open {
                    min-height: 240px;
                }
            }

            &.skill-properties {
                .row.form-group {
                    margin-bottom: 5px;
                }

                label {
                    word-break: break-word;
                }

                .select-eagerness-custom-option {
                    display: flex;
                    align-items: center;

                    &:hover:not(.select-eagerness-custom-option-selected) {
                        background: #e8e8e8;

                        &.love-it {
                            background: $skillEagernessLoveItLightColor;
                        }

                        &.ok {
                            background: $skillEagernessOkLightColor;
                        }

                        &.if-need-be {
                            background: $skillEagernessIfNeedBeLightColor;
                        }
                    }

                    &.select-eagerness-custom-option-selected {
                        font-weight: 500;

                        &.love-it {
                            background: $skillEagernessLoveItLightColor;
                        }

                        &.ok {
                            background: $skillEagernessOkLightColor;
                        }

                        &.if-need-be {
                            background: $skillEagernessIfNeedBeLightColor;
                        }
                    }

                    .select-eagerness-custom-option-icon {
                        display: flex;
                        margin: 0 8px;
                    }
                }

                .skill-version-ratio-col {
                    padding-right: 1px;
                }

                .skill-version-value-col {
                    padding-left: 1px;
                    max-width: 90px;
                }

                .input-select-eagerness-single-value {
                    display: flex;
                    align-items: center;

                    .input-select-eagerness-single-value-icon {
                        display: flex;
                        margin-right: 8px;
                    }
                }

                .notes-icon {
                    padding: 0 5px;
                    display: inline;
                }
            }

            &.define-new-skill {
                .row.form-group {
                    margin-bottom: 5px;
                }

                .form-check {
                    padding-left: 8px;

                    .form-check-label {
                        padding-top: 0;
                    }
                }
            }

            &.semantic-context,
            &.search-skills {
                .collapse.show,
                .collapsing {
                    max-width: 100%;
                    max-height: 100%;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    min-height: 0;
                }
            }

            h6.with-collapse {
                position: relative;
                margin-right: 15px;

                .icon-container {
                    position: absolute;
                    display: inline;
                }
            }
        }

        .bread-crumbs-container {
            display: flex;
            white-space: nowrap;
            flex-wrap: wrap;
            align-items: center;
            margin: 5px 0;
            min-height: fit-content;

            .bread-crumbs-element {
                display: flex;
                align-items: center;
                max-width: 90%;

                .skill {
                    margin: 2px 0;
                    max-width: 100%;
                    font-size: $smallFontSize;
                    padding: 1px 10px;
                    min-height: auto;

                    .skill-text-container {
                        max-width: 100%;
                    }
                }

                .icon-container {
                    svg {
                        fill: $skillNoLevelColor;
                    }
                }
            }
        }

        .current-cluster-skills-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            height: 100%;
            overflow-y: auto;
            border: 1px solid darkgrey;
            border-radius: 5px;
            padding: 5px;

            .skill {
                margin: 3px 5px;
                max-width: 95%;

                .skill-text-container {
                    max-width: 100%;
                }
            }
        }

        .search-skills {
            display: flex;
            flex-direction: column;
            align-items: center;

            .search-items-container {
                width: 100%;
                height: 100%;
                overflow: auto;
                border: $subCardBorder;
                border-radius: $cardBorderRadius;
                margin-top: 5px;

                .list-group-item {
                    display: flex;
                    padding: 0;
                    padding-right: 5px;
                    justify-content: space-between;
                    min-height: fit-content;

                    .skill {
                        max-width: 50%;
                    }

                    .node-semantic-context {
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        justify-content: center;
                        max-width: 45%;

                        .cluster {
                            color: $mainLightGreyColor;
                            font-size: $smallFontSize;
                            height: 21px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            max-width: 100%;
                        }
                    }
                }
            }

            .search-skills-input-group {
                padding-bottom: 5px;
                min-height: fit-content;
            }
        }
    }
}

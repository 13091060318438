@import "styles/constants";

.cluster-skills-popover {
    .skills-items-container {
        max-height: 300px;
        overflow-y: auto;
        border: 1px solid #ced4dd;
        border-radius: 5px;

        .list-group-item {
            display: flex;
            font-size: $smallFontSize;
            padding: 6px 3px;
            align-items: center;
            justify-content: space-between;

            .text-container {
                margin-left: 5px;
            }

            .icon-container {
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
}

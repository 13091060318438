.create-new-job {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .nav {
        .nav-link {
            cursor: pointer;
        }
    }

    .nav-tabs {
        width: 100%;
        justify-content: center;
    }

    .tab-content {
        min-height: 1px;
        height: 100%;
        width: 100%;
    }

    .tab-pane {
        height: 100%;
    }

    .submit-new-job-container {
        position: absolute;
        top: 15px;
    }
}

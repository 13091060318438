@import "styles/constants";

.skills-without-level-modal {

    .list-group {
        max-height: 300px;
        overflow-y: auto;
        border: solid 1px #dfdfdf;
        border-radius: 4px;
    }
}
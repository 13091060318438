@import "../../../../styles/constants.scss";

.job-details-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1rem;
    word-wrap: break-word;
    width: 75%;
    margin-bottom: 50px;

    > .sub-section {
        width: 100%;
        &:nth-child(odd) {
            border-right: 1px solid $mainLightGreyColor;
            padding: 0 1rem;
        }
        &:nth-child(even) {
            border-left: 1px solid $mainLightGreyColor;
            padding-left: 1rem;
        }
    }
    &.core,
    &.dates,
    &.status,
    &.rich-media {
        > h6 {
            color: $mainLightGreyColor;
            font-style: italic;
            display: flex;
            align-self: center;
            &:before {
                content: "";
                display: inline-block;
                width: 50px;
                background-color: $brainChainLimeColor;
                height: 2px;
                align-self: center;
                margin: 0 0.5rem;
            }
            &:after {
                content: "";
                display: inline-block;
                width: 50px;
                background-color: $brainChainLimeColor;
                height: 2px;
                align-self: center;
                margin: 0 0.5rem;
            }
        }
    }
    > div {
        margin: 0.5rem 0;
    }
    .sub-title {
        color: $mainLightGreyColor;

        font-style: italic;
    }
}

.rich-media {
    min-width: 800px;

    .modal-title {
        width: 100%;
    }

    &__content {
        width: 20%;
        height: auto;
    }
}

@import "styles/constants";

.dashboard-comparison {
    max-width: 100%;
    overflow-x: auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    .candidates-info {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 250px;
        margin-bottom: 10px;
        position: sticky;
        top: 0;
        z-index: 2;

        .candidates-info-col {
            display: flex;
            align-items: center;
            border: 1px solid #a9a9a9;

            &:not(:last-child) {
                border-right: 0;
            }

            &.job-requirements {
                justify-content: center;
                background: #efefef;

                & + .candidate-info {
                    border-left: 0;
                }
            }

            &.candidate-info {
                flex-direction: column;
                padding: 8px;
                background-color: #ffffff;
                position: relative;

                .candidate-controls {
                    display: flex;
                    margin-bottom: 5px;

                    .buttons-group {
                        display: flex;
                        align-items: center;
                    }

                    .icon-container {
                        margin: 0 7px;
                    }
                }

                .user-photo {
                    margin: 0 5px;
                }

                .candidate-name {
                    font-size: $smallFontSize;
                    text-align: center;
                    font-weight: 600;
                    line-height: 1.2;
                    width: 100%;
                    padding: 0 25px;
                    margin-bottom: 5px;
                }

                .candidate-notes-icon {
                    position: absolute;
                    bottom: 4px;
                    right: 0;
                }

                .remove-candidate-from-comparison {
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }

            .favorite-icon svg {
                fill: $favoriteColor;
            }
        }
    }

    .hard-skills-header {
        margin-bottom: 5px;

        .hard-skills-controls {
            display: flex;
            align-items: center;
            justify-content: center;

            .controls-title {
                font-size: $smallFontSize;
                font-weight: 600;
            }

            .control-button {
                display: flex;
                align-items: center;
                font-size: $smallFontSize;
                cursor: pointer;
                margin-left: 10px;

                .icon-container {
                    margin-right: 3px;
                }
            }
        }
    }

    .hard-skills {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .hard-skills-row {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 250px;
            position: relative;

            .cluster-name {
                position: absolute;
                z-index: 1;
                color: white;
                background-color: #a9a9a9;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 0 5px;
                top: 0;
                font-size: 12px;
                left: 50%;
                transform: translate(-50%, 0);
                height: 18px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: calc(100% - 40px);
            }

            .cluster {
                border: 1px solid #a9a9a9;
                padding: 17px 5px;

                &:not(:last-child) {
                    border-right: 0;
                }

                &.job-cluster {
                    background: #efefef;

                    & + .candidate-cluster {
                        border-left: 0;
                    }
                }

                &.candidate-cluster {
                    background-color: #ffffff;
                    position: relative;
                    z-index: 0;
                }
            }

            &:not(:last-child) {
                .job-cluster {
                    border-bottom: 0;
                }

                .candidate-cluster {
                    border-bottom: 0;
                }
            }

            &:nth-child(2n) {
                .candidate-cluster {
                    background-color: #efefef;
                }

                .job-cluster {
                    background-color: #e0e0e0;
                }
            }
        }

        .skill {
            max-width: calc(100% - 10px);
            background: #ffffff;

            .notes-icon {
                cursor: pointer;
            }
        }
    }

    .quality-checks {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 250px;

        .quality-check-label {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #a9a9a9;
            border-right: 0;
            background: #efefef;

            h6 {
                margin: 0;
            }

            & + .quality-check {
                border-left: 0;
            }
        }

        .quality-check {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid #a9a9a9;
            background-color: #ffffff;

            &:not(:last-child) {
                border-right: 0;
            }
        }
    }

    .soft-skills {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 250px;
        margin-bottom: 20px;

        .ffm-chart-container {
            border: 1px solid #a9a9a9;
            background-color: #ffffff;

            &:not(:last-child) {
                border-right: 0;
            }

            &.job-ffm {
                border-right: 0;
                background: #efefef;

                & + .ffm-chart-container {
                    border-left: 0;
                }
            }

            .ffm-chart {
                .ffm-background {
                    fill: #f9f9f9;
                }
            }
        }
    }
}

@import "styles/constants";

.filtered-list-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    margin: 1rem 0;

    .list-container {
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        overflow: auto;
        max-height: 8rem;
        list-style: none;
        padding: 1rem 0;
        margin: 0;

        &.required {
            border-color: $brainChainLimeColor;
            border-radius: 2px;
        }
        > li {
            user-select: none;
            cursor: pointer;
            padding: 0 1rem;
            &:hover {
                background-color: $brainChainLimeColor;
                color: $mainLightColor;
            }
            &.active {
                background-color: $brainChainLimeColor;
                color: $mainLightColor;
            }
        }
        > .fallback {
            padding: 0 1rem;
        }
    }
    > .filter-container {
        width: 40%;
        margin-left: 1rem;
        > button {
            margin: 1rem 0;
        }
    }
    > .select-area {
        display: flex;
        flex-direction: column;
        width: 60%;
    }
}

@import "styles/constants";

html,
body,
#root {
	height: 100%;
}

.app {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.page-container {
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
	overflow-y: auto;

	> div {
		width: 100%;
	}

	&.boxed-width > div {
		max-width: $boxedPageWidth;
	}
}

@import "styles/constants";

.applicant-registration {
    max-width: $defaultFormWidth;
    margin-left: $defaultFormMargin;
    margin-right: $defaultFormMargin;
    margin-top: 30px;

    .back-link-container {
        width: $defaultFormWidth;
        max-width: $defaultFormWidth;
        display: flex;
    }

    .applicant-registration-header {
        margin-bottom: 20px;
    }

    .applicant-registration-hint {
        margin-bottom: 15px;
    }

    .success-registration-message {
        margin-top: 20px;
    }
}

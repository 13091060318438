@import "styles/constants";

.back-link {
    display: flex;
    align-items: center;

    .icon-container {
        padding-right: 2px;
    }

    &:hover,
    &:focus {
        .icon-container svg {
            fill: $linkHoverColor;
        }
    }
}

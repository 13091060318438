@import "styles/constants";

.alternative-now-black-list-modal {
    .modal-body {
        padding: 0;
        max-height: calc(100vh - 300px);
        overflow-y: auto;

        .no-profiles-label {
            margin: 10px 16px;
        }
    }

    .hidden-profiles-container {
        .hidden-profile-item {
            display: flex;
            justify-content: space-between;
        }
    }
}

.App {
    font-family: sans-serif;
}

.dropzone {
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    margin-top: 20px;

    margin-bottom: 20px;
}

.accept {
    border-color: #107c10 !important;
}

.reject {
    border-color: #d83b01 !important;
}

.company-rich-media__media {
    width: 100px;
    height: 50px;
}

@import "styles/constants";

.center {
  text-align: center;
}

.search-button-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  margin-top: 20px;

  .search-button {
    align-self: center;
  }
}

.compare-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}

.quickfind-row {
  margin: 0;
  flex: 1;
}

.flex {
  flex: 1;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
}

.regime-input-wrapper {
  flex: 1;
  align-items: baseline;

  .info-icon {
    margin-left: 20px;
    cursor: pointer;
  }
}

.input {
  min-height: 38px;
  font-size: 1rem;
}

.with-top-margin {
  margin-top: 25px;
}

.rows-wrapper {
  padding: 10px 0 10px 0;
  flex: 1;
}

.city-input-wrapper {
  flex-grow: 1;
}

.work-regime-input {
  padding: 0;
}

.form-check-inline {
  margin: 0;

  > label {
    white-space: pre;
    margin: 0 20px 0 0;
  }

  .form-label {
    margin: 0 20px;
  }

  > input {
    width: 100%;
    max-width: 250px;
    min-width: 100px;
  }
}

.work-regime-labels {
  display: flex;
  justify-content: space-between;
}

.preferred-contract-type-wrapper {
  margin: 0 20px;
}

.salary-input-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;

  .salary-input {
    flex: 0.45;
  }

  .hf1-custom-select {
    flex: 0.45;
  }
}

.work-regime-input-wrapper {
  width: 100%;
}

.work-regime-label {
  display: flex;
  justify-content: center;
}

.autocomplete-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
}

.distance-unit-select-wrapper {
  margin: 0 0 0 20px;
  padding: 0;
  min-width: 105px;
}

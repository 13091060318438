$boxShadow: 0 1px 7px 0 #c2c5cdbf;

.secondary-navbar {
    box-shadow: $boxShadow;
    min-height: 48px;
    max-height: 48px;
    padding: 0.5rem;

    .sub-roles-navbar-toggler {
        height: 34px;
        width: 40px;
        padding: 0.25rem;

        .navbar-toggler-icon {
            height: 24px;
        }
    }

    .sub-roles-container,
    .groups-container {
        display: flex;
        align-items: baseline;

        h6 {
            margin-right: 10px;
        }
    }

    .sub-role-select,
    .group-select {
        width: 140px;
    }

    .loading-nav {
        h6 {
            margin-bottom: 8px;
            margin-top: 4px;
        }
    }
}

@media (min-width: 1024px) {
    .secondary-navbar {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;

        .sub-roles-navbar-toggler {
            display: none;
        }

        .sub-roles-collapse {
            display: flex;
            justify-content: flex-end;

            ul {
                margin-right: 20px;

                &:last-child:not(.loading-nav) {
                    margin-right: 8px;
                }
            }
        }
    }
}

@media (max-width: 1023px) {
    .secondary-navbar {
        .sub-roles-collapse {
            margin-top: 10px;
            z-index: 90;
            box-shadow: $boxShadow;
            padding: 10px 15px;
            background-color: #f8f9fa;

            ul {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

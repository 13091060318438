@import "styles/constants";

.personal-settings {
    &__title {
        padding: 0.5rem 1rem;
        font-size: 16px;
    }

    &__tab {
        color: $colorBlack;
        margin-top: 15px;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0 !important;

        &:hover {
            color: $brainChainLimeColor;
            cursor: pointer;
            border-right: none;
        }

        &--active {
            color: $colorBlack;
            border-left: solid $brainChainLimeColor 1px !important;
            border-top: solid $brainChainLimeColor 1px !important;
            border-bottom: solid $brainChainLimeColor 1px !important;
        }
    }
}

.olive-green {
    color: $brainChainOliveColor;
}

.qr-code-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.parameters-container {
    margin-bottom: 20px;
}

.parameters-title-container,
.links-title-container {
    display: flex;
}

.parameters-title-container h6,
.links-title-container h6 {
    cursor: pointer;
}

.parameters-title-icon,
.links-title-icon {
    display: flex;
    cursor: pointer;
}

.links-container {
    display: flex;
    flex-direction: column;
}

.two-fa-settings-label {
    margin-bottom: 20px;
}

.two-fa-modal-success-buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.two-fa-modal-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.skill-node {
  border: solid 5px #00acbe !important; }

.skill-cluster-node {
  border: solid 5px #9fc643 !important; }

.domain-node {
  border: solid 5px #cb2a7b !important; }

.profile-node {
  border: solid 5px #d9c025 !important; }

/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #89aa11;
  --secondary: #6c757d;
  --success: #89aa11;
  --info: #0e9cd8;
  --warning: #ffc107;
  --danger: #d64021;
  --light: #efefef;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #89aa11;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #51640a;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #dee7bc; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #c2d383; }

.table-hover .table-primary:hover {
  background-color: #d5e0a9; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #d5e0a9; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dee7bc; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #c2d383; }

.table-hover .table-success:hover {
  background-color: #d5e0a9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d5e0a9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bce3f4; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #82cceb; }

.table-hover .table-info:hover {
  background-color: #a6daf0; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a6daf0; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4cac1; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ea9c8c; }

.table-hover .table-danger:hover {
  background-color: #f0b7ab; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f0b7ab; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fbfbfb; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f7f7f7; }

.table-hover .table-light:hover {
  background-color: #eeeeee; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eeeeee; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #cbed4d;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #89aa11; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(137, 170, 17, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #89aa11;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2389aa11' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #89aa11;
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #89aa11;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2389aa11' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #89aa11;
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #89aa11; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #89aa11; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #89aa11; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #aed816;
  background-color: #aed816; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #89aa11; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #89aa11; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #89aa11;
  box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d64021; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(214, 64, 33, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d64021;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d64021' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d64021' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #d64021;
    box-shadow: 0 0 0 0.2rem rgba(214, 64, 33, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d64021;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d64021' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d64021' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #d64021;
    box-shadow: 0 0 0 0.2rem rgba(214, 64, 33, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d64021; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d64021; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #d64021; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e36247;
  background-color: #e36247; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(214, 64, 33, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d64021; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d64021; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d64021;
  box-shadow: 0 0 0 0.2rem rgba(214, 64, 33, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #89aa11;
  border-color: #89aa11; }
  .btn-primary:hover {
    color: #fff;
    background-color: #6d870e;
    border-color: #647c0c; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #6d870e;
    border-color: #647c0c;
    box-shadow: 0 0 0 0.2rem rgba(155, 183, 53, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #89aa11;
    border-color: #89aa11; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #647c0c;
    border-color: #5a700b; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(155, 183, 53, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #89aa11;
  border-color: #89aa11; }
  .btn-success:hover {
    color: #fff;
    background-color: #6d870e;
    border-color: #647c0c; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #6d870e;
    border-color: #647c0c;
    box-shadow: 0 0 0 0.2rem rgba(155, 183, 53, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #89aa11;
    border-color: #89aa11; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #647c0c;
    border-color: #5a700b; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(155, 183, 53, 0.5); }

.btn-info {
  color: #fff;
  background-color: #0e9cd8;
  border-color: #0e9cd8; }
  .btn-info:hover {
    color: #fff;
    background-color: #0c82b4;
    border-color: #0b79a8; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #0c82b4;
    border-color: #0b79a8;
    box-shadow: 0 0 0 0.2rem rgba(50, 171, 222, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #0e9cd8;
    border-color: #0e9cd8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0b79a8;
    border-color: #0a719c; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(50, 171, 222, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #d64021;
  border-color: #d64021; }
  .btn-danger:hover {
    color: #fff;
    background-color: #b5361c;
    border-color: #aa331a; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #b5361c;
    border-color: #aa331a;
    box-shadow: 0 0 0 0.2rem rgba(220, 93, 66, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #d64021;
    border-color: #d64021; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #aa331a;
    border-color: #9f2f18; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 93, 66, 0.5); }

.btn-light {
  color: #212529;
  background-color: #efefef;
  border-color: #efefef; }
  .btn-light:hover {
    color: #212529;
    background-color: gainsboro;
    border-color: #d6d6d6; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: gainsboro;
    border-color: #d6d6d6;
    box-shadow: 0 0 0 0.2rem rgba(208, 209, 209, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #efefef;
    border-color: #efefef; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #d6d6d6;
    border-color: #cfcfcf; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(208, 209, 209, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #89aa11;
  border-color: #89aa11; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #89aa11;
    border-color: #89aa11; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #89aa11;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #89aa11;
    border-color: #89aa11; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #89aa11;
  border-color: #89aa11; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #89aa11;
    border-color: #89aa11; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #89aa11;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #89aa11;
    border-color: #89aa11; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.5); }

.btn-outline-info {
  color: #0e9cd8;
  border-color: #0e9cd8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #0e9cd8;
    border-color: #0e9cd8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(14, 156, 216, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #0e9cd8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #0e9cd8;
    border-color: #0e9cd8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(14, 156, 216, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #d64021;
  border-color: #d64021; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #d64021;
    border-color: #d64021; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(214, 64, 33, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d64021;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #d64021;
    border-color: #d64021; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(214, 64, 33, 0.5); }

.btn-outline-light {
  color: #efefef;
  border-color: #efefef; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #efefef;
    border-color: #efefef; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(239, 239, 239, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #efefef;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #efefef;
    border-color: #efefef; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 239, 239, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #89aa11;
  text-decoration: none; }
  .btn-link:hover {
    color: #51640a;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #89aa11; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #89aa11;
    background-color: #89aa11; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #cbed4d; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #d8f27c;
    border-color: #d8f27c; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #89aa11;
  background-color: #89aa11; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(137, 170, 17, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(137, 170, 17, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(137, 170, 17, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(137, 170, 17, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #cbed4d;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #cbed4d;
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #89aa11;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #d8f27c; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #89aa11;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #d8f27c; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #89aa11;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #d8f27c; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #89aa11; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #89aa11;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #51640a;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #89aa11;
  border-color: #89aa11; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #89aa11; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #647c0c; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #89aa11; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #647c0c; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(137, 170, 17, 0.5); }

.badge-info {
  color: #fff;
  background-color: #0e9cd8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #0b79a8; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(14, 156, 216, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #d64021; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #aa331a; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(214, 64, 33, 0.5); }

.badge-light {
  color: #212529;
  background-color: #efefef; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #d6d6d6; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(239, 239, 239, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #475809;
  background-color: #e7eecf;
  border-color: #dee7bc; }
  .alert-primary hr {
    border-top-color: #d5e0a9; }
  .alert-primary .alert-link {
    color: #222a04; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #475809;
  background-color: #e7eecf;
  border-color: #dee7bc; }
  .alert-success hr {
    border-top-color: #d5e0a9; }
  .alert-success .alert-link {
    color: #222a04; }

.alert-info {
  color: #075170;
  background-color: #cfebf7;
  border-color: #bce3f4; }
  .alert-info hr {
    border-top-color: #a6daf0; }
  .alert-info .alert-link {
    color: #042e40; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #6f2111;
  background-color: #f7d9d3;
  border-color: #f4cac1; }
  .alert-danger hr {
    border-top-color: #f0b7ab; }
  .alert-danger .alert-link {
    color: #43140a; }

.alert-light {
  color: #7c7c7c;
  background-color: #fcfcfc;
  border-color: #fbfbfb; }
  .alert-light hr {
    border-top-color: #eeeeee; }
  .alert-light .alert-link {
    color: #636363; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #89aa11;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #89aa11;
    border-color: #89aa11; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #475809;
  background-color: #dee7bc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #475809;
    background-color: #d5e0a9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #475809;
    border-color: #475809; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #475809;
  background-color: #dee7bc; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #475809;
    background-color: #d5e0a9; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #475809;
    border-color: #475809; }

.list-group-item-info {
  color: #075170;
  background-color: #bce3f4; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #075170;
    background-color: #a6daf0; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #075170;
    border-color: #075170; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #6f2111;
  background-color: #f4cac1; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6f2111;
    background-color: #f0b7ab; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6f2111;
    border-color: #6f2111; }

.list-group-item-light {
  color: #7c7c7c;
  background-color: #fbfbfb; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7c7c7c;
    background-color: #eeeeee; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7c7c7c;
    border-color: #7c7c7c; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #89aa11 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #647c0c !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #89aa11 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #647c0c !important; }

.bg-info {
  background-color: #0e9cd8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0b79a8 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #d64021 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #aa331a !important; }

.bg-light {
  background-color: #efefef !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d6d6d6 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #89aa11 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #89aa11 !important; }

.border-info {
  border-color: #0e9cd8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #d64021 !important; }

.border-light {
  border-color: #efefef !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #89aa11 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #51640a !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #89aa11 !important; }

a.text-success:hover, a.text-success:focus {
  color: #51640a !important; }

.text-info {
  color: #0e9cd8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #096890 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #d64021 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #942c17 !important; }

.text-light {
  color: #efefef !important; }

a.text-light:hover, a.text-light:focus {
  color: #c9c9c9 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.modal-footer.content-center {
  justify-content: center; }

.modal-footer.content-space-between {
  justify-content: space-between; }

.modal-dialog {
  margin-top: 75px; }

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: initial; }

.page-header {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px; }

.page-subheader {
  width: 100%;
  text-align: center;
  margin-bottom: 10px; }

.page-subsection-header {
  border-bottom: 2px solid #89aa11;
  color: #89aa11;
  padding-bottom: 5px;
  width: 100%;
  margin-bottom: 15px; }

.page-hint {
  text-align: center;
  font-size: 0.95rem;
  color: #808080;
  margin-bottom: 10px; }

.subsection-hint {
  font-size: 0.95rem;
  color: #808080;
  margin-bottom: 10px; }

.page-message {
  width: 100%;
  text-align: center;
  margin-top: 20px; }

.buttons-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
  margin-bottom: 10px; }
  .buttons-container.content-center {
    justify-content: center; }
  .buttons-container.content-space-between {
    justify-content: space-between; }
  .buttons-container.form-buttons {
    padding: 0; }

.icon-button {
  padding: 5px; }

.button-with-icon {
  display: flex;
  align-items: center; }
  .button-with-icon.button-with-icon-right .icon-container {
    padding-left: 5px; }

.form-group-with-hint {
  position: relative; }
  .form-group-with-hint .form-group-hint-icon {
    position: absolute;
    left: -20px;
    top: 5px;
    cursor: pointer; }

table.clickable-rows tbody tr {
  cursor: pointer; }
  table.clickable-rows tbody tr:hover {
    background: rgba(201, 201, 201, 0.5); }

table.clickable-rows .table-cell-with-icon:hover {
  background: rgba(201, 201, 201, 0.5); }

table .table-cell-with-icon {
  width: 24px;
  cursor: pointer; }
  table .table-cell-with-icon:hover {
    background: rgba(201, 201, 201, 0.5); }

table .table-cell-align-right {
  text-align: right; }

table .table-cell-align-center {
  text-align: center; }

table .table-row-disabled {
  background-color: #e6e6e6; }

.col-input-with-right-icon {
  padding-right: 50px; }

.input-right-icon {
  position: absolute;
  top: 6px;
  right: 16px; }

.input-group-text-item-with-icon {
  padding: 0;
  background: #ffffff; }

.input-field-changed {
  border-color: #007bff; }

.input-field-changed:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.input-group-field-changed .input-group-text {
  border-color: #007bff; }

.input-group-field-changed input {
  border-top-color: #007bff;
  border-bottom-color: #007bff;
  border-right-color: #007bff; }

a,
.btn-link {
  color: #007bff; }
  a:hover, a:focus,
  .btn-link:hover,
  .btn-link:focus {
    color: #0056b3;
    text-decoration: none; }

.btn-link,
.btn-link:active,
.btn-link:focus {
  display: inline;
  vertical-align: initial;
  line-height: normal;
  box-shadow: none;
  padding: 0;
  border: 0;
  margin: 0; }

.small-text {
  font-size: 14px; }

.text-pre-wrap {
  white-space: pre-wrap; }

.text-bold {
  font-weight: bold; }

.form-check.form-check-with-left-margin {
  margin-left: 10px; }

.form-check.form-check-with-inputs .form-check-input {
  margin-top: 0.5rem; }

.form-check.form-check-with-inputs .inputs-in-form-check-container {
  display: inline-block;
  max-width: 100%; }

.form-check.form-check-with-inputs > label {
  max-width: 100%; }

.react-tags {
  min-height: 38px;
  position: relative;
  padding: 0 4px;
  border: 1px solid #d1d1d1;
  border-radius: 0.2rem;
  display: flex;
  flex: 1 1;
  font-size: 1em;
  line-height: 1.2;
  cursor: text;
  display: flex;
  align-items: center; }

.react-tags.is-focused {
  border-color: #b1b1b1; }

.react-tags__selected {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px; }
  .react-tags__selected > .skill {
    display: flex;
    align-items: center;
    justify-content: center; }
  .react-tags__selected > h5 {
    margin-top: 0; }

.react-tags__search {
  display: inline-block;
  padding: 4px 8px;
  max-width: 100%; }

@media screen and (min-width: 30em) {
  .react-tags__search {
    position: relative; } }

.react-tags__search-input {
  max-width: 100%;
  min-width: 200px;
  height: 30px;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font-size: 1rem;
  line-height: 1.5; }

.react-tags__search-input::-ms-clear {
  display: none; }

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10; }

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px; } }

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); }

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px; }

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600; }

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee; }

.react-tags__suggestions li.is-active {
  background: #b7cfe0; }

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto; }

.icon-container.inline-icon {
  display: inline; }

.content-divider {
  border-top: 1px solid #89aa11;
  display: flex;
  align-items: center;
  margin: 50px 0;
  box-shadow: 0 3px 5px 0 #aeaeae;
  width: 100%; }

.root-container {
  display: flex;
  flex-direction: column;
  width: auto !important; }
  .root-container .skills-map-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    margin: 5px;
    box-shadow: 0 0 0 1px #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #a9a9a9; }
    .root-container .skills-map-container > .legend {
      background-color: #cfcfcf;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      margin: 10px 0 0 0; }
    .root-container .skills-map-container .info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;
      background-color: #878787; }
      .root-container .skills-map-container .info-wrapper > h6 {
        color: white; }
      .root-container .skills-map-container .info-wrapper > div {
        display: flex;
        flex-direction: row;
        align-items: center; }
        .root-container .skills-map-container .info-wrapper > div > button {
          margin-right: 10px;
          background-color: #75b444;
          border-radius: 50px; }
        .root-container .skills-map-container .info-wrapper > div > h6 {
          margin: 0 10px;
          color: white; }
          .root-container .skills-map-container .info-wrapper > div > h6 > a {
            color: #75b444;
            text-decoration: underline; }
    .root-container .skills-map-container .generated-info-wrapper {
      background-color: #878787;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 5px; }
      .root-container .skills-map-container .generated-info-wrapper > .contact-me a {
        color: #75b444; }
      .root-container .skills-map-container .generated-info-wrapper > h6 {
        color: white;
        margin: 5px 0; }
        .root-container .skills-map-container .generated-info-wrapper > h6 > b {
          color: white; }
        .root-container .skills-map-container .generated-info-wrapper > h6 > i > a {
          color: white;
          text-decoration: underline; }

.loading-wrapper {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center; }

.nav-link {
  white-space: nowrap; }

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }

.static-link {
  margin: 10px 5px; }

html,
body,
#root {
  height: 100%; }

.app {
  height: 100%;
  display: flex;
  flex-direction: column; }

.page-container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-y: auto; }
  .page-container > div {
    width: 100%; }
  .page-container.boxed-width > div {
    max-width: 1100px; }

.header {
  padding: 0 0.5rem;
  height: 56px;
  min-height: 56px;
  max-height: 56px; }
  .header .logo-container {
    padding: 0; }
    .header .logo-container .logo {
      height: 40px; }
  .header .navbar-nav .header-link {
    color: #ffffff;
    opacity: 0.6;
    padding: 1rem; }
    .header .navbar-nav .header-link.active {
      background-color: #41484e;
      opacity: 1; }
    .header .navbar-nav .header-link:hover {
      opacity: 1; }
  .header .header-nav-dropdown .dropdown-toggle.nav-link {
    color: #ffffff;
    padding: 1rem;
    opacity: 0.6;
    display: flex;
    align-items: center; }
    .header .header-nav-dropdown .dropdown-toggle.nav-link:hover {
      opacity: 1; }
  .header .header-nav-dropdown.active .dropdown-toggle.nav-link {
    background-color: #41484e;
    opacity: 1; }
  .header .user-menu-dropdown .nav-link {
    color: #ffffff;
    padding: 1rem;
    display: flex;
    align-items: center; }
  .header .user-menu-dropdown .user-email {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .header .user-menu-dropdown .user-icon {
    margin-right: 5px; }
  .header .header-toggler {
    margin-right: 0.5rem; }
  .header .header-collapse {
    background-color: #343a40;
    justify-content: center; }
  .header .language-select-container {
    margin-right: 10px;
    order: 1; }
  .header .mantra-container {
    position: absolute;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none; }
    .header .mantra-container .mantra {
      height: 42px; }
  @media (max-width: 767px) {
    .header .header-collapse {
      margin-top: 10px;
      z-index: 99999;
      box-shadow: 0 1px 7px 0 #232325bf; }
    .header .language-select-container {
      margin-right: 20px; }
    .header .header-toggler {
      order: 2; }
    .header .mantra-container {
      display: none; } }
  .header.user-unauthorized .unauthorized-menu {
    margin-left: auto;
    margin-right: 10px; }
  .header.user-data-loading .language-select-container {
    margin-left: auto; }

.language-select {
  width: 60px; }

.hf1-custom-select {
  position: relative; }
  .hf1-custom-select .react-select {
    position: relative; }
  .hf1-custom-select .hf1-input-required {
    position: absolute;
    border: 0;
    color: transparent;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    outline: none; }

.icon-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }
  .icon-container.icon-clickable {
    cursor: pointer; }
  .icon-container.icon-centered {
    justify-content: center; }
  .icon-container > svg {
    width: 24px;
    height: 24px; }
  .icon-container.icon-xs > svg {
    width: 16px;
    height: 16px; }
  .icon-container.icon-sm > svg {
    width: 20px;
    height: 20px; }
  .icon-container.icon-lg > svg {
    width: 30px;
    height: 30px; }
  .icon-container.icon-xl > svg {
    width: 36px;
    height: 36px; }
  .icon-container.icon-xxl > svg {
    width: 42px;
    height: 42px; }
  .icon-container.icon-plain {
    stroke-width: 0; }
    .icon-container.icon-plain.icon-primary > svg {
      fill: #89aa11;
      stroke: #89aa11; }
    .icon-container.icon-plain.icon-secondary > svg {
      fill: #6c757d;
      stroke: #6c757d; }
    .icon-container.icon-plain.icon-success > svg {
      fill: #89aa11;
      stroke: #89aa11; }
    .icon-container.icon-plain.icon-info > svg {
      fill: #0e9cd8;
      stroke: #0e9cd8; }
    .icon-container.icon-plain.icon-warning > svg {
      fill: #ffc107;
      stroke: #ffc107; }
    .icon-container.icon-plain.icon-danger > svg {
      fill: #d64021;
      stroke: #d64021; }
    .icon-container.icon-plain.icon-link > svg {
      fill: #007bff;
      stroke: #007bff; }
    .icon-container.icon-plain.icon-light > svg {
      fill: #ffffff;
      stroke: #ffffff; }
  .icon-container .icon-white-path {
    fill: #ffffff; }

.icon-red-path {
  fill: #d64021; }

.st0 {
  fill: url(#SVGID_1_); }

.st1 {
  fill: url(#SVGID_2_); }

.st2 {
  fill: url(#SVGID_3_); }

.st3 {
  fill: url(#SVGID_4_); }

.st4 {
  fill: url(#SVGID_5_); }

.st5 {
  fill: url(#SVGID_6_); }

.st6 {
  fill: url(#SVGID_7_); }

.st7 {
  fill: url(#SVGID_8_); }

.st8 {
  fill: url(#SVGID_9_); }

.st9 {
  fill: url(#SVGID_10_); }

.st10 {
  fill: url(#SVGID_11_); }

.st11 {
  fill: url(#SVGID_12_); }

.st12 {
  fill: url(#SVGID_13_); }

.st13 {
  fill: url(#SVGID_14_); }

.st14 {
  fill: url(#SVGID_15_); }

.st15 {
  fill: url(#SVGID_16_); }

.st16 {
  fill: url(#SVGID_17_); }

.st17 {
  fill: url(#SVGID_18_); }

.st18 {
  fill: url(#SVGID_19_); }

.st19 {
  fill: url(#SVGID_20_); }

.st20 {
  fill: url(#SVGID_21_); }

.st21 {
  fill: url(#SVGID_22_); }

.st22 {
  fill: url(#SVGID_23_); }

.st23 {
  fill: url(#SVGID_24_); }

.st24 {
  fill: url(#SVGID_25_); }

.st25 {
  fill: url(#SVGID_26_); }

.st26 {
  fill: url(#SVGID_27_); }

.st27 {
  fill: url(#SVGID_28_); }

.st28 {
  fill: url(#SVGID_29_); }

.st29 {
  fill: url(#SVGID_30_); }

.st30 {
  fill: url(#SVGID_31_); }

.st31 {
  fill: url(#SVGID_32_); }

.st32 {
  fill: url(#SVGID_33_); }

.st33 {
  fill: url(#SVGID_34_); }

.st34 {
  fill: url(#SVGID_35_); }

.st35 {
  fill: url(#SVGID_36_); }

.st36 {
  fill: url(#SVGID_37_); }

.st37 {
  fill: url(#SVGID_38_); }

.st38 {
  fill: url(#SVGID_39_); }

.st39 {
  fill: url(#SVGID_40_); }

.st40 {
  fill: url(#SVGID_41_); }

.st41 {
  fill: url(#SVGID_42_); }

.st42 {
  fill: url(#SVGID_43_); }

.st43 {
  fill: url(#SVGID_44_); }

.st44 {
  fill: url(#SVGID_45_); }

.st45 {
  fill: url(#SVGID_46_); }

.st46 {
  fill: url(#SVGID_47_); }

.st47 {
  fill: url(#SVGID_48_); }

.st48 {
  fill: url(#SVGID_49_); }

.st49 {
  fill: url(#SVGID_50_); }

.st50 {
  fill: url(#SVGID_51_); }

.st51 {
  fill: url(#SVGID_52_); }

.st52 {
  fill: url(#SVGID_53_); }

.st53 {
  fill: url(#SVGID_54_); }

.st54 {
  fill: url(#SVGID_55_); }

.st55 {
  fill: url(#SVGID_56_); }

.st56 {
  fill: url(#SVGID_57_); }

.st57 {
  fill: url(#SVGID_58_); }

.st58 {
  fill: url(#SVGID_59_); }

.st59 {
  fill: url(#SVGID_60_); }

.st60 {
  fill: url(#SVGID_61_); }

.st61 {
  fill: url(#SVGID_62_); }

.st62 {
  fill: url(#SVGID_63_); }

.st63 {
  fill: url(#SVGID_64_); }

.st64 {
  fill: url(#SVGID_65_); }

.st65 {
  fill: url(#SVGID_66_); }

.st66 {
  fill: url(#SVGID_67_); }

.st67 {
  fill: url(#SVGID_68_); }

.st68 {
  fill: url(#SVGID_69_); }

.st69 {
  fill: url(#SVGID_70_); }

.st70 {
  fill: url(#SVGID_71_); }

.st71 {
  fill: url(#SVGID_72_); }

.st72 {
  fill: url(#SVGID_73_); }

.st73 {
  fill: url(#SVGID_74_); }

.st74 {
  fill: url(#SVGID_75_); }

.st75 {
  fill: url(#SVGID_76_); }

.st76 {
  fill: url(#SVGID_77_); }

.st77 {
  fill: url(#SVGID_78_); }

.st78 {
  fill: url(#SVGID_79_); }

.st79 {
  fill: url(#SVGID_80_); }

.st80 {
  fill: url(#SVGID_81_); }

.st81 {
  fill: url(#SVGID_82_); }

.st82 {
  fill: url(#SVGID_83_); }

.st83 {
  fill: url(#SVGID_84_); }

.st84 {
  fill: #ffffff; }

.secondary-navbar {
  box-shadow: 0 1px 7px 0 #c2c5cdbf;
  min-height: 48px;
  max-height: 48px;
  padding: 0.5rem; }
  .secondary-navbar .sub-roles-navbar-toggler {
    height: 34px;
    width: 40px;
    padding: 0.25rem; }
    .secondary-navbar .sub-roles-navbar-toggler .navbar-toggler-icon {
      height: 24px; }
  .secondary-navbar .sub-roles-container,
  .secondary-navbar .groups-container {
    display: flex;
    align-items: baseline; }
    .secondary-navbar .sub-roles-container h6,
    .secondary-navbar .groups-container h6 {
      margin-right: 10px; }
  .secondary-navbar .sub-role-select,
  .secondary-navbar .group-select {
    width: 140px; }
  .secondary-navbar .loading-nav h6 {
    margin-bottom: 8px;
    margin-top: 4px; }

@media (min-width: 1024px) {
  .secondary-navbar {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .secondary-navbar .sub-roles-navbar-toggler {
      display: none; }
    .secondary-navbar .sub-roles-collapse {
      display: flex;
      justify-content: flex-end; }
      .secondary-navbar .sub-roles-collapse ul {
        margin-right: 20px; }
        .secondary-navbar .sub-roles-collapse ul:last-child:not(.loading-nav) {
          margin-right: 8px; } }

@media (max-width: 1023px) {
  .secondary-navbar .sub-roles-collapse {
    margin-top: 10px;
    z-index: 90;
    box-shadow: 0 1px 7px 0 #c2c5cdbf;
    padding: 10px 15px;
    background-color: #f8f9fa; }
    .secondary-navbar .sub-roles-collapse ul {
      margin-bottom: 10px; }
      .secondary-navbar .sub-roles-collapse ul:last-child {
        margin-bottom: 0; } }

.loading-app-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.page-not-found {
  padding-top: 80px; }
  .page-not-found h2 {
    margin-bottom: 20px; }

.login {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .login .login-form {
    width: 90vw;
    max-width: 400px;
    margin: 60px 10px 15px 10px;
    padding: 30px;
    box-shadow: 0 2px 2px #0000004d;
    border: 1px solid #e7e7e7;
    background: #f7f7f7;
    border-radius: 5px; }
    .login .login-form h4 {
      margin-bottom: 20px; }
  .login .additional-actions {
    width: 90vw;
    max-width: 400px;
    margin-bottom: 10px; }
    .login .additional-actions .additional-action-column {
      padding: 0 5px; }

.two-fa-login-label {
  margin-bottom: 20px; }

.two-fa-login-modal-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; }

.alert.two-fa-alert {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 3px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000; }

.server-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px; }
  .server-error h2 {
    margin-bottom: 20px; }

.applicant-skills {
  display: flex;
  flex-direction: column;
  align-items: center; }

.legend {
  display: flex;
  margin: 10px 0; }
  .legend.vertical {
    align-self: flex-start; }
  .legend .legend-item {
    display: flex;
    align-items: center;
    margin: 0 5px;
    cursor: default; }
    .legend .legend-item .skill-level {
      width: 35px;
      min-width: 35px;
      height: 20px;
      border: solid 2px #a9a9a9;
      border-radius: 10px;
      margin-right: 5px;
      background: #ffffff; }
      .legend .legend-item .skill-level.no-level {
        border-color: #a9a9a9; }
        .legend .legend-item .skill-level.no-level.light-level {
          border-color: #a9a9a9; }
      .legend .legend-item .skill-level.level-ninja {
        border-color: #333333; }
        .legend .legend-item .skill-level.level-ninja.light-level {
          border-color: #797979; }
      .legend .legend-item .skill-level.level-senior {
        border-color: #ab00de; }
        .legend .legend-item .skill-level.level-senior.light-level {
          border-color: #e48efd; }
      .legend .legend-item .skill-level.level-medior {
        border-color: #1e90ff; }
        .legend .legend-item .skill-level.level-medior.light-level {
          border-color: #aed7ff; }
      .legend .legend-item .skill-level.level-junior {
        border-color: #f57900; }
        .legend .legend-item .skill-level.level-junior.light-level {
          border-color: #ffcd9b; }
      .legend .legend-item .skill-level.perfect-match {
        border-color: #00da35; }
      .legend .legend-item .skill-level.lower-level {
        border-color: #f57900; }
      .legend .legend-item .skill-level.missing-skill {
        border-color: #ff2034; }
    .legend .legend-item .skill-status {
      width: 35px;
      min-width: 35px;
      height: 20px;
      border: solid 2px;
      border-radius: 10px;
      margin-right: 5px;
      background: #ffffff; }
      .legend .legend-item .skill-status.status-new {
        border-color: #40e0d0; }
      .legend .legend-item .skill-status.status-evergreen {
        border-color: #75b444; }
      .legend .legend-item .skill-status.status-fading {
        border-color: #333333; }
    .legend .legend-item .not-moderated-icon {
      margin-right: 5px; }
  .legend .legend-parts-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly; }
    .legend .legend-parts-container.vertical {
      flex-direction: column; }
    .legend .legend-parts-container .legend-part {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .legend .legend-parts-container .legend-part .legend-part-title {
        font-size: 14px;
        font-weight: 600; }
      .legend .legend-parts-container .legend-part .legend-part-content {
        display: flex; }

@charset "UTF-8";
.skills-map {
  max-width: 530px;
  width: 100%; }
  .skills-map.сentered {
    align-self: center; }
  .skills-map.with-secondary {
    max-width: 980px; }
  .skills-map .cluster {
    stroke: #a9a9a9;
    fill: none; }
    .skills-map .cluster.cluster-selected {
      fill: #eaeaea; }
    .skills-map .cluster.cluster-suggested {
      stroke: #7fbb8c; }
  .skills-map .cluster-text-container {
    color: #6c757c;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px; }
    .skills-map .cluster-text-container .text-container {
      white-space: nowrap;
      overflow: hidden; }
  .skills-map .secondary-skills-divider {
    stroke: #a9a9a9; }
  .skills-map .skill {
    display: flex;
    background: #ffffff;
    height: 32px;
    width: calc(100% - 10px);
    position: fixed;
    font-family: Arial, Helvetica, sans-serif; }
    .skills-map .skill .skill-text-container {
      max-width: 340px; }
  .skills-map .connector {
    stroke: #a9a9a9; }

.skill {
  display: inline-block;
  min-height: 32px;
  height: auto;
  border: 2px solid #a9a9a9;
  border-radius: 16px;
  color: #6c757c;
  padding: 2px 12px;
  position: relative;
  margin: 5px;
  cursor: default;
  font-size: 16px;
  word-wrap: break-word; }
  .skill.skill-text-overflow-ellipsis .skill-text-container {
    white-space: nowrap;
    overflow: hidden; }
  .skill .icon-container {
    position: absolute; }
    .skill .icon-container.top-left {
      top: -4px;
      left: -6px; }
    .skill .icon-container.top-right {
      top: -4px;
      right: -6px; }
    .skill .icon-container.bottom-right {
      bottom: -4px;
      right: -6px; }
    .skill .icon-container.bottom-left {
      bottom: -4px;
      left: -6px; }
    .skill .icon-container.info-icon {
      width: 20px;
      height: 20px;
      right: -8px; }
      .skill .icon-container.info-icon > svg {
        width: 20px;
        height: 20px; }
  .skill.skill-selected {
    color: #ffffff; }
    .skill.skill-selected.cluster {
      color: #6c757c;
      background: #eaeaea; }
  .skill.skill-clickable {
    cursor: pointer; }
  .skill.skill-added, .skill.cluster-added {
    border-color: #a9a9a9; }
  .skill.skill-not-added, .skill.cluster-not-added {
    border-color: #7fbb8c; }
  .skill.no-level {
    border-color: #a9a9a9; }
    .skill.no-level.skill-selected {
      background-color: #a9a9a9; }
      .skill.no-level.skill-selected.requirement-coefficient-crucial {
        box-shadow: 0px 0px 0px 2px #707070; }
    .skill.no-level.requirement-coefficient-crucial.skill-selected {
      border-color: #707070; }
    .skill.no-level.requirement-coefficient-required.skill-selected {
      border-color: #707070; }
    .skill.no-level.requirement-coefficient-nice-to-have.skill-selected {
      border-color: #707070; }
    .skill.no-level.requirement-coefficient-crucial {
      box-shadow: 0px 0px 0px 2px #a9a9a9; }
  .skill.no-level-secondary {
    border-color: #a9a9a9; }
  .skill.level-ninja {
    border-color: #333333; }
    .skill.level-ninja.skill-selected {
      background-color: #333333; }
      .skill.level-ninja.skill-selected.requirement-coefficient-crucial {
        box-shadow: 0px 0px 0px 2px #a9a9a9; }
    .skill.level-ninja.requirement-coefficient-crucial.skill-selected {
      border-color: #a9a9a9; }
    .skill.level-ninja.requirement-coefficient-required.skill-selected {
      border-color: #a9a9a9; }
    .skill.level-ninja.requirement-coefficient-nice-to-have.skill-selected {
      border-color: #a9a9a9; }
    .skill.level-ninja.requirement-coefficient-crucial {
      box-shadow: 0px 0px 0px 2px #333333; }
  .skill.level-ninja-secondary {
    border-color: #797979; }
  .skill.level-senior {
    border-color: #ab00de; }
    .skill.level-senior.skill-selected {
      background-color: #ab00de; }
      .skill.level-senior.skill-selected.requirement-coefficient-crucial {
        box-shadow: 0px 0px 0px 2px #55006f; }
    .skill.level-senior.requirement-coefficient-crucial.skill-selected {
      border-color: #55006f; }
    .skill.level-senior.requirement-coefficient-required.skill-selected {
      border-color: #55006f; }
    .skill.level-senior.requirement-coefficient-nice-to-have.skill-selected {
      border-color: #55006f; }
    .skill.level-senior.requirement-coefficient-crucial {
      box-shadow: 0px 0px 0px 2px #ab00de; }
  .skill.level-senior-secondary {
    border-color: #e48efd; }
  .skill.level-medior {
    border-color: #1e90ff; }
    .skill.level-medior.skill-selected {
      background-color: #1e90ff; }
      .skill.level-medior.skill-selected.requirement-coefficient-crucial {
        box-shadow: 0px 0px 0px 2px #125699; }
    .skill.level-medior.requirement-coefficient-crucial.skill-selected {
      border-color: #125699; }
    .skill.level-medior.requirement-coefficient-required.skill-selected {
      border-color: #125699; }
    .skill.level-medior.requirement-coefficient-nice-to-have.skill-selected {
      border-color: #125699; }
    .skill.level-medior.requirement-coefficient-crucial {
      box-shadow: 0px 0px 0px 2px #1e90ff; }
  .skill.level-medior-secondary {
    border-color: #aed7ff; }
  .skill.level-junior {
    border-color: #f57900; }
    .skill.level-junior.skill-selected {
      background-color: #f57900; }
      .skill.level-junior.skill-selected.requirement-coefficient-crucial {
        box-shadow: 0px 0px 0px 2px #a54600; }
    .skill.level-junior.requirement-coefficient-crucial.skill-selected {
      border-color: #a54600; }
    .skill.level-junior.requirement-coefficient-required.skill-selected {
      border-color: #a54600; }
    .skill.level-junior.requirement-coefficient-nice-to-have.skill-selected {
      border-color: #a54600; }
    .skill.level-junior.requirement-coefficient-crucial {
      box-shadow: 0px 0px 0px 2px #f57900; }
  .skill.level-junior-secondary {
    border-color: #ffcd9b; }
  .skill.status-new {
    border-color: #40e0d0; }
    .skill.status-new.skill-selected {
      background-color: #40e0d0; }
  .skill.status-evergreen {
    border-color: #75b444; }
    .skill.status-evergreen.skill-selected {
      background-color: #75b444; }
  .skill.status-fading {
    border-color: #333333; }
    .skill.status-fading.skill-selected {
      background-color: #333333; }
  .skill.perfect-match {
    border-color: #00da35; }
    .skill.perfect-match.requirement-coefficient-crucial {
      border-color: #00da35; }
    .skill.perfect-match.requirement-coefficient-required {
      border-color: #00da35; }
    .skill.perfect-match.requirement-coefficient-nice-to-have {
      border-color: #00da35; }
    .skill.perfect-match.actual-level-ninja:before {
      content: " ";
      position: absolute;
      z-index: -1;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      border: 4px solid #333333;
      border-radius: 20px; }
    .skill.perfect-match.requirement-coefficient-crucial {
      box-shadow: 0px 0px 0px 2px #00da35; }
  .skill.lower-level {
    border-color: #f57900; }
    .skill.lower-level.requirement-coefficient-crucial {
      border-color: #f57900; }
    .skill.lower-level.requirement-coefficient-required {
      border-color: #f57900; }
    .skill.lower-level.requirement-coefficient-nice-to-have {
      border-color: #f57900; }
    .skill.lower-level.actual-level-ninja:before {
      content: " ";
      position: absolute;
      z-index: -1;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      border: 4px solid #333333;
      border-radius: 20px; }
    .skill.lower-level.requirement-coefficient-crucial {
      box-shadow: 0px 0px 0px 2px #f57900; }
  .skill.missing-skill {
    border-color: #ff2034; }
    .skill.missing-skill.requirement-coefficient-crucial {
      border-color: #ff2034; }
    .skill.missing-skill.requirement-coefficient-required {
      border-color: #ff2034; }
    .skill.missing-skill.requirement-coefficient-nice-to-have {
      border-color: #ff2034; }
    .skill.missing-skill.actual-level-ninja:before {
      content: " ";
      position: absolute;
      z-index: -1;
      top: -6px;
      left: -6px;
      right: -6px;
      bottom: -6px;
      border: 4px solid #333333;
      border-radius: 20px; }
    .skill.missing-skill.requirement-coefficient-crucial {
      box-shadow: 0px 0px 0px 2px #ff2034; }
  .skill.requirement-coefficient-nice-to-have {
    border-style: dashed; }

.user-photo {
  background-size: cover;
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  border: 8px solid #f1f1f1;
  border-radius: 50%; }
  .user-photo.is-dragging {
    z-index: 10001; }
  .user-photo.anonymity-mode {
    box-shadow: inset 0px 0px 2px 1px #00000080;
    background-color: #ffffff; }
  .user-photo.clickable {
    cursor: pointer; }
  .user-photo.selected.single-mode {
    border-color: #74cc80; }
  .user-photo.selected.multiple-mode {
    border-color: #458afc; }
  .user-photo.size-sm {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-width: 4px; }
  .user-photo.size-md {
    width: 90px;
    height: 90px;
    min-width: 90px;
    min-height: 90px;
    border-width: 8px; }
  .user-photo.size-lg {
    width: 120px;
    height: 120px;
    min-width: 120px;
    min-height: 120px;
    border-width: 10px; }

.skill-notes-popover .popover-inner {
  width: 250px;
  max-height: 400px;
  overflow-y: auto; }

.skill-notes-popover .list-group-item {
  padding: 10px; }

.skill-notes-popover .note-date {
  color: #a9a9a9;
  font-size: 12px; }

.skill-comparison-popover .actual-level,
.skill-comparison-popover .required-level {
  font-weight: 600; }
  .skill-comparison-popover .actual-level.no-level,
  .skill-comparison-popover .required-level.no-level {
    color: #a9a9a9; }
  .skill-comparison-popover .actual-level.level-ninja,
  .skill-comparison-popover .required-level.level-ninja {
    color: #333333; }
  .skill-comparison-popover .actual-level.level-senior,
  .skill-comparison-popover .required-level.level-senior {
    color: #ab00de; }
  .skill-comparison-popover .actual-level.level-medior,
  .skill-comparison-popover .required-level.level-medior {
    color: #1e90ff; }
  .skill-comparison-popover .actual-level.level-junior,
  .skill-comparison-popover .required-level.level-junior {
    color: #f57900; }
  .skill-comparison-popover .actual-level.missing-skill,
  .skill-comparison-popover .required-level.missing-skill {
    color: #ff2034;
    font-weight: bold; }

.skill-comparison-popover .skill-title {
  font-weight: 600; }

.skill-comparison-notes-popover .popover-inner {
  width: 250px;
  max-height: 400px;
  overflow-y: auto; }

.skill-comparison-notes-popover .notes-group-title {
  font-weight: 600;
  text-align: center; }

.skill-comparison-notes-popover .list-group-item {
  padding: 10px; }

.skill-comparison-notes-popover .custom-property-title {
  color: #0012ff;
  font-size: 12px; }

.skill-comparison-notes-popover .notes-container {
  margin: 5px 0; }

.skill-comparison-notes-popover .note-date {
  color: #a9a9a9;
  font-size: 12px; }

.edit-applicant-skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }
  .edit-applicant-skills .loading-save-changes-container {
    display: flex;
    align-items: center;
    margin: 0 10px; }
  .edit-applicant-skills .save-changes-container {
    position: absolute;
    top: 10px; }

.skills-setup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 0; }
  .skills-setup .alert {
    font-size: 14px;
    padding: 0.5rem 0.75rem; }
    .skills-setup .alert.overflow-auto {
      overflow-y: auto; }
    .skills-setup .alert.alert-dismissible {
      padding-right: 2rem; }
      .skills-setup .alert.alert-dismissible .close {
        padding: 0.5rem;
        padding-top: 0.25rem; }
    .skills-setup .alert ul {
      margin-bottom: 0; }
  .skills-setup .skills-setup-content {
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 0; }
    .skills-setup .skills-setup-content .menu-column-container {
      width: 25%;
      max-width: 25%;
      padding: 0 5px 5px 5px; }
      @media (min-width: 1500px) {
        .skills-setup .skills-setup-content .menu-column-container {
          max-width: 400px;
          width: 400px; } }
    .skills-setup .skills-setup-content .skills-map-column {
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 100%;
      max-height: 100%;
      position: relative;
      padding-bottom: 5px; }
  .skills-setup .hint-info-icon {
    position: absolute;
    display: inline;
    margin-left: 5px;
    cursor: pointer; }
  .skills-setup .hint-info-icon-with-arrow {
    margin-left: 20px; }
  .skills-setup .profiles-settings-container,
  .skills-setup .skills-map-container,
  .skills-setup .skill-settings-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #a9a9a9;
    background-color: #f9f9f9;
    border-radius: 5px;
    height: 100%;
    max-height: 100%; }
    .skills-setup .profiles-settings-container .info-wrapper,
    .skills-setup .skills-map-container .info-wrapper,
    .skills-setup .skill-settings-container .info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px;
      background-color: #878787;
      margin-top: 10px; }
      .skills-setup .profiles-settings-container .info-wrapper > h6,
      .skills-setup .skills-map-container .info-wrapper > h6,
      .skills-setup .skill-settings-container .info-wrapper > h6 {
        color: white; }
      .skills-setup .profiles-settings-container .info-wrapper > div,
      .skills-setup .skills-map-container .info-wrapper > div,
      .skills-setup .skill-settings-container .info-wrapper > div {
        display: flex;
        flex-direction: row;
        align-items: center; }
        .skills-setup .profiles-settings-container .info-wrapper > div > button,
        .skills-setup .skills-map-container .info-wrapper > div > button,
        .skills-setup .skill-settings-container .info-wrapper > div > button {
          margin-right: 10px;
          background-color: #75b444;
          border-radius: 50px;
          min-width: 100px; }
        .skills-setup .profiles-settings-container .info-wrapper > div > h6,
        .skills-setup .skills-map-container .info-wrapper > div > h6,
        .skills-setup .skill-settings-container .info-wrapper > div > h6 {
          margin-right: 10px;
          color: white; }
          .skills-setup .profiles-settings-container .info-wrapper > div > h6 > a,
          .skills-setup .skills-map-container .info-wrapper > div > h6 > a,
          .skills-setup .skill-settings-container .info-wrapper > div > h6 > a {
            color: #75b444;
            text-decoration: underline; }
  .skills-setup .profiles-settings-container .profile-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;
    border: 1px solid #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px;
    padding: 10px; }
    .skills-setup .profiles-settings-container .profile-card .list-group-item {
      display: flex;
      padding: 10px 5px;
      align-items: center;
      justify-content: space-between;
      min-height: -webkit-fit-content;
      min-height: -moz-fit-content;
      min-height: fit-content; }
      .skills-setup .profiles-settings-container .profile-card .list-group-item .icon-container {
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer; }
  .skills-setup .profiles-settings-container .profile-items-container {
    height: 100%;
    border: 1px solid #ced4dd;
    border-radius: 5px;
    margin-top: 5px;
    overflow: auto; }
  .skills-setup .profiles-settings-container .profile-item-with-checkbox-container {
    display: flex;
    align-items: center; }
  .skills-setup .profiles-settings-container .profile-item {
    margin-left: 5px; }
  .skills-setup .profiles-settings-container .search-profiles-input-group {
    padding-bottom: 5px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content; }
  .skills-setup .skills-map-component-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 10px;
    margin: 5px;
    box-shadow: 0 0 0 1px #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px; }
  .skills-setup .skills-map-container .legend {
    justify-content: space-evenly;
    margin: 0;
    margin-top: 5px; }
  .skills-setup .skill-settings-container .skill-settings-content-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%; }
  .skills-setup .skill-settings-container .skill-settings-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px;
    padding: 10px;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content; }
    .skills-setup .skill-settings-container .skill-settings-card.card-close {
      min-height: 55px; }
    .skills-setup .skill-settings-container .skill-settings-card.semantic-context {
      height: auto;
      overflow-y: auto;
      max-height: 50%; }
      .skills-setup .skill-settings-container .skill-settings-card.semantic-context.card-open {
        min-height: 290px; }
        .skills-setup .skill-settings-container .skill-settings-card.semantic-context.card-open.without-skills {
          min-height: 170px; }
    .skills-setup .skill-settings-container .skill-settings-card.search-skills {
      height: auto;
      overflow-y: auto;
      max-height: 50%; }
      .skills-setup .skill-settings-container .skill-settings-card.search-skills.card-open {
        min-height: 240px; }
    .skills-setup .skill-settings-container .skill-settings-card.skill-properties .row.form-group {
      margin-bottom: 5px; }
    .skills-setup .skill-settings-container .skill-settings-card.skill-properties label {
      word-break: break-word; }
    .skills-setup .skill-settings-container .skill-settings-card.skill-properties .select-eagerness-custom-option {
      display: flex;
      align-items: center; }
      .skills-setup .skill-settings-container .skill-settings-card.skill-properties .select-eagerness-custom-option:hover:not(.select-eagerness-custom-option-selected) {
        background: #e8e8e8; }
        .skills-setup .skill-settings-container .skill-settings-card.skill-properties .select-eagerness-custom-option:hover:not(.select-eagerness-custom-option-selected).love-it {
          background: #e3f3d2; }
        .skills-setup .skill-settings-container .skill-settings-card.skill-properties .select-eagerness-custom-option:hover:not(.select-eagerness-custom-option-selected).ok {
          background: #e6fffc; }
        .skills-setup .skill-settings-container .skill-settings-card.skill-properties .select-eagerness-custom-option:hover:not(.select-eagerness-custom-option-selected).if-need-be {
          background: #ffeecb; }
      .skills-setup .skill-settings-container .skill-settings-card.skill-properties .select-eagerness-custom-option.select-eagerness-custom-option-selected {
        font-weight: 500; }
        .skills-setup .skill-settings-container .skill-settings-card.skill-properties .select-eagerness-custom-option.select-eagerness-custom-option-selected.love-it {
          background: #e3f3d2; }
        .skills-setup .skill-settings-container .skill-settings-card.skill-properties .select-eagerness-custom-option.select-eagerness-custom-option-selected.ok {
          background: #e6fffc; }
        .skills-setup .skill-settings-container .skill-settings-card.skill-properties .select-eagerness-custom-option.select-eagerness-custom-option-selected.if-need-be {
          background: #ffeecb; }
      .skills-setup .skill-settings-container .skill-settings-card.skill-properties .select-eagerness-custom-option .select-eagerness-custom-option-icon {
        display: flex;
        margin: 0 8px; }
    .skills-setup .skill-settings-container .skill-settings-card.skill-properties .skill-version-ratio-col {
      padding-right: 1px; }
    .skills-setup .skill-settings-container .skill-settings-card.skill-properties .skill-version-value-col {
      padding-left: 1px;
      max-width: 90px; }
    .skills-setup .skill-settings-container .skill-settings-card.skill-properties .input-select-eagerness-single-value {
      display: flex;
      align-items: center; }
      .skills-setup .skill-settings-container .skill-settings-card.skill-properties .input-select-eagerness-single-value .input-select-eagerness-single-value-icon {
        display: flex;
        margin-right: 8px; }
    .skills-setup .skill-settings-container .skill-settings-card.skill-properties .notes-icon {
      padding: 0 5px;
      display: inline; }
    .skills-setup .skill-settings-container .skill-settings-card.define-new-skill .row.form-group {
      margin-bottom: 5px; }
    .skills-setup .skill-settings-container .skill-settings-card.define-new-skill .form-check {
      padding-left: 8px; }
      .skills-setup .skill-settings-container .skill-settings-card.define-new-skill .form-check .form-check-label {
        padding-top: 0; }
    .skills-setup .skill-settings-container .skill-settings-card.semantic-context .collapse.show,
    .skills-setup .skill-settings-container .skill-settings-card.semantic-context .collapsing, .skills-setup .skill-settings-container .skill-settings-card.search-skills .collapse.show,
    .skills-setup .skill-settings-container .skill-settings-card.search-skills .collapsing {
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      min-height: 0; }
    .skills-setup .skill-settings-container .skill-settings-card h6.with-collapse {
      position: relative;
      margin-right: 15px; }
      .skills-setup .skill-settings-container .skill-settings-card h6.with-collapse .icon-container {
        position: absolute;
        display: inline; }
  .skills-setup .skill-settings-container .bread-crumbs-container {
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
    align-items: center;
    margin: 5px 0;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content; }
    .skills-setup .skill-settings-container .bread-crumbs-container .bread-crumbs-element {
      display: flex;
      align-items: center;
      max-width: 90%; }
      .skills-setup .skill-settings-container .bread-crumbs-container .bread-crumbs-element .skill {
        margin: 2px 0;
        max-width: 100%;
        font-size: 14px;
        padding: 1px 10px;
        min-height: auto; }
        .skills-setup .skill-settings-container .bread-crumbs-container .bread-crumbs-element .skill .skill-text-container {
          max-width: 100%; }
      .skills-setup .skill-settings-container .bread-crumbs-container .bread-crumbs-element .icon-container svg {
        fill: #a9a9a9; }
  .skills-setup .skill-settings-container .current-cluster-skills-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding: 5px; }
    .skills-setup .skill-settings-container .current-cluster-skills-container .skill {
      margin: 3px 5px;
      max-width: 95%; }
      .skills-setup .skill-settings-container .current-cluster-skills-container .skill .skill-text-container {
        max-width: 100%; }
  .skills-setup .skill-settings-container .search-skills {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .skills-setup .skill-settings-container .search-skills .search-items-container {
      width: 100%;
      height: 100%;
      overflow: auto;
      border: 1px solid #ced4dd;
      border-radius: 5px;
      margin-top: 5px; }
      .skills-setup .skill-settings-container .search-skills .search-items-container .list-group-item {
        display: flex;
        padding: 0;
        padding-right: 5px;
        justify-content: space-between;
        min-height: -webkit-fit-content;
        min-height: -moz-fit-content;
        min-height: fit-content; }
        .skills-setup .skill-settings-container .search-skills .search-items-container .list-group-item .skill {
          max-width: 50%; }
        .skills-setup .skill-settings-container .search-skills .search-items-container .list-group-item .node-semantic-context {
          text-align: right;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          max-width: 45%; }
          .skills-setup .skill-settings-container .search-skills .search-items-container .list-group-item .node-semantic-context .cluster {
            color: #a9a9a9;
            font-size: 14px;
            height: 21px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%; }
    .skills-setup .skill-settings-container .search-skills .search-skills-input-group {
      padding-bottom: 5px;
      min-height: -webkit-fit-content;
      min-height: -moz-fit-content;
      min-height: fit-content; }

.cluster-skills-popover .skills-items-container {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ced4dd;
  border-radius: 5px; }
  .cluster-skills-popover .skills-items-container .list-group-item {
    display: flex;
    font-size: 14px;
    padding: 6px 3px;
    align-items: center;
    justify-content: space-between; }
    .cluster-skills-popover .skills-items-container .list-group-item .text-container {
      margin-left: 5px; }
    .cluster-skills-popover .skills-items-container .list-group-item .icon-container {
      margin-left: 5px;
      margin-right: 5px; }

.modal-dialog.skill-notes-modal .modal-header .icon-container {
  display: inline-block;
  padding: 0 5px; }

.modal-dialog.skill-notes-modal .modal-footer {
  justify-content: flex-end; }

.modal-dialog.skill-notes-modal .button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0; }

.modal-dialog.skill-notes-modal .notes-container {
  overflow-y: auto;
  max-height: 40vh;
  border: 1px solid #ced4dd;
  border-radius: 5px; }
  .modal-dialog.skill-notes-modal .notes-container .note-date {
    color: #a9a9a9;
    font-size: 14px; }
  .modal-dialog.skill-notes-modal .notes-container .note {
    display: flex;
    justify-content: space-between; }

.skills-without-level-modal .list-group {
  max-height: 300px;
  overflow-y: auto;
  border: solid 1px #dfdfdf;
  border-radius: 4px; }

.welcome-page {
  max-width: 1100px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
  white-space: pre-wrap; }
  .welcome-page li {
    font-size: 16px; }

.main-color-text {
  color: #89aa11; }

.white-space-normal {
  white-space: normal; }

#video {
  width: 250px;
  height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: -3; }

.home-page {
  background-color: #000000; }
  .home-page__header {
    color: #ffffff; }
  .home-page__node-link {
    z-index: 4;
    color: #fbdcd5;
    text-decoration: none;
    font-weight: bold; }
  .home-page__video-container {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: -5; }
  .home-page__icon-wrapper {
    display: flex;
    background: radial-gradient(circle, white 0%, black 75%);
    width: 70px;
    height: 70px;
    justify-content: center;
    align-items: center;
    border-radius: 50%; }
  .home-page__node-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .home-page__node {
    overflow: hidden;
    cursor: pointer;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    width: 230px;
    height: 230px;
    border-radius: 50px; }
  .home-page__edge-helper {
    fill: #ffffff;
    font-size: 14px;
    text-anchor: middle;
    z-index: 15;
    font-style: italic;
    font-weight: bold; }
    .home-page__edge-helper--active {
      filter: url(#activeHelperBg); }
  .home-page__edge-notifications {
    font-size: 14px;
    opacity: 1;
    fill: white;
    font-weight: bold;
    font-style: italic; }
  .home-page__edge-notifications-bg {
    fill: #89aa11; }
  .home-page__edge {
    stroke: "grey";
    stroke-width: 2;
    z-index: 100;
    white-space: pre-line; }
    .home-page__edge--active {
      opacity: 1; }
    .home-page__edge--inactive {
      opacity: 1; }
  .home-page__edge-path {
    stroke-width: 1; }

.create-new-job {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }
  .create-new-job .nav .nav-link {
    cursor: pointer; }
  .create-new-job .nav-tabs {
    width: 100%;
    justify-content: center; }
  .create-new-job .tab-content {
    min-height: 1px;
    height: 100%;
    width: 100%; }
  .create-new-job .tab-pane {
    height: 100%; }
  .create-new-job .submit-new-job-container {
    position: absolute;
    top: 15px; }

.open-job-details {
  display: flex;
  flex-direction: column;
  align-items: center; }

.candidates-comparison {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 0; }
  .candidates-comparison .candidates-comparison-content-container {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 0; }
    .candidates-comparison .candidates-comparison-content-container .content-column {
      max-height: 100%; }
      .candidates-comparison .candidates-comparison-content-container .content-column.candidates-stack-column {
        padding: 0 5px 5px 5px;
        min-width: 200px;
        max-width: 260px; }
      .candidates-comparison .candidates-comparison-content-container .content-column.details-column {
        padding: 0 5px 5px 0;
        max-width: calc(100% - 260px); }
    .candidates-comparison .candidates-comparison-content-container .candidates-stack-wrapper {
      display: flex;
      flex-direction: column;
      padding: 10px;
      padding-bottom: 0;
      border: 1px solid #a9a9a9;
      background-color: #f9f9f9;
      border-radius: 5px;
      height: 100%;
      max-height: 100%; }
    .candidates-comparison .candidates-comparison-content-container .details-wrapper {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border: 1px solid #a9a9a9;
      background-color: #f9f9f9;
      border-radius: 5px;
      height: 100%;
      max-height: 100%;
      position: relative;
      padding: 10px;
      padding-top: 65px;
      padding-left: 110px; }
      .candidates-comparison .candidates-comparison-content-container .details-wrapper.hard-skills-mode {
        padding-right: 110px; }
      .candidates-comparison .candidates-comparison-content-container .details-wrapper.soft-skills-mode {
        padding-right: 110px;
        padding-bottom: 65px; }
      .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane {
        display: flex;
        position: absolute;
        justify-content: center; }
        .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane {
          top: 0;
          left: 0;
          right: 0; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane .pane-button-dropdown .pane-button {
            border-top: 0;
            border-bottom-right-radius: 3px;
            box-shadow: none; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane .pane-button-dropdown .pane-button-dropdown-item,
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane .pane-button-dropdown .pane-button-dropdown-item {
            color: #212529;
            outline: none;
            cursor: pointer;
            padding: 5px 10px;
            font-size: 14px;
            display: flex;
            align-items: center; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane .pane-button-dropdown .pane-button-dropdown-item .icon-container,
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane .pane-button-dropdown .pane-button-dropdown-item .icon-container {
              margin-right: 5px; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane .pane-button-dropdown .dropdown-menu {
            outline: none; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane .pane-button-dropdown .pane-button-dropdown-item.active,
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane .pane-button-dropdown .pane-button-dropdown-item:active {
            color: #212529;
            background: #f9f9f9; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane .pane-button-dropdown .comparing-mode-icon {
            margin-right: 0;
            margin-left: 5px; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane > .pane-button {
            border-top: 0;
            border-right: 0; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane > .pane-button:first-child {
              border-bottom-left-radius: 3px; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.mode-pane .pane-button .icon-container {
            margin-right: 5px; }
        .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane {
          flex-direction: column;
          top: 0;
          bottom: 0;
          left: 0; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button {
            position: relative;
            flex-direction: column;
            width: 100px;
            max-width: 100px;
            border-left: 0;
            padding: 10px 6px;
            padding-top: 15px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button:first-child {
              border-top-right-radius: 3px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button:last-child {
              border-bottom-right-radius: 3px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button:not(:last-child) {
              border-bottom: 0; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button .icon-container {
              margin-bottom: 5px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button .pane-button-label {
              text-align: center; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button .count-badge {
              position: absolute;
              top: 2px;
              right: 2px;
              padding: 3px 6px; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button-collapse {
            display: flex;
            position: absolute;
            overflow: hidden;
            height: calc(100% + 2px);
            left: 100px;
            top: -1px;
            width: 0;
            transition: width 0.5s ease-in-out;
            z-index: 10; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button-collapse .pane-sub-button {
              position: relative;
              flex-direction: column;
              display: flex;
              align-items: center;
              background: #f9f9f9;
              border: 1px solid #a9a9a9;
              border-left: 0;
              padding: 10px 6px;
              padding-top: 15px;
              min-width: 100px;
              width: 100px;
              max-width: 100px;
              height: 100%; }
              .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button-collapse .pane-sub-button:hover {
                background: #e8e8e8; }
              .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button-collapse .pane-sub-button:last-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px; }
              .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button-collapse .pane-sub-button .info-icon {
                position: absolute;
                top: 2px;
                right: 2px; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button:hover,
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button.ready-for-drop {
            border-bottom-right-radius: 0px !important; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button:hover .pane-button-collapse,
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.groups-pane .pane-button.ready-for-drop .pane-button-collapse {
              width: 300px; }
        .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.soft-skills-pane {
          bottom: 0;
          left: 0;
          right: 0;
          justify-content: space-evenly; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.soft-skills-pane .pane-buttons-container {
            display: flex; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.soft-skills-pane .pane-button {
            border-bottom: 0;
            padding: 10px 15px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.soft-skills-pane .pane-button:first-child {
              border-top-left-radius: 3px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.soft-skills-pane .pane-button:last-child {
              border-top-right-radius: 3px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.soft-skills-pane .pane-button:not(:last-child) {
              border-right: 0; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.soft-skills-pane .icon-container {
            margin-right: 5px; }
        .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.candidate-pane {
          flex-direction: column;
          justify-content: space-evenly;
          top: 0;
          bottom: 0;
          right: 0; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.candidate-pane .pane-button {
            flex-direction: column;
            width: 100px;
            max-width: 100px;
            border-right: 0;
            padding: 10px 6px;
            padding-bottom: 8px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.candidate-pane .pane-button:first-child {
              border-top-left-radius: 3px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.candidate-pane .pane-button:last-child {
              border-bottom-left-radius: 3px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.candidate-pane .pane-button:not(:last-child) {
              border-bottom: 0; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.candidate-pane .pane-button .pane-button-label {
              text-align: center; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane.candidate-pane .pane-button-link {
            flex-direction: column;
            padding: 10px 6px;
            padding-bottom: 8px; }
        .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane .pane-button {
          padding: 10px 15px;
          display: flex;
          align-items: center;
          border: 1px solid #a9a9a9;
          background: #f9f9f9;
          color: #212529;
          cursor: pointer; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane .pane-button:hover:not(.active) {
            background: #e8e8e8; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane .pane-button.active {
            background: #ffffff; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane .pane-button .pane-button-label {
            font-size: 14px; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane .pane-button.ready-for-drop {
            z-index: 10000; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane .pane-button.pane-title {
            cursor: default;
            padding: 0 3px;
            font-weight: 600;
            font-size: 14px; }
            .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane .pane-button.pane-title:hover {
              background: #f9f9f9; }
          .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane .pane-button.with-link {
            padding: 0; }
        .candidates-comparison .candidates-comparison-content-container .details-wrapper .buttons-pane .pane-button-link {
          padding: 10px 15px;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: #212529; }
  .candidates-comparison .drop-overlay {
    z-index: 9999;
    background-color: #bdbdbd6e;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    pointer-events: none; }
  .candidates-comparison .select-candidate-hint {
    display: flex;
    justify-content: center;
    padding-top: 30px; }
    .candidates-comparison .select-candidate-hint.right-padding {
      padding-right: 100px; }

.phone-number-popover .phone-number {
  padding: 15px;
  font-weight: 600; }

.candidates-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%; }
  .candidates-stack .candidates-number {
    display: flex;
    align-items: center; }
    .candidates-stack .candidates-number .badge {
      margin: 0 5px; }
  .candidates-stack .sorting-menu {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .candidates-stack .sorting-menu .sorting-menu-buttons {
      display: flex;
      margin-left: 5px; }
      .candidates-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button {
        border: 1px solid #a9a9a9;
        cursor: pointer; }
        .candidates-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button:hover:not(.active) {
          background: #e8e8e8; }
        .candidates-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button.active {
          background: #ffffff; }
        .candidates-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px; }
        .candidates-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px; }
        .candidates-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button:not(:last-child) {
          border-right: 0; }
        .candidates-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button .icon-container {
          padding: 5px; }
  .candidates-stack .hs-ss-ratio-container {
    display: flex;
    margin-bottom: 10px; }
    .candidates-stack .hs-ss-ratio-container .hs-ss-ratio-input {
      margin: 0 5px; }
  .candidates-stack .candidates-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: 200px;
    background: #ffffff;
    border: 1px solid #a9a9a9;
    border-radius: 5px;
    padding: 10px; }
    .candidates-stack .candidates-list .candidate-card {
      margin: 5px; }
      .candidates-stack .candidates-list .candidate-card .candidate-photo-container {
        padding: 5px 25px;
        position: relative;
        display: flex; }
        .candidates-stack .candidates-list .candidate-card .candidate-photo-container .favorite-icon {
          position: absolute;
          top: 0;
          left: 0; }
          .candidates-stack .candidates-list .candidate-card .candidate-photo-container .favorite-icon svg {
            fill: #ffa500; }
        .candidates-stack .candidates-list .candidate-card .candidate-photo-container .top-x-icon {
          position: absolute;
          top: 0;
          right: 0; }
        .candidates-stack .candidates-list .candidate-card .candidate-photo-container .candidate-notes-icon {
          position: absolute;
          bottom: 0;
          right: 0; }
        .candidates-stack .candidates-list .candidate-card .candidate-photo-container .brainchain-check-mark {
          position: absolute;
          bottom: 0;
          left: 0; }
      .candidates-stack .candidates-list .candidate-card .candidate-name {
        text-align: center; }
        .candidates-stack .candidates-list .candidate-card .candidate-name.selected {
          font-weight: bold; }

.brainchain-check-mark .brainchain-icon {
  cursor: pointer; }
  .brainchain-check-mark .brainchain-icon.no-info .icon-background {
    fill: #de7828; }
  .brainchain-check-mark .brainchain-icon.ok .icon-background {
    fill: #89aa11; }
  .brainchain-check-mark .brainchain-icon.not-ok .icon-background {
    fill: #e81223; }

.brainchain-check-mark-popover .send-invitation-button {
  margin-top: 10px; }

.modal-dialog.candidate-notes-modal .audio-note-container {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .modal-dialog.candidate-notes-modal .audio-note-container .audio-player {
    width: 100%; }
  .modal-dialog.candidate-notes-modal .audio-note-container .audio-control-icon {
    margin-left: 10px;
    padding: 5px; }
    .modal-dialog.candidate-notes-modal .audio-note-container .audio-control-icon:hover {
      background-color: rgba(201, 201, 201, 0.5); }

.candidate-modal-header .modal-title {
  display: flex;
  align-items: center; }

.candidate-modal-header .user-photo {
  margin-left: 5px;
  margin-right: 10px; }

.candidate-modal-header .candidate-buttons .favorite-icon svg {
  fill: #ffa500; }

.modal-dialog.candidates-group-modal .modal-title {
  display: flex;
  align-items: center; }
  .modal-dialog.candidates-group-modal .modal-title .icon-container {
    margin-right: 5px; }
  .modal-dialog.candidates-group-modal .modal-title .group-name {
    margin-bottom: 0; }
  .modal-dialog.candidates-group-modal .modal-title .badge {
    margin-left: 8px; }

.modal-dialog.candidates-group-modal .modal-content {
  max-height: calc(100vh - 150px); }

.modal-dialog.candidates-group-modal .modal-body {
  max-height: 600px;
  overflow-y: auto; }

.modal-dialog.candidates-group-modal .candidates-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; }
  .modal-dialog.candidates-group-modal .candidates-container .candidate-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 5px 10px;
    margin: 0 10px;
    margin-bottom: 10px;
    width: 130px;
    max-width: 130px;
    text-align: center; }
    .modal-dialog.candidates-group-modal .candidates-container .candidate-card .remove-candidate-icon {
      position: absolute;
      top: 0;
      right: 0; }

@media (min-width: 768px) {
  .modal-dialog.candidates-group-modal {
    max-width: 700px; } }

@media (min-height: 768px) {
  .modal-dialog.candidates-group-modal .modal-body {
    min-height: 400px; } }

.hard-skills-comparison {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%; }
  .hard-skills-comparison .candidate-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px; }
    .hard-skills-comparison .candidate-info .buttons-group {
      display: flex; }
      .hard-skills-comparison .candidate-info .buttons-group .icon-container {
        margin: 0 5px; }
    .hard-skills-comparison .candidate-info .user-photo {
      margin-left: 5px;
      margin-right: 10px; }
    .hard-skills-comparison .candidate-info .candidate-name {
      border-bottom: 1px solid #212529; }
    .hard-skills-comparison .candidate-info .favorite-icon svg {
      fill: #ffa500; }
  .hard-skills-comparison .skills-map-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    margin: 10px 5px;
    box-shadow: 0 0 0 1px #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px; }
    .hard-skills-comparison .skills-map-container .skills-map .skill .notes-icon {
      cursor: pointer; }
  .hard-skills-comparison .legend {
    margin: 0; }
  .hard-skills-comparison .skills-map-controls {
    display: flex;
    align-items: center;
    justify-content: center; }
    .hard-skills-comparison .skills-map-controls .controls-title {
      font-size: 14px;
      font-weight: 600; }
    .hard-skills-comparison .skills-map-controls .control-button {
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      margin-left: 10px; }
      .hard-skills-comparison .skills-map-controls .control-button .icon-container {
        margin-right: 3px; }

.candidate-cross-job-info-modal .modal-body {
  padding: 0;
  display: flex; }

.candidate-cross-job-info-modal .modes-buttons {
  border-right: 1px solid #dee2e6; }
  .candidate-cross-job-info-modal .modes-buttons .mode-button {
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #dee2e6; }
    .candidate-cross-job-info-modal .modes-buttons .mode-button:last-child {
      border-bottom: none;
      box-shadow: 0px 1px 0px 0px #dee2e6; }
    .candidate-cross-job-info-modal .modes-buttons .mode-button:hover:not(.active) {
      background-color: #f1f1f1; }
    .candidate-cross-job-info-modal .modes-buttons .mode-button.active {
      background-color: #f1f1f1; }

.candidate-cross-job-info-modal .modes-content {
  width: 100%; }
  .candidate-cross-job-info-modal .modes-content .mode-content {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }

.candidate-cross-job-info-modal .video-player {
  width: 100%; }

.candidate-job-specific-info-modal .modal-body {
  padding: 0;
  display: flex; }

.candidate-job-specific-info-modal .modes-buttons {
  border-right: 1px solid #dee2e6; }
  .candidate-job-specific-info-modal .modes-buttons .mode-button {
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #dee2e6; }
    .candidate-job-specific-info-modal .modes-buttons .mode-button:last-child {
      border-bottom: none;
      box-shadow: 0px 1px 0px 0px #dee2e6; }
    .candidate-job-specific-info-modal .modes-buttons .mode-button:hover:not(.active) {
      background-color: #f1f1f1; }
    .candidate-job-specific-info-modal .modes-buttons .mode-button.active {
      background-color: #f1f1f1; }

.candidate-job-specific-info-modal .modes-content {
  width: 100%; }
  .candidate-job-specific-info-modal .modes-content .mode-content {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }

.candidate-job-specific-info-modal .video-player {
  width: 100%; }

.candidate-job-specific-info-modal .audio-player {
  width: 100%; }

.candidate-job-specific-info-modal .modal-body {
  padding: 0;
  display: flex; }

.candidate-job-specific-info-modal .modes-buttons {
  border-right: 1px solid #dee2e6; }
  .candidate-job-specific-info-modal .modes-buttons .mode-button {
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #dee2e6; }
    .candidate-job-specific-info-modal .modes-buttons .mode-button:last-child {
      border-bottom: none;
      box-shadow: 0px 1px 0px 0px #dee2e6; }
    .candidate-job-specific-info-modal .modes-buttons .mode-button:hover:not(.active) {
      background-color: #f1f1f1; }
    .candidate-job-specific-info-modal .modes-buttons .mode-button.active {
      background-color: #f1f1f1; }

.candidate-job-specific-info-modal .modes-content {
  width: 100%; }
  .candidate-job-specific-info-modal .modes-content .mode-content {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }

.candidate-job-specific-info-modal .video-player {
  width: 100%; }

.candidate-job-specific-info-modal .audio-player {
  width: 100%; }

.soft-skills-comparison {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%; }
  .soft-skills-comparison .candidate-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 15px; }
    .soft-skills-comparison .candidate-info .buttons-group {
      display: flex; }
      .soft-skills-comparison .candidate-info .buttons-group .icon-container {
        margin: 0 5px; }
    .soft-skills-comparison .candidate-info .user-photo {
      margin-left: 5px;
      margin-right: 10px; }
    .soft-skills-comparison .candidate-info .candidate-name {
      border-bottom: 1px solid #212529; }
    .soft-skills-comparison .candidate-info .favorite-icon svg {
      fill: #ffa500; }
  .soft-skills-comparison .soft-skills-ffm {
    margin: 20px; }
  .soft-skills-comparison .soft-skills-test {
    margin: 20px; }
    .soft-skills-comparison .soft-skills-test .soft-skills-test-content {
      margin-bottom: 15px; }
  .soft-skills-comparison .desired-qualities .desired-qualities-items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .soft-skills-comparison .desired-qualities .desired-qualities-items .desired-qualities-item {
      border: 2px solid #7fbb8c;
      min-height: 32px;
      height: auto;
      border-radius: 16px;
      color: #6c757c;
      padding: 2px 12px;
      margin: 5px;
      cursor: default; }
      .soft-skills-comparison .desired-qualities .desired-qualities-items .desired-qualities-item.matched {
        background: #7fbb8c;
        color: #ffffff; }
      .soft-skills-comparison .desired-qualities .desired-qualities-items .desired-qualities-item.missing {
        border-color: #a9a9a9; }
  .soft-skills-comparison .ffm-info {
    display: flex;
    align-items: center;
    justify-content: center; }
    .soft-skills-comparison .ffm-info .ffm-info-icon {
      cursor: pointer;
      display: inline-block;
      margin-left: 5px; }
  .soft-skills-comparison .ffm-data {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 15px; }

.ffm-chart .ffm-background {
  fill: #f1f1f1;
  stroke: #eeeeee;
  stroke-width: 1; }

.ffm-chart .ffm-empty-area {
  fill: white; }

.ffm-chart .ffm-normal-polygon {
  stroke: #04cc40;
  stroke-width: 7;
  stroke-dasharray: 0 16.33;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none; }

.ffm-chart .ffm-normal-point {
  fill: #04cc40;
  stroke: #04cc40;
  stroke-width: 3; }

.ffm-chart .ffm-actual-polygon {
  stroke: #3bcae4;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none; }

.ffm-chart .ffm-actual-point {
  fill: #03a9f4; }

.ffm-chart .range-line {
  stroke: #1e90ff;
  stroke-width: 10; }
  .ffm-chart .range-line.not-match {
    stroke: #ff2034; }
  .ffm-chart .range-line.low-match {
    stroke: #f57900; }
  .ffm-chart .range-line.perfect-match {
    stroke: #00da35; }

.ffm-chart .range-marker-default {
  fill: #1e90ff; }

.ffm-chart .range-marker-not-match {
  fill: #ff2034; }

.ffm-chart .range-marker-low-match {
  fill: #f57900; }

.ffm-chart .range-marker-perfect-match {
  fill: #00da35; }

.dashboard-comparison {
  max-width: 100%;
  overflow-x: auto;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .dashboard-comparison .candidates-info {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 250px;
    margin-bottom: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2; }
    .dashboard-comparison .candidates-info .candidates-info-col {
      display: flex;
      align-items: center;
      border: 1px solid #a9a9a9; }
      .dashboard-comparison .candidates-info .candidates-info-col:not(:last-child) {
        border-right: 0; }
      .dashboard-comparison .candidates-info .candidates-info-col.job-requirements {
        justify-content: center;
        background: #efefef; }
        .dashboard-comparison .candidates-info .candidates-info-col.job-requirements + .candidate-info {
          border-left: 0; }
      .dashboard-comparison .candidates-info .candidates-info-col.candidate-info {
        flex-direction: column;
        padding: 8px;
        background-color: #ffffff;
        position: relative; }
        .dashboard-comparison .candidates-info .candidates-info-col.candidate-info .candidate-controls {
          display: flex;
          margin-bottom: 5px; }
          .dashboard-comparison .candidates-info .candidates-info-col.candidate-info .candidate-controls .buttons-group {
            display: flex;
            align-items: center; }
          .dashboard-comparison .candidates-info .candidates-info-col.candidate-info .candidate-controls .icon-container {
            margin: 0 7px; }
        .dashboard-comparison .candidates-info .candidates-info-col.candidate-info .user-photo {
          margin: 0 5px; }
        .dashboard-comparison .candidates-info .candidates-info-col.candidate-info .candidate-name {
          font-size: 14px;
          text-align: center;
          font-weight: 600;
          line-height: 1.2;
          width: 100%;
          padding: 0 25px;
          margin-bottom: 5px; }
        .dashboard-comparison .candidates-info .candidates-info-col.candidate-info .candidate-notes-icon {
          position: absolute;
          bottom: 4px;
          right: 0; }
        .dashboard-comparison .candidates-info .candidates-info-col.candidate-info .remove-candidate-from-comparison {
          position: absolute;
          top: 0;
          right: 0; }
      .dashboard-comparison .candidates-info .candidates-info-col .favorite-icon svg {
        fill: #ffa500; }
  .dashboard-comparison .hard-skills-header {
    margin-bottom: 5px; }
    .dashboard-comparison .hard-skills-header .hard-skills-controls {
      display: flex;
      align-items: center;
      justify-content: center; }
      .dashboard-comparison .hard-skills-header .hard-skills-controls .controls-title {
        font-size: 14px;
        font-weight: 600; }
      .dashboard-comparison .hard-skills-header .hard-skills-controls .control-button {
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        margin-left: 10px; }
        .dashboard-comparison .hard-skills-header .hard-skills-controls .control-button .icon-container {
          margin-right: 3px; }
  .dashboard-comparison .hard-skills {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .dashboard-comparison .hard-skills .hard-skills-row {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 250px;
      position: relative; }
      .dashboard-comparison .hard-skills .hard-skills-row .cluster-name {
        position: absolute;
        z-index: 1;
        color: white;
        background-color: #a9a9a9;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 0 5px;
        top: 0;
        font-size: 12px;
        left: 50%;
        transform: translate(-50%, 0);
        height: 18px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100% - 40px); }
      .dashboard-comparison .hard-skills .hard-skills-row .cluster {
        border: 1px solid #a9a9a9;
        padding: 17px 5px; }
        .dashboard-comparison .hard-skills .hard-skills-row .cluster:not(:last-child) {
          border-right: 0; }
        .dashboard-comparison .hard-skills .hard-skills-row .cluster.job-cluster {
          background: #efefef; }
          .dashboard-comparison .hard-skills .hard-skills-row .cluster.job-cluster + .candidate-cluster {
            border-left: 0; }
        .dashboard-comparison .hard-skills .hard-skills-row .cluster.candidate-cluster {
          background-color: #ffffff;
          position: relative;
          z-index: 0; }
      .dashboard-comparison .hard-skills .hard-skills-row:not(:last-child) .job-cluster {
        border-bottom: 0; }
      .dashboard-comparison .hard-skills .hard-skills-row:not(:last-child) .candidate-cluster {
        border-bottom: 0; }
      .dashboard-comparison .hard-skills .hard-skills-row:nth-child(2n) .candidate-cluster {
        background-color: #efefef; }
      .dashboard-comparison .hard-skills .hard-skills-row:nth-child(2n) .job-cluster {
        background-color: #e0e0e0; }
    .dashboard-comparison .hard-skills .skill {
      max-width: calc(100% - 10px);
      background: #ffffff; }
      .dashboard-comparison .hard-skills .skill .notes-icon {
        cursor: pointer; }
  .dashboard-comparison .quality-checks {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 250px; }
    .dashboard-comparison .quality-checks .quality-check-label {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #a9a9a9;
      border-right: 0;
      background: #efefef; }
      .dashboard-comparison .quality-checks .quality-check-label h6 {
        margin: 0; }
      .dashboard-comparison .quality-checks .quality-check-label + .quality-check {
        border-left: 0; }
    .dashboard-comparison .quality-checks .quality-check {
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #a9a9a9;
      background-color: #ffffff; }
      .dashboard-comparison .quality-checks .quality-check:not(:last-child) {
        border-right: 0; }
  .dashboard-comparison .soft-skills {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 250px;
    margin-bottom: 20px; }
    .dashboard-comparison .soft-skills .ffm-chart-container {
      border: 1px solid #a9a9a9;
      background-color: #ffffff; }
      .dashboard-comparison .soft-skills .ffm-chart-container:not(:last-child) {
        border-right: 0; }
      .dashboard-comparison .soft-skills .ffm-chart-container.job-ffm {
        border-right: 0;
        background: #efefef; }
        .dashboard-comparison .soft-skills .ffm-chart-container.job-ffm + .ffm-chart-container {
          border-left: 0; }
      .dashboard-comparison .soft-skills .ffm-chart-container .ffm-chart .ffm-background {
        fill: #f9f9f9; }

.model-management {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .model-management .nav .nav-link {
    cursor: pointer; }
  .model-management .nav-tabs {
    width: 100%;
    justify-content: center; }
  .model-management .tab-content {
    min-height: 1px;
    height: 100%;
    width: 100%; }
  .model-management .tab-pane {
    height: 100%; }
  .model-management__add-btn {
    background: none;
    border: none;
    border-radius: 50%; }
    .model-management__add-btn:hover {
      background: none; }
    .model-management__add-btn:active {
      background: none; }
  .model-management .model-management-content {
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 0; }
    .model-management .model-management-content .model-management-nodes-graph-and-list-col {
      padding: 5px; }
    .model-management .model-management-content .model-management-nodes-details-col {
      padding: 5px;
      padding-left: 0; }
    .model-management .model-management-content .model-management-nodes-graph-and-list {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border: 1px solid #a9a9a9;
      background-color: #f9f9f9;
      border-radius: 5px;
      height: 100%;
      max-height: 100%; }
      .model-management .model-management-content .model-management-nodes-graph-and-list .nodes-graph-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 0;
        border: 1px solid #a9a9a9;
        background-color: #ffffff;
        border-radius: 5px;
        margin-bottom: 5px;
        position: relative; }
        .model-management .model-management-content .model-management-nodes-graph-and-list .nodes-graph-content .back-to-list-button {
          position: absolute;
          right: 5px;
          top: 5px;
          z-index: 1; }
      .model-management .model-management-content .model-management-nodes-graph-and-list .nodes-filters {
        border: 1px solid #a9a9a9;
        background-color: #ffffff;
        border-radius: 5px;
        margin-bottom: 5px; }
      .model-management .model-management-content .model-management-nodes-graph-and-list .nodes-legend {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        border: 1px solid #a9a9a9;
        background-color: #ffffff;
        border-radius: 5px;
        padding: 5px; }
        .model-management .model-management-content .model-management-nodes-graph-and-list .nodes-legend:not(:last-child) {
          margin-bottom: 5px; }
        .model-management .model-management-content .model-management-nodes-graph-and-list .nodes-legend .legend-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;
          white-space: pre-wrap;
          width: 50%; }
          .model-management .model-management-content .model-management-nodes-graph-and-list .nodes-legend .legend-item .node-type-short-name {
            font-weight: bold; }
    .model-management .model-management-content .model-management-nodes-details {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border: 1px solid #a9a9a9;
      background-color: #f9f9f9;
      border-radius: 5px;
      height: 100%;
      max-height: 100%; }
      .model-management .model-management-content .model-management-nodes-details .nodes-actions .nodes-actions-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px; }
        .model-management .model-management-content .model-management-nodes-details .nodes-actions .nodes-actions-buttons .btn {
          margin-bottom: 10px; }
  .model-management .nodes-list-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    min-height: 0; }
    .model-management .nodes-list-content .table-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #a9a9a9;
      background-color: #ffffff;
      border-radius: 5px; }
    .model-management .nodes-list-content .nodes-list {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 0;
      max-height: 100%;
      border: 1px solid #a9a9a9;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 10px;
      position: relative;
      overflow-y: auto;
      margin-bottom: 5px; }
      .model-management .nodes-list-content .nodes-list .table {
        margin-bottom: 0;
        table-layout: fixed; }
        .model-management .nodes-list-content .nodes-list .table .system-node-type-cell {
          width: 58px; }
          .model-management .nodes-list-content .nodes-list .table .system-node-type-cell .node-source {
            margin: 0; }
        .model-management .nodes-list-content .nodes-list .table .system-node-name-cell {
          width: 100%;
          padding-top: 8px;
          padding-bottom: 8px; }
          .model-management .nodes-list-content .nodes-list .table .system-node-name-cell .system-node-name-cell-content .node-parent-cluster,
          .model-management .nodes-list-content .nodes-list .table .system-node-name-cell .system-node-name-cell-content .node-domain {
            color: #808080;
            font-size: 12px;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis; }
          .model-management .nodes-list-content .nodes-list .table .system-node-name-cell .system-node-name-cell-content .node-parent-cluster {
            margin-bottom: 5px; }
          .model-management .nodes-list-content .nodes-list .table .system-node-name-cell .system-node-name-cell-content .node-name {
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: flex-start; }
          .model-management .nodes-list-content .nodes-list .table .system-node-name-cell .system-node-name-cell-content .node-domain-badge,
          .model-management .nodes-list-content .nodes-list .table .system-node-name-cell .system-node-name-cell-content .node-parent-cluster-badge {
            font-weight: normal;
            font-size: 12px;
            display: inline; }
          .model-management .nodes-list-content .nodes-list .table .system-node-name-cell .system-node-name-cell-content .node-domain-badge {
            background: #e7ffee; }
          .model-management .nodes-list-content .nodes-list .table .system-node-name-cell .system-node-name-cell-content .node-parent-cluster-badge {
            background: #e7f4ff; }
        .model-management .nodes-list-content .nodes-list .table .system-node-source-cell {
          width: 100px; }
        .model-management .nodes-list-content .nodes-list .table .system-node-action-cell {
          width: 48px; }

.legend-connector {
  height: 2px;
  width: 50%; }
  .legend-connector__p-d {
    background-color: #d9c025; }
  .legend-connector__d-sc {
    background-color: #cc2a7b; }
  .legend-connector__sc-s {
    background-color: #a0c643; }
  .legend-connector__s-s {
    height: 0;
    border-top: dashed #00acbe 4px; }
  .legend-connector__subtitle {
    align-self: center;
    font-size: 16px; }
  .legend-connector__arrow {
    color: #00acbe;
    font-size: 25px; }

.system-node-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  min-width: 34px;
  min-height: 34px;
  border: 3px solid #422c7b;
  color: white;
  background: #a9a9a9;
  border-radius: 17px;
  margin: 5px;
  font-size: 12px; }
  .system-node-status.system-node-status-reference-model {
    background: #89aa11; }
  .system-node-status.system-node-status-node-cemetery {
    background: #be2525; }
  .system-node-status.system-node-status-new-incoming {
    background: #17bbab; }
  .system-node-status.system-node-status-size-sm {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    border-radius: 14px;
    font-size: 10px;
    line-height: 11px; }
  .system-node-status.system-node-status-size-xl {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 25px; }

.legend-item-profile {
  border: solid 5px #d9c025; }

.legend-item-sc {
  border: solid 5px #9fc643; }

.legend-item-skill {
  border: solid 5px #00acbe; }

.legend-item-domain {
  border: solid 5px #cb2a7b; }

.table-pagination ul.pagination {
  margin: 10px 0; }
  .table-pagination ul.pagination .page-item.active.disabled .page-link {
    background: #a9a9a9;
    color: #ffffff; }

.block-loader {
  width: 100%;
  height: 100%;
  z-index: 999999;
  background: rgba(202, 201, 201, 0.137);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.nodes-graph {
  height: 100%;
  width: 100%;
  position: relative; }
  .nodes-graph .cytoscape-component {
    height: 100%;
    width: 100%; }
  .nodes-graph .graph-overlay {
    width: 100%;
    height: 100%;
    z-index: 100;
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;
    border-radius: 5px; }
    .nodes-graph .graph-overlay.graph-loader {
      align-items: center;
      background: #f8f8f8;
      padding: 0; }

.system-node-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0; }
  .system-node-details .current-node-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    width: 100%; }
    .system-node-details .current-node-header .current-node-name {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      min-width: 0; }
      .system-node-details .current-node-header .current-node-name h5 {
        margin-bottom: 0;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: normal; }
      .system-node-details .current-node-header .current-node-name .back-to-list-button {
        white-space: nowrap; }
  .system-node-details .current-node-details-content {
    border: 1px solid #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 15px;
    overflow-y: auto;
    margin-bottom: 10px;
    height: 100%; }
  .system-node-details .current-node-controls {
    display: flex;
    justify-content: space-between;
    width: 100%; }
  .system-node-details .description-textarea {
    height: 110px;
    resize: none; }
  .system-node-details .system-node-source-row .system-node-source {
    margin-top: 9px;
    font-size: 14px; }

.synonyms-items-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-bottom: 25px; }
  .synonyms-items-container .synonyms-item {
    display: flex;
    align-items: center;
    min-height: 32px;
    max-width: 100%;
    padding: 2px 6px 2px 12px;
    margin: 3px; }
    .synonyms-items-container .synonyms-item .synonyms-item-delete-icon {
      margin-left: 3px; }
  .synonyms-items-container .synonyms-item-content-editable {
    max-width: calc(100% - 16px);
    outline: 0;
    min-width: 10px; }
    .synonyms-items-container .synonyms-item-content-editable:focus {
      box-shadow: 0 0 0 1px rgba(212, 212, 212, 0.5); }
  .synonyms-items-container .synonyms-item-add-icon {
    border: 2px solid #89aa11;
    border-radius: 20px;
    background: #f7f7f7;
    margin: 3px; }

.synonyms-items-lang-label {
  width: 20%; }

.related-nodes {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 0; }
  .related-nodes .table-pagination .pagination {
    margin-top: 0;
    justify-content: center; }
  .related-nodes .related-nodes-table-container {
    width: 100%;
    min-height: 315px;
    height: 315px;
    max-height: 315px;
    overflow-y: auto;
    border: 1px solid #a9a9a9;
    border-radius: 5px;
    position: relative;
    padding: 10px 5px;
    margin-bottom: 10px; }
    .related-nodes .related-nodes-table-container.removed-nodes, .related-nodes .related-nodes-table-container.added-nodes {
      min-height: initial;
      height: initial;
      max-height: 200px; }
    .related-nodes .related-nodes-table-container .table {
      margin-bottom: 0;
      table-layout: fixed; }
      .related-nodes .related-nodes-table-container .table .related-node-type-cell {
        width: 58px; }
        .related-nodes .related-nodes-table-container .table .related-node-type-cell .node-source {
          margin: 0; }
      .related-nodes .related-nodes-table-container .table .related-node-name-cell {
        width: 100%;
        padding-top: 8px;
        padding-bottom: 8px; }
        .related-nodes .related-nodes-table-container .table .related-node-name-cell .related-node-name-cell-content .node-parent-cluster,
        .related-nodes .related-nodes-table-container .table .related-node-name-cell .related-node-name-cell-content .node-domain {
          color: #808080;
          font-size: 12px;
          white-space: nowrap;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis; }
        .related-nodes .related-nodes-table-container .table .related-node-name-cell .related-node-name-cell-content .node-parent-cluster {
          margin-bottom: 5px; }
        .related-nodes .related-nodes-table-container .table .related-node-name-cell .related-node-name-cell-content .node-name {
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: flex-start; }
        .related-nodes .related-nodes-table-container .table .related-node-name-cell .related-node-name-cell-content .node-domain-badge,
        .related-nodes .related-nodes-table-container .table .related-node-name-cell .related-node-name-cell-content .node-parent-cluster-badge {
          font-weight: normal;
          font-size: 12px;
          display: inline; }
        .related-nodes .related-nodes-table-container .table .related-node-name-cell .related-node-name-cell-content .node-domain-badge {
          background: #e7ffee; }
        .related-nodes .related-nodes-table-container .table .related-node-name-cell .related-node-name-cell-content .node-parent-cluster-badge {
          background: #e7f4ff; }
      .related-nodes .related-nodes-table-container .table .system-node-source-cell {
        width: 100px; }
      .related-nodes .related-nodes-table-container .table .related-node-action-cell {
        width: 48px; }
      .related-nodes .related-nodes-table-container .table .related-node-row.removed-node {
        background: #ffdada; }
      .related-nodes .related-nodes-table-container .table .related-node-row.added-node {
        background: #ddffda; }
  .related-nodes .nodes-filters .filter-label {
    font-weight: normal; }

@media (min-width: 720px) {
  .add-relation-with-node-modal {
    max-width: 98vh; } }

.add-relation-with-node-modal .modal-body {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 240px);
  overflow-y: auto; }

.add-relation-with-node-modal .table-pagination .pagination {
  justify-content: center; }

.add-relation-with-node-modal .nodes-to-add-table-container {
  width: 100%;
  min-height: 315px;
  height: 315px;
  max-height: 315px;
  overflow-y: auto;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  position: relative;
  padding: 10px 5px; }
  .add-relation-with-node-modal .nodes-to-add-table-container.added-nodes {
    min-height: initial;
    height: initial;
    max-height: 200px; }
  .add-relation-with-node-modal .nodes-to-add-table-container .table {
    margin-bottom: 0;
    table-layout: fixed; }
    .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-type-cell {
      width: 58px; }
      .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-type-cell .node-source {
        margin: 0; }
    .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-name-cell {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px; }
      .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-name-cell .node-to-add-name-cell-content .node-parent-cluster,
      .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-name-cell .node-to-add-name-cell-content .node-domain {
        color: #808080;
        font-size: 12px;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-name-cell .node-to-add-name-cell-content .node-parent-cluster {
        margin-bottom: 5px; }
      .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-name-cell .node-to-add-name-cell-content .node-name {
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
      .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-name-cell .node-to-add-name-cell-content .node-domain-badge,
      .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-name-cell .node-to-add-name-cell-content .node-parent-cluster-badge {
        font-weight: normal;
        font-size: 12px;
        display: inline; }
      .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-name-cell .node-to-add-name-cell-content .node-domain-badge {
        background: #e7ffee; }
      .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-name-cell .node-to-add-name-cell-content .node-parent-cluster-badge {
        background: #e7f4ff; }
    .add-relation-with-node-modal .nodes-to-add-table-container .table .system-node-source-cell {
      width: 100px; }
    .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-action-cell {
      width: 48px; }
      .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-action-cell--disabled {
        background-color: grey; }
    .add-relation-with-node-modal .nodes-to-add-table-container .table .node-to-add-row.added-node {
      background: #ddffda; }

.nodes-filters {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content; }
  .nodes-filters .filters-group {
    display: flex; }
    .nodes-filters .filters-group.search-group {
      padding-top: 5px;
      padding-bottom: 5px; }
  .nodes-filters .filters-row {
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px; }
  .nodes-filters .filter-label {
    margin-right: 10px; }
  .nodes-filters .filter-select {
    width: 100%; }
  .nodes-filters .select-node-status-option {
    display: flex;
    align-items: center; }
    .nodes-filters .select-node-status-option .system-node-status {
      margin: 0;
      margin-right: 5px; }
  .nodes-filters .input-select-node-status-multi-value .system-node-status {
    margin: 3px; }
  .nodes-filters .nodes-sorting-mode-select .select-single-value {
    max-width: calc(100% - 35px);
    margin-left: 25px; }

.system-node-source.admin-source {
  background: #41a7ff; }

.system-node-source.user-source {
  background: #12da7d; }

@media (min-width: 576px) {
  .change-parent-cluster-modal {
    max-width: 900px; } }

.change-parent-cluster-modal .modal-body {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 240px);
  overflow-y: auto; }

.change-parent-cluster-modal .table-pagination .pagination {
  justify-content: center; }

.change-parent-cluster-modal .clusters-list-table-container {
  width: 100%;
  min-height: 315px;
  height: 315px;
  max-height: 315px;
  overflow-y: auto;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  position: relative;
  padding: 10px 5px; }
  .change-parent-cluster-modal .clusters-list-table-container .table {
    margin-bottom: 0;
    table-layout: fixed; }
    .change-parent-cluster-modal .clusters-list-table-container .table .clusters-list-type-cell {
      width: 58px; }
      .change-parent-cluster-modal .clusters-list-table-container .table .clusters-list-type-cell .node-source {
        margin: 0; }
    .change-parent-cluster-modal .clusters-list-table-container .table .clusters-list-name-cell {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px; }
      .change-parent-cluster-modal .clusters-list-table-container .table .clusters-list-name-cell .clusters-list-name-cell-content .node-domain {
        color: #808080;
        font-size: 12px;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis; }
      .change-parent-cluster-modal .clusters-list-table-container .table .clusters-list-name-cell .clusters-list-name-cell-content .node-name {
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
      .change-parent-cluster-modal .clusters-list-table-container .table .clusters-list-name-cell .clusters-list-name-cell-content .node-domain-badge {
        font-weight: normal;
        font-size: 12px;
        display: inline;
        background: #e7ffee; }
    .change-parent-cluster-modal .clusters-list-table-container .table .system-node-source-cell {
      width: 100px; }
    .change-parent-cluster-modal .clusters-list-table-container .table .clusters-list-action-cell {
      width: 48px; }
    .change-parent-cluster-modal .clusters-list-table-container .table .clusters-list-row.current-parent {
      background: #dae9ff; }
    .change-parent-cluster-modal .clusters-list-table-container .table .clusters-list-row.new-parent {
      background: #ddffda; }

.change-parent-cluster-modal .reset-selected-parent {
  display: inline;
  margin-left: 5px; }

.select-account-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px; }
  .select-account-type .select-account-type-form {
    width: 90vw;
    max-width: 800px;
    margin: 15px 10px;
    padding: 30px;
    box-shadow: 0 2px 2px #0000004d;
    border: 1px solid #e7e7e7;
    background: #f7f7f7;
    border-radius: 5px; }
    .select-account-type .select-account-type-form h4 {
      margin-bottom: 20px; }
  .select-account-type .back-link-container {
    width: 90vw;
    max-width: 800px;
    display: flex; }
  .select-account-type .card-button {
    margin-top: auto; }

.back-link {
  display: flex;
  align-items: center; }
  .back-link .icon-container {
    padding-right: 2px; }
  .back-link:hover .icon-container svg, .back-link:focus .icon-container svg {
    fill: #0056b3; }

.applicant-registration {
  max-width: 700px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px; }
  .applicant-registration .back-link-container {
    width: 700px;
    max-width: 700px;
    display: flex; }
  .applicant-registration .applicant-registration-header {
    margin-bottom: 20px; }
  .applicant-registration .applicant-registration-hint {
    margin-bottom: 15px; }
  .applicant-registration .success-registration-message {
    margin-top: 20px; }

.modal-dialog.license-agreement-modal {
  max-width: 800px; }
  .modal-dialog.license-agreement-modal .modal-header .modal-title {
    margin-bottom: 0; }
  .modal-dialog.license-agreement-modal .modal-body {
    height: 60vh;
    max-height: 700px;
    min-height: 300px;
    overflow-y: auto;
    padding: 25px;
    padding-top: 10px; }

.company-registration {
  max-width: 700px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px; }
  .company-registration .back-link-container {
    width: 700px;
    max-width: 700px;
    display: flex; }
  .company-registration .company-registration-header {
    margin-bottom: 20px; }
  .company-registration .success-registration-message {
    margin-top: 20px; }
  .company-registration form {
    margin-bottom: 40px; }

.irrelevant-account-activation-link-message-container {
  display: flex;
  justify-content: center;
  margin-top: 40px; }

.applicant-account-activation {
  max-width: 700px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px; }
  .applicant-account-activation .applicant-account-activation-header {
    margin-bottom: 20px; }
  .applicant-account-activation .applicant-account-activation-hint {
    margin-bottom: 15px; }

.employee-account-activation {
  max-width: 700px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px; }
  .employee-account-activation .employee-account-activation-header {
    margin-bottom: 20px; }
  .employee-account-activation .employee-account-activation-hint {
    margin-bottom: 15px; }

.restore-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px; }
  .restore-account .restore-account-form {
    width: 90vw;
    max-width: 400px;
    margin: 15px 10px;
    padding: 30px;
    box-shadow: 0 2px 2px #0000004d;
    border: 1px solid #e7e7e7;
    background: #f7f7f7;
    border-radius: 5px; }
    .restore-account .restore-account-form h5 {
      margin-bottom: 20px; }
  .restore-account .back-link-container {
    width: 90vw;
    max-width: 400px;
    display: flex; }
  .restore-account .help-message {
    width: 90vw;
    max-width: 400px;
    padding: 0 5px; }

.reset-password {
  max-width: 700px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px; }
  .reset-password h4 {
    margin-bottom: 20px; }

.support-page {
  max-width: 700px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 30px; }
  .support-page .back-link-container {
    width: 700px;
    max-width: 700px;
    display: flex; }
  .support-page h4 {
    margin-bottom: 20px; }

.resend-activation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px; }
  .resend-activation .resend-activation-form {
    width: 90vw;
    max-width: 400px;
    margin: 15px 10px;
    padding: 30px;
    box-shadow: 0 2px 2px #0000004d;
    border: 1px solid #e7e7e7;
    background: #f7f7f7;
    border-radius: 5px; }
    .resend-activation .resend-activation-form h5 {
      margin-bottom: 20px; }
  .resend-activation .back-link-container {
    width: 90vw;
    max-width: 400px;
    display: flex; }

.applicant-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }
  .applicant-account .account-header-container {
    width: 100%;
    position: relative; }
    .applicant-account .account-header-container .save-changes-button {
      position: absolute;
      top: 10px;
      right: 10px; }
  .applicant-account__rich-media-title {
    color: #a9a9a9;
    text-align: center; }
  .applicant-account__rich-media-subtitle {
    white-space: break-spaces; }
  .applicant-account .nav .nav-item {
    max-width: 400px; }
  .applicant-account .nav .nav-link {
    cursor: pointer;
    color: #212529; }
    .applicant-account .nav .nav-link.active {
      font-weight: bold;
      color: #89aa11; }
  .applicant-account .nav-tabs {
    width: 100%;
    justify-content: center; }
  .applicant-account .applicant-account-content-divider {
    width: 100%;
    margin-top: 20px;
    height: 10px;
    box-shadow: 0px 4px 6px -3px #c2c5cdbf;
    z-index: 1; }
  .applicant-account .applicant-account-content {
    height: 100%;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    overflow-y: auto; }
    .applicant-account .applicant-account-content .tab-pane {
      width: 100%;
      max-width: 700px; }
      .applicant-account .applicant-account-content .tab-pane .tab-content-container {
        margin-bottom: 30px; }
        .applicant-account .applicant-account-content .tab-pane .tab-content-container .olive {
          color: #89aa11; }
  .applicant-account .select-in-text {
    display: inline-block;
    width: 200px;
    margin: 0 5px;
    max-width: 100%; }
    .applicant-account .select-in-text.food {
      width: 375px; }
    .applicant-account .select-in-text.currency {
      width: 50px; }
  .applicant-account .input-in-text {
    display: inline-block;
    width: 100px;
    margin: 0 5px; }
  .applicant-account .work-regime-labels {
    display: flex;
    justify-content: space-between; }
  .applicant-account .work-regime-input {
    padding: 0; }
  .applicant-account .dream-locations-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    column-gap: 10px; }
    .applicant-account .dream-locations-container .dream-location-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 15px;
      border: 2px solid #89aa11;
      border-radius: 10px;
      position: relative;
      margin-bottom: 10px;
      height: calc(100% - 10px); }
    .applicant-account .dream-locations-container .preference-badge {
      margin-bottom: 3px; }
      .applicant-account .dream-locations-container .preference-badge.commute {
        background: #c4ffb1; }
      .applicant-account .dream-locations-container .preference-badge.work-regime {
        background: #dbc5ff; }
      .applicant-account .dream-locations-container .preference-badge.start-period {
        background: #c5fffc; }
    .applicant-account .dream-locations-container .dream-location-remove-icon {
      position: absolute;
      right: 4px;
      top: 4px; }
  .applicant-account .picture-preview {
    background-size: cover;
    height: 100px;
    width: 100px;
    margin-top: 10px;
    margin-right: 10px;
    position: relative; }
  .applicant-account .other-pictures-container {
    display: flex;
    flex-wrap: wrap; }
  .applicant-account .preview-video-player {
    margin-top: 10px;
    width: 100%;
    max-width: 100%; }
  .applicant-account .preview-audio-player {
    margin-top: 10px;
    width: 100%;
    max-width: 100%; }
  .applicant-account .work-regime-row {
    margin-bottom: 1rem; }
    .applicant-account .work-regime-row > div {
      flex: 1 1;
      margin: 0 15px; }

.hf1-custom-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  margin: 0 20px;
  border-radius: 18px;
  border: 1px solid #343a40;
  background-color: #89aa11; }
  .hf1-custom-switch .hf1-custom-switch-item {
    cursor: pointer;
    text-align: center;
    z-index: 2;
    padding: 5px 15px;
    color: white;
    display: flex;
    align-items: center; }
  .hf1-custom-switch .hf1-custom-switch-indicator {
    height: 100%;
    width: 100px;
    background: #343a40;
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: left 0.5s ease, width 0.5s ease;
    box-shadow: #343a40 0px 1px 5px 1px; }

.radial-progress-bar-done-mark {
  margin-left: 5px;
  height: 20px;
  width: 20px;
  fill: #11ffe2; }

.radial-progress-bar {
  transform: rotate(-90deg);
  margin-left: 5px;
  height: 20px;
  width: 20px; }
  .radial-progress-bar circle {
    stroke-width: 3px;
    stroke-linecap: round;
    fill: transparent; }
  .radial-progress-bar .bar {
    stroke: #11ffe2; }
  .radial-progress-bar .warning-circle {
    stroke-width: 5px;
    stroke: #d64021; }

.media-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0; }
  .media-container h6 {
    text-align: center;
    color: #a9a9a9;
    font-style: italic;
    display: flex;
    align-self: center; }
    .media-container h6:before {
      content: "";
      display: inline-block;
      width: 50px;
      background-color: #75b444;
      height: 2px;
      align-self: center;
      margin: 0 0.5rem; }
    .media-container h6:after {
      content: "";
      display: inline-block;
      width: 50px;
      background-color: #75b444;
      height: 2px;
      align-self: center;
      margin: 0 0.5rem; }
  .media-container > .links-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .media-container > .links-container > .icon-container {
      margin: 0 0.5rem;
      cursor: pointer; }
      .media-container > .links-container > .icon-container > svg {
        width: 32px;
        height: 32px; }

.rich-media__title {
  font-weight: bold;
  font-size: 16px;
  align-items: center;
  align-content: center;
  display: flex;
  padding: 15px;
  width: 50%;
  font-style: italic;
  border-right: solid #75b444 1px; }

.rich-media__avatar-box {
  flex: 0 1 auto; }

.rich-media__org-box {
  flex: 1 1 auto; }

.rich-media__media-viewer {
  border: solid #75b444 2px;
  width: 80%; }

.rich-media__table-cell--top-border {
  border-top: solid black 1px; }

.rich-media__table-cell--bottom-border {
  border-bottom: solid black 1px; }

.rich-media__row {
  border-bottom: dashed black 1px; }
  .rich-media__row--no-border {
    border: none; }

.rich-media__container {
  border-left: none;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.rich-media__media-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 50%; }

.rich-media__media-container-content {
  width: 42px;
  height: 42px; }

.rich-media__selector-box {
  padding: 17px;
  border: solid #a9a9a9 2px;
  margin-bottom: 15px;
  align-items: flex-start;
  border-radius: 15%; }
  .rich-media__selector-box--active {
    border-color: #007bff;
    border-width: 4px;
    padding: 15px; }

.rich-media__tab-title {
  top: 6px;
  background: white;
  left: auto;
  width: 100px;
  right: auto;
  text-align: center;
  white-space: nowrap; }

.rich-media__media-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%; }

.rich-media__media-container {
  width: 150px;
  height: auto; }

.rich-media__selector-box-container {
  width: 20%; }

.staff-and-departments {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .staff-and-departments .content-divider {
    width: 80vw; }

.staff-list {
  width: 80vw; }
  .staff-list .table-pagination {
    display: flex;
    align-items: center;
    justify-content: center; }

.modal-content .modal-body .change-anchor-title {
  font-weight: 400; }

.modal-content .modal-body .employee-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  .modal-content .modal-body .employee-dropdown > label {
    margin: 0 15px 0 0; }
  .modal-content .modal-body .employee-dropdown > div {
    width: 100%; }

.departments-list {
  width: 80vw; }
  .departments-list .table-pagination {
    display: flex;
    align-items: center;
    justify-content: center; }

.page-container > .department-details {
  margin-top: 30px;
  width: 80vw; }
  .page-container > .department-details .department-info {
    margin-bottom: 20px; }
    .page-container > .department-details .department-info .edit-icon-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px; }
      .page-container > .department-details .department-info .edit-icon-wrapper h6 {
        margin: 0 10px 0 0; }
  .page-container > .department-details .buttons-container {
    padding: 0; }

.modal-content .modal-body .with-bottom-margin {
  margin-bottom: 10px; }

.modal-content .modal-body .dropdown-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; }
  .modal-content .modal-body .dropdown-wrapper > label {
    margin: 0 15px 0 0;
    width: 120px; }
  .modal-content .modal-body .dropdown-wrapper > div {
    width: 100%; }

.resize-element {
  display: flex;
  justify-content: center;
  height: 15px;
  cursor: ns-resize;
  position: absolute;
  left: 0;
  width: 100%;
  -webkit-user-select: none;
          user-select: none;
  z-index: 2; }

.job-timeline-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%; }
  .job-timeline-container > .multiple-lines-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: inherit;
    width: 100%;
    z-index: 2; }
    .job-timeline-container > .multiple-lines-container .arrow {
      border: solid #cccccc;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      position: absolute;
      top: -10px;
      left: 18px; }
  .job-timeline-container > .label {
    padding: 5px;
    white-space: nowrap;
    margin: 1rem 0; }

.description {
  height: 150px;
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  margin: 20px 0;
  padding: 10px 0; }
  .description > div {
    display: flex;
    justify-content: center;
    position: relative; }

.dots-controls-container {
  display: flex;
  margin-bottom: 0.5rem;
  align-self: center; }
  .dots-controls-container .job-dot {
    width: 10px;
    height: 10px;
    margin: 0 0.5rem;
    margin-right: 0.2rem;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid #40e0d0; }
    .dots-controls-container .job-dot.active {
      background-color: #40e0d0; }

.skill-journey-block-container {
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 10px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  background-color: #f9f9f9;
  justify-content: flex-start; }
  .skill-journey-block-container.left {
    flex-direction: column;
    width: 15%;
    background: #f9f9f9;
    max-height: 680px;
    min-height: 680px;
    position: relative; }
  .skill-journey-block-container.center {
    width: 70%;
    min-width: 300px; }
  .skill-journey-block-container.right {
    width: 15%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content; }
  .skill-journey-block-container h6,
  .skill-journey-block-container h5,
  .skill-journey-block-container h4 {
    text-align: center; }
  .skill-journey-block-container .inner-block-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    height: 100%; }
    .skill-journey-block-container .inner-block-container.scrolable-content {
      position: relative;
      justify-content: flex-start;
      overflow-y: scroll; }
  .skill-journey-block-container .icon-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer; }
    .skill-journey-block-container .icon-container#addPastJobButton {
      display: flex;
      align-self: center;
      margin: 1rem 0;
      z-index: 20; }
  .skill-journey-block-container .description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 5px 0; }
    .skill-journey-block-container .description-container > h6 {
      align-self: center;
      margin-top: 1rem; }
  .skill-journey-block-container .skills-map-component-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
    padding: 10px;
    margin: 5px;
    box-shadow: 0 0 0 1px #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px; }
    .skill-journey-block-container .skills-map-component-container.hidden {
      visibility: hidden; }
    .skill-journey-block-container .skills-map-component-container h5 {
      text-align: center; }
  .skill-journey-block-container .skill-timeline-container {
    height: 400px;
    max-height: 400px;
    min-height: 400px;
    position: relative; }
    .skill-journey-block-container .skill-timeline-container .icon-container {
      position: absolute;
      top: 10px;
      right: 10px; }
    .skill-journey-block-container .skill-timeline-container > .inner-block-container {
      align-items: flex-start;
      justify-content: flex-start; }
  .skill-journey-block-container .slider-mark.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0; }
  .skill-journey-block-container .slider-mark > .label {
    width: -webkit-max-content;
    width: max-content;
    margin: 0 0.5rem; }

.handler-dark-block {
  position: absolute;
  background-color: #40dfcf;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  filter: blur(10px) opacity(30%); }

.slider-tooltip {
  position: absolute;
  top: 0;
  left: 85%;
  transform: translate(-50%, -100%);
  z-index: 9999;
  font-size: 10px;
  line-height: 1.5;
  opacity: 0.9;
  -webkit-user-select: none;
          user-select: none;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 150ms; }
  .slider-tooltip.active {
    visibility: visible;
    opacity: 1;
    top: -10px; }
    .slider-tooltip.active.skill-timeline-tooltip {
      top: 25px;
      left: -25px; }
      .slider-tooltip.active.skill-timeline-tooltip span:before {
        content: none; }
      .slider-tooltip.active.skill-timeline-tooltip span:after {
        content: none; }
  .slider-tooltip span {
    display: block;
    text-align: center;
    color: #000000;
    text-decoration: none;
    position: relative;
    font-weight: bold;
    -webkit-user-select: none;
            user-select: none;
    padding: 3px 5px; }
    .slider-tooltip span:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 5px 5px 0;
      border-top-color: #373737;
      left: 50%;
      bottom: -25px;
      transform: rotate(180deg) translate(50%, 80%); }
    .slider-tooltip span:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 5px 5px 0;
      border-top-color: #373737;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 100%); }

.carousel {
  position: unset !important; }

.applicant-quick-find {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
  padding: 0 20px; }
  .applicant-quick-find .section-header {
    border-bottom: 2px solid #89aa11;
    color: #89aa11;
    padding-bottom: 5px; }
  .applicant-quick-find .filtered-candidates-wrapper {
    display: flex;
    width: 100%;
    border: solid 1px #a9a9a9;
    border-radius: 5px;
    height: 100%;
    max-height: 520px;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 20px;
    overflow: hidden;
    overflow-y: scroll; }
  .applicant-quick-find .candidate-pull {
    max-width: 1000px;
    width: 100%;
    margin-top: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px; }
  .applicant-quick-find .balance-icon-description-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 20px; }
    .applicant-quick-find .balance-icon-description-wrapper > .icon-container {
      margin: 0 10px; }
  .applicant-quick-find .no-applicants-message {
    padding: 15px 0;
    margin: 0; }

.candidate-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  max-width: 190px;
  width: 100%;
  position: relative; }
  .candidate-wrapper .compare-icon-wrapper {
    cursor: pointer; }
  .candidate-wrapper .candidate-skills-wrapper > .skill {
    display: inline-block;
    box-sizing: border-box;
    padding: 6px 8px;
    border: solid 2px #a9a9a9;
    border-radius: 30px;
    background-color: white;
    font-size: 10px;
    max-width: 70px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .candidate-wrapper .candidate-info {
    text-align: center;
    width: 100%;
    margin: 0; }
  .candidate-wrapper .candidate-job {
    margin: 10px 0 0 0; }
  .candidate-wrapper .compare-icon {
    position: absolute;
    top: 15px;
    right: 15px; }

.center {
  text-align: center; }

.search-button-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: flex-end;
  margin-top: 20px; }
  .search-button-wrapper .search-button {
    align-self: center; }

.compare-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px; }

.quickfind-row {
  margin: 0;
  flex: 1 1; }

.flex {
  flex: 1 1; }

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px; }

.regime-input-wrapper {
  flex: 1 1;
  align-items: baseline; }
  .regime-input-wrapper .info-icon {
    margin-left: 20px;
    cursor: pointer; }

.input {
  min-height: 38px;
  font-size: 1rem; }

.with-top-margin {
  margin-top: 25px; }

.rows-wrapper {
  padding: 10px 0 10px 0;
  flex: 1 1; }

.city-input-wrapper {
  flex-grow: 1; }

.work-regime-input {
  padding: 0; }

.form-check-inline {
  margin: 0; }
  .form-check-inline > label {
    white-space: pre;
    margin: 0 20px 0 0; }
  .form-check-inline .form-label {
    margin: 0 20px; }
  .form-check-inline > input {
    width: 100%;
    max-width: 250px;
    min-width: 100px; }

.work-regime-labels {
  display: flex;
  justify-content: space-between; }

.preferred-contract-type-wrapper {
  margin: 0 20px; }

.salary-input-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: space-between; }
  .salary-input-wrapper .salary-input {
    flex: 0.45 1; }
  .salary-input-wrapper .hf1-custom-select {
    flex: 0.45 1; }

.work-regime-input-wrapper {
  width: 100%; }

.work-regime-label {
  display: flex;
  justify-content: center; }

.autocomplete-wrapper {
  display: flex;
  align-items: center;
  flex: 1 1; }

.distance-unit-select-wrapper {
  margin: 0 0 0 20px;
  padding: 0;
  min-width: 105px; }

.candidate-cross-job-info-modal .candidate-modal-header .modal-title {
  display: flex;
  align-items: center; }

.candidate-cross-job-info-modal .candidate-modal-header .user-photo {
  margin-left: 5px;
  margin-right: 10px; }

.candidate-cross-job-info-modal .candidate-modal-header .candidate-buttons .favorite-icon svg {
  fill: #ffa500; }

.candidate-cross-job-info-modal .modal-body {
  padding: 0;
  display: flex; }

.candidate-cross-job-info-modal .modes-buttons {
  border-right: 1px solid #dee2e6; }
  .candidate-cross-job-info-modal .modes-buttons .mode-button {
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #dee2e6; }
    .candidate-cross-job-info-modal .modes-buttons .mode-button:last-child {
      border-bottom: none;
      box-shadow: 0px 1px 0px 0px #dee2e6; }
    .candidate-cross-job-info-modal .modes-buttons .mode-button:hover:not(.active) {
      background-color: #f1f1f1; }
    .candidate-cross-job-info-modal .modes-buttons .mode-button.active {
      background-color: #f1f1f1; }

.candidate-cross-job-info-modal .modes-content {
  width: 100%; }
  .candidate-cross-job-info-modal .modes-content .mode-content {
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }

.candidate-cross-job-info-modal .video-player {
  width: 100%; }

.modal-content-wrapper {
  display: flex;
  flex-direction: row; }
  .modal-content-wrapper .buttons-pane {
    display: flex;
    flex-direction: column; }
    .modal-content-wrapper .buttons-pane.candidate-pane {
      flex-direction: column; }
      .modal-content-wrapper .buttons-pane.candidate-pane .pane-button {
        flex-direction: column; }
        .modal-content-wrapper .buttons-pane.candidate-pane .pane-button:first-child {
          border-top-left-radius: 0.3rem;
          border-top-right-radius: 0.3rem; }
        .modal-content-wrapper .buttons-pane.candidate-pane .pane-button:last-child {
          border-bottom-left-radius: 0.3rem;
          border-bottom-right-radius: 0.3rem; }
        .modal-content-wrapper .buttons-pane.candidate-pane .pane-button:not(:last-child) {
          border-bottom: 0; }
    .modal-content-wrapper .buttons-pane .pane-button {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      border: 1px solid #a9a9a9;
      color: #212529;
      cursor: pointer; }
      .modal-content-wrapper .buttons-pane .pane-button:hover:not(.active) {
        background: #e8e8e8; }
      .modal-content-wrapper .buttons-pane .pane-button.active {
        background: #ffffff; }
      .modal-content-wrapper .buttons-pane .pane-button .pane-button-label {
        font-size: 12px; }
      .modal-content-wrapper .buttons-pane .pane-button.ready-for-drop {
        z-index: 10000; }
      .modal-content-wrapper .buttons-pane .pane-button.pane-title {
        cursor: default;
        padding: 0 3px;
        font-weight: 600;
        font-size: 12px; }
        .modal-content-wrapper .buttons-pane .pane-button.pane-title:hover {
          background: #f9f9f9; }
      .modal-content-wrapper .buttons-pane .pane-button.with-link {
        padding: 0; }
    .modal-content-wrapper .buttons-pane .pane-button-link {
      flex-direction: column;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: #212529; }
    .modal-content-wrapper .buttons-pane .with-bottom-margin {
      margin-bottom: 10px; }

.hard-skills-compare {
  display: flex;
  flex-direction: column;
  flex: 1 1; }
  .hard-skills-compare .skills-map-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    margin-right: 10px;
    box-shadow: 0 0 0 1px #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px; }

.applicants-comparison {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }
  .applicants-comparison .applicants-comparison-content-container {
    display: flex;
    width: 100%;
    margin-bottom: 15px; }
    .applicants-comparison .applicants-comparison-content-container .content-column {
      max-height: 100%; }
      .applicants-comparison .applicants-comparison-content-container .content-column.candidates-stack-column {
        padding: 0 5px 5px 5px;
        min-width: 200px;
        max-width: 260px; }
      .applicants-comparison .applicants-comparison-content-container .content-column.details-column {
        padding: 0 5px 5px 0;
        max-width: calc(100% - 260px); }
    .applicants-comparison .applicants-comparison-content-container .details-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #a9a9a9;
      background-color: #f9f9f9;
      border-radius: 5px;
      height: 100%;
      max-height: 100%;
      position: relative;
      padding: 65px 110px 10px 110px; }
      .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison {
        max-width: 100%;
        overflow-x: auto;
        height: 100%;
        display: flex;
        flex-direction: column; }
        .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info {
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: 250px;
          margin-bottom: 10px;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          z-index: 2; }
          .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col {
            display: flex;
            align-items: center;
            border: 1px solid #a9a9a9; }
            .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col:not(:last-child) {
              border-right: 0; }
            .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col.job-requirements {
              justify-content: center;
              background: #efefef; }
              .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col.job-requirements + .applicant-info {
                border-left: 0; }
            .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col.applicant-info {
              flex-direction: column;
              padding: 8px;
              background-color: #ffffff;
              position: relative; }
              .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col.applicant-info .applicant-controls {
                display: flex;
                margin-bottom: 5px; }
                .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col.applicant-info .applicant-controls .buttons-group {
                  display: flex;
                  align-items: center; }
                .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col.applicant-info .applicant-controls .icon-container {
                  margin: 0 7px; }
              .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col.applicant-info .user-photo {
                margin: 0 5px; }
              .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col.applicant-info .applicant-name {
                font-size: 14px;
                text-align: center;
                font-weight: 600;
                line-height: 1.2;
                width: 100%;
                padding: 0 25px;
                margin-bottom: 5px; }
              .applicants-comparison .applicants-comparison-content-container .details-wrapper .dashboard-comparison .applicants-info .applicants-info-col.applicant-info .remove-applicant-from-comparison {
                position: absolute;
                top: 0;
                right: 0; }
    .applicants-comparison .applicants-comparison-content-container .applicants-stack-wrapper {
      display: flex;
      flex-direction: column;
      padding: 10px;
      border: 1px solid #a9a9a9;
      background-color: #f9f9f9;
      border-radius: 5px;
      height: 100%;
      max-height: 100%; }

.applicants-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%; }
  .applicants-stack .sorting-menu {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    .applicants-stack .sorting-menu .sorting-menu-buttons {
      display: flex;
      margin-left: 5px; }
      .applicants-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button {
        border: 1px solid #a9a9a9;
        cursor: pointer; }
        .applicants-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button:hover:not(.active) {
          background: #e8e8e8; }
        .applicants-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button.active {
          background: #ffffff; }
        .applicants-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px; }
        .applicants-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px; }
        .applicants-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button:not(:last-child) {
          border-right: 0; }
        .applicants-stack .sorting-menu .sorting-menu-buttons .sorting-menu-button .icon-container {
          padding: 5px; }
  .applicants-stack .hs-ss-ratio-container {
    display: flex;
    margin-bottom: 10px; }
    .applicants-stack .hs-ss-ratio-container .hs-ss-ratio-input {
      margin: 0 5px; }
  .applicants-stack .applicants-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: 200px;
    background: #ffffff;
    border: 1px solid #a9a9a9;
    border-radius: 5px;
    padding: 10px; }
    .applicants-stack .applicants-list .applicant-card {
      margin: 5px; }
      .applicants-stack .applicants-list .applicant-card .applicant-photo-container {
        padding: 5px 25px;
        position: relative;
        display: flex; }
        .applicants-stack .applicants-list .applicant-card .applicant-photo-container .remove-from-stack {
          position: absolute;
          top: 0;
          right: 0; }
      .applicants-stack .applicants-list .applicant-card .applicant-name {
        text-align: center; }
        .applicants-stack .applicants-list .applicant-card .applicant-name.selected {
          font-weight: bold; }

.hard-skills-header {
  margin-bottom: 5px; }
  .hard-skills-header .hard-skills-controls {
    display: flex;
    align-items: center;
    justify-content: center; }
    .hard-skills-header .hard-skills-controls .controls-title {
      font-size: 14px;
      font-weight: 600; }
    .hard-skills-header .hard-skills-controls .control-button {
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      margin-left: 10px; }
      .hard-skills-header .hard-skills-controls .control-button .icon-container {
        margin-right: 3px; }

.hard-skills {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .hard-skills .hard-skills-row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 250px;
    position: relative; }
    .hard-skills .hard-skills-row .cluster-name {
      position: absolute;
      z-index: 1;
      color: white;
      background-color: #a9a9a9;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 0 5px;
      top: 0;
      font-size: 12px;
      left: 50%;
      transform: translate(-50%, 0);
      height: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 40px); }
    .hard-skills .hard-skills-row .cluster {
      border: 1px solid #a9a9a9;
      padding: 17px 5px; }
      .hard-skills .hard-skills-row .cluster:not(:last-child) {
        border-right: 0; }
      .hard-skills .hard-skills-row .cluster.job-cluster {
        background: #efefef; }
        .hard-skills .hard-skills-row .cluster.job-cluster + .candidate-cluster {
          border-left: 0; }
      .hard-skills .hard-skills-row .cluster.candidate-cluster {
        background-color: #ffffff;
        position: relative;
        z-index: 0; }
    .hard-skills .hard-skills-row:not(:last-child) .job-cluster {
      border-bottom: 0; }
    .hard-skills .hard-skills-row:not(:last-child) .candidate-cluster {
      border-bottom: 0; }
    .hard-skills .hard-skills-row:nth-child(2n) .candidate-cluster {
      background-color: #efefef; }
    .hard-skills .hard-skills-row:nth-child(2n) .job-cluster {
      background-color: #e0e0e0; }
  .hard-skills .skill {
    max-width: calc(100% - 10px);
    background: #ffffff; }
    .hard-skills .skill .notes-icon {
      cursor: pointer; }

.job-quick-find {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
  padding: 0 20px; }
  .job-quick-find .job-pull {
    max-width: 1000px;
    width: 100%;
    margin-top: 30px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px; }
  .job-quick-find .balance-icon-description-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-top: 20px; }
    .job-quick-find .balance-icon-description-wrapper > .icon-container {
      margin: 0 10px; }
  .job-quick-find .section-header {
    border-bottom: 2px solid #89aa11;
    color: #89aa11;
    padding-bottom: 5px; }
  .job-quick-find .section-subheader {
    margin-top: 20px; }
  .job-quick-find .jobs-wrapper {
    display: flex;
    width: 100%;
    border: solid 1px #a9a9a9;
    border-radius: 5px;
    height: 100%;
    max-height: 520px;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 20px;
    overflow: hidden;
    overflow-y: scroll; }
  .job-quick-find .no-jobs-message {
    padding: 15px 0;
    margin: 0; }

.job-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  max-width: 190px;
  width: 100%;
  position: relative; }
  .job-wrapper .selected path {
    stroke: #458afc;
    fill: #458afc; }
  .job-wrapper .job-icon-wrapper {
    width: 90px;
    height: 90px;
    min-width: 90px;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .job-wrapper .job-icon-wrapper > div, .job-wrapper .job-icon-wrapper svg {
      width: 80px;
      height: 80px; }
  .job-wrapper .compare-icon-wrapper {
    cursor: pointer; }
  .job-wrapper .compare-icon {
    position: absolute;
    top: 15px;
    right: 15px; }
  .job-wrapper .job-info {
    text-align: center;
    width: 100%;
    white-space: pre;
    margin: 5px 0 0 0; }
  .job-wrapper .job-title {
    margin: 0 0 10px 0; }
  .job-wrapper .job-skills-wrapper > .skill {
    display: inline-block;
    box-sizing: border-box;
    padding: 6px 8px;
    border: solid 2px #a9a9a9;
    border-radius: 30px;
    background-color: white;
    font-size: 10px;
    max-width: 70px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.jobs-comparison {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }
  .jobs-comparison .jobs-comparison-content-container {
    display: flex;
    width: 100%;
    margin-bottom: 15px; }
    .jobs-comparison .jobs-comparison-content-container .content-column {
      max-height: 100%; }
      .jobs-comparison .jobs-comparison-content-container .content-column.jobs-stack-column {
        padding: 0 5px 5px 5px;
        min-width: 200px;
        max-width: 260px; }
        .jobs-comparison .jobs-comparison-content-container .content-column.jobs-stack-column .jobs-stack-wrapper {
          display: flex;
          flex-direction: column;
          padding: 10px;
          border: 1px solid #a9a9a9;
          background-color: #f9f9f9;
          border-radius: 5px;
          height: 100%;
          max-height: 100%; }
      .jobs-comparison .jobs-comparison-content-container .content-column.details-column {
        padding: 0 5px 5px 0;
        max-width: calc(100% - 260px); }
    .jobs-comparison .jobs-comparison-content-container .details-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #a9a9a9;
      background-color: #f9f9f9;
      border-radius: 5px;
      height: 100%;
      max-height: 100%;
      position: relative;
      padding: 65px 110px 10px 110px; }
      .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison {
        max-width: 100%;
        overflow-x: auto;
        height: 100%;
        display: flex;
        flex-direction: column; }
        .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info {
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: 250px;
          margin-bottom: 10px;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          z-index: 2; }
          .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col {
            display: flex;
            align-items: center;
            border: 1px solid #a9a9a9; }
            .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col:not(:last-child) {
              border-right: 0; }
            .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col.applicant-requirements {
              justify-content: center;
              background: #efefef; }
              .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col.applicant-requirements + .job-info {
                border-left: 0; }
            .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col.job-info {
              flex-direction: column;
              padding: 8px;
              background-color: #ffffff;
              position: relative; }
              .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col.job-info .job-controls {
                display: flex; }
                .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col.job-info .job-controls .job-icon {
                  margin: 0 10px; }
                .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col.job-info .job-controls .buttons-group {
                  display: flex;
                  align-items: center; }
                  .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col.job-info .job-controls .buttons-group > div {
                    margin-right: 10px; }
                  .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col.job-info .job-controls .buttons-group .favorite-icon svg {
                    fill: #ffa500; }
              .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col.job-info .job-title {
                font-size: 14px;
                text-align: center;
                font-weight: 600;
                line-height: 1.2;
                width: 100%;
                padding: 0 25px;
                margin: 5px 0; }
              .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .jobs-info .jobs-info-col.job-info .remove-applicant-from-comparison {
                position: absolute;
                top: 0;
                right: 0; }
        .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .apply-all {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          margin-top: 20px; }
        .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .apply-buttons-wrapper {
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: 250px; }
          .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .apply-buttons-wrapper .apply-buttons-label {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #a9a9a9;
            border-right: 0;
            background: #efefef; }
            .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .apply-buttons-wrapper .apply-buttons-label h6 {
              margin: 0; }
            .jobs-comparison .jobs-comparison-content-container .details-wrapper .dashboard-comparison .apply-buttons-wrapper .apply-buttons-label + .button-wrapper {
              border-left: 0; }
  .jobs-comparison .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #a9a9a9;
    background-color: #ffffff;
    padding: 10px; }
    .jobs-comparison .button-wrapper:not(:last-child) {
      border-right: 0; }

.apply-media__dropzone {
  width: 100%;
  height: 50px;
  border: dashed black 1px;
  display: flex;
  align-items: center;
  justify-content: center; }

.jobs-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%; }
  .jobs-stack .jobs-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: 200px;
    background: #ffffff;
    border: 1px solid #a9a9a9;
    border-radius: 5px;
    padding: 10px; }
    .jobs-stack .jobs-list .job-card {
      margin: 5px; }
      .jobs-stack .jobs-list .job-card .job-icon-container {
        padding: 5px 25px;
        position: relative;
        display: flex; }
        .jobs-stack .jobs-list .job-card .job-icon-container .selected path {
          stroke: #458afc;
          fill: #458afc; }
        .jobs-stack .jobs-list .job-card .job-icon-container .job-icon-wrapper {
          width: 90px;
          height: 90px;
          min-width: 90px;
          min-height: 90px;
          display: flex;
          align-items: center;
          justify-content: center; }
          .jobs-stack .jobs-list .job-card .job-icon-container .job-icon-wrapper > div, .jobs-stack .jobs-list .job-card .job-icon-container .job-icon-wrapper svg {
            width: 80px;
            height: 80px; }
        .jobs-stack .jobs-list .job-card .job-icon-container .favorite-icon {
          position: absolute;
          top: 0;
          left: 0; }
          .jobs-stack .jobs-list .job-card .job-icon-container .favorite-icon svg {
            width: 21px;
            height: 21px;
            fill: #ffa500; }
        .jobs-stack .jobs-list .job-card .job-icon-container .remove-from-stack {
          position: absolute;
          top: 0;
          right: 0; }
      .jobs-stack .jobs-list .job-card .job-title {
        text-align: center; }
        .jobs-stack .jobs-list .job-card .job-title.selected {
          font-weight: bold; }

.audio-modal .audio-player {
  width: 100%; }

.video-modal .video-player {
  width: 100%; }

.job-compass {
  display: flex;
  align-items: center;
  flex-direction: column; }
  .job-compass .jc-content-container {
    display: flex;
    height: 100%;
    min-height: 0;
    width: 100%;
    justify-content: center; }
    .job-compass .jc-content-container .left-col,
    .job-compass .jc-content-container .right-col {
      width: 20%;
      max-width: 20%; }
      @media (min-width: 1500px) {
        .job-compass .jc-content-container .left-col,
        .job-compass .jc-content-container .right-col {
          max-width: 400px; } }
    .job-compass .jc-content-container .left-col > button {
      width: 100%;
      margin-bottom: 10px; }
    .job-compass .jc-content-container .right-col {
      display: flex;
      flex-direction: column; }
    .job-compass .jc-content-container .job-compass-col {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      max-width: 60%; }
  .job-compass .jc-menu {
    border: 2px solid #ced4da;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px; }
    .job-compass .jc-menu .jc-menu-divider {
      height: 2px;
      width: calc(100% + 20px);
      margin: 10px -10px;
      background-color: #ced4da; }
    .job-compass .jc-menu.alternative-settings {
      border-color: #33dfff; }
      .job-compass .jc-menu.alternative-settings .jc-menu-divider {
        background-color: #33dfff; }
    .job-compass .jc-menu.possible-future-jobs-menu {
      border-color: #ffd700;
      max-height: 100%;
      min-height: 0; }
    .job-compass .jc-menu.common-settings {
      border: none;
      margin: 0;
      padding: 0; }
      .job-compass .jc-menu.common-settings .jc-menu-divider {
        background-color: #33dfff; }
  .job-compass .domains-select .domains-items-container {
    display: flex;
    flex-wrap: wrap; }
  .job-compass .domains-select .domain-item {
    border: 2px solid #33dfff;
    background: #ffffff;
    border-radius: 15px;
    margin: 4px;
    padding: 2px 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    font-size: 14px; }
    .job-compass .domains-select .domain-item.selected {
      background: #33dfff; }
  .job-compass .alternative-now-matching .alt-job-matching-input {
    padding: 0;
    box-shadow: none; }
  .job-compass .alternative-now-matching .alt-job-matching-value {
    text-align: center; }
  .job-compass .alternative-now-black-list-info {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .job-compass .alternative-now-black-list-info h6 {
      margin-bottom: 2px; }
    .job-compass .alternative-now-black-list-info .icon-container {
      margin-left: 10px; }
  .job-compass .possible-future-jobs-menu {
    height: 100%;
    display: flex;
    flex-direction: column; }
    .job-compass .possible-future-jobs-menu .search-future-profiles-input-group {
      margin-bottom: 10px; }
    .job-compass .possible-future-jobs-menu .possible-future-jobs-list {
      height: 100%;
      border: 1px solid #ced4da;
      background: #ffffff;
      border-radius: 5px;
      margin-bottom: 20px;
      overflow-y: auto;
      display: flex;
      flex-direction: column; }
      .job-compass .possible-future-jobs-menu .possible-future-jobs-list .search-profile-items-container .search-profile-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px; }
      .job-compass .possible-future-jobs-menu .possible-future-jobs-list .search-profile-items-container .profile-icons {
        display: flex; }
        .job-compass .possible-future-jobs-menu .possible-future-jobs-list .search-profile-items-container .profile-icons .icon-container {
          margin-left: 5px; }
      .job-compass .possible-future-jobs-menu .possible-future-jobs-list .search-profile-items-container .profile-data-container {
        display: flex;
        flex-direction: column; }
      .job-compass .possible-future-jobs-menu .possible-future-jobs-list .search-profile-items-container .profile-badges-container {
        display: flex;
        flex-wrap: wrap; }
      .job-compass .possible-future-jobs-menu .possible-future-jobs-list .search-profile-items-container .jobs-number-badge,
      .job-compass .possible-future-jobs-menu .possible-future-jobs-list .search-profile-items-container .skills-number-badge {
        font-weight: normal;
        font-size: 12px;
        display: inline;
        margin-right: 10px;
        margin-bottom: 5px; }
      .job-compass .possible-future-jobs-menu .possible-future-jobs-list .search-profile-items-container .jobs-number-badge {
        background: #fff3af; }
      .job-compass .possible-future-jobs-menu .possible-future-jobs-list .search-profile-items-container .skills-number-badge {
        background: #d4e7c4; }
    .job-compass .possible-future-jobs-menu .legend-container {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .job-compass .possible-future-jobs-menu .legend-container .jc-skill {
        max-width: -webkit-max-content;
        max-width: max-content;
        margin-bottom: 5px;
        max-width: 100%; }
  .job-compass .share-data-switch-container,
  .job-compass .find-jobs-by-preferences-switch-container {
    display: flex;
    justify-content: center; }
    .job-compass .share-data-switch-container .custom-control-label,
    .job-compass .find-jobs-by-preferences-switch-container .custom-control-label {
      margin-left: 10px; }
  .job-compass .job-compass-component {
    width: 850px;
    max-height: 100%;
    max-width: 100%; }
    .job-compass .job-compass-component .jc-background {
      fill: #fdfdfd; }
    .job-compass .job-compass-component .jc-border {
      fill: #bdc2ce; }
    .job-compass .job-compass-component .jc-border-shadow {
      fill: #969ca8; }
    .job-compass .job-compass-component .jc-border-inside-shadow {
      fill: #f5f5f5; }
    .job-compass .job-compass-component .jc-inside-circle {
      fill: #e0e1e4; }
    .job-compass .job-compass-component .jc-small-arrows .jc-arrows-dark-sides,
    .job-compass .job-compass-component .jc-big-arrows .jc-arrows-dark-sides {
      fill: #bdc2ce; }
    .job-compass .job-compass-component .jc-small-arrows .jc-arrows-light-sides,
    .job-compass .job-compass-component .jc-big-arrows .jc-arrows-light-sides {
      fill: #f0f2f7; }
    .job-compass .job-compass-component .jc-needle {
      transform-origin: calc(50% - 30px) calc(50% - 5px);
      transition: all 0.5s ease-in-out; }
      .job-compass .job-compass-component .jc-needle .jc-needle-north-side {
        fill: #bde91f; }
      .job-compass .job-compass-component .jc-needle .jc-needle-south-side {
        fill: #e0e0e0; }
      .job-compass .job-compass-component .jc-needle .jc-needle-shadow {
        opacity: 0.2; }
      .job-compass .job-compass-component .jc-needle .jc-needle-point {
        fill: #ffffff; }
  .job-compass .jc-profile-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: fixed; }
  .job-compass .jc-profile {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 40px;
    max-height: 40px;
    background: #ffffff;
    border: 3px solid #a07f5d;
    border-radius: 50px;
    padding: 8px 16px;
    text-align: center;
    min-width: 100px;
    max-width: 180px;
    margin: 8px; }
    .job-compass .jc-profile .jc-profile-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: bold;
      max-width: 127px;
      width: 100%; }
    .job-compass .jc-profile .jc-profile-jobs-total {
      position: absolute;
      top: -10px;
      right: -10px;
      background: #219c0b;
      color: white;
      padding: 2px 7px;
      font-size: 12px;
      border-radius: 12px;
      font-weight: bold;
      min-width: 25px; }
    .job-compass .jc-profile .jc-profile-remove-icon {
      position: absolute;
      bottom: -6px;
      right: -8px; }
    .job-compass .jc-profile .jc-profile-compare-icon {
      position: absolute;
      bottom: -6px;
      left: -8px; }
    .job-compass .jc-profile.current {
      border-color: #75b444;
      background: #75b444;
      color: white; }
      .job-compass .jc-profile.current .jc-profile-jobs-total {
        background: #517c2f; }
    .job-compass .jc-profile.alternative {
      border-color: #33dfff;
      background: #33dfff; }
      .job-compass .jc-profile.alternative .jc-profile-jobs-total {
        background: #00c2e6; }
    .job-compass .jc-profile.future {
      border-color: #ffd700;
      background: #ffd700; }
      .job-compass .jc-profile.future .jc-profile-jobs-total {
        background: #b39700; }
  .job-compass .jc-skill-wrapper {
    display: flex;
    align-items: center;
    justify-content: center; }
  .job-compass .jc-skill {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 32px;
    max-height: 32px;
    background: #ffffff;
    border: 3px solid #75b444;
    border-radius: 5px;
    padding: 2px 5px;
    text-align: center;
    min-width: 50px;
    max-width: 120px; }
    .job-compass .jc-skill.broadening {
      border-style: dashed; }
    .job-compass .jc-skill .jc-skill-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
  .job-compass .jc-connector {
    stroke: #75b444; }
  .job-compass .user-photo {
    border-color: #75b444; }

.profile-jobs-popover .popover-body {
  padding: 5px; }

.profile-jobs-popover .popover-inner {
  min-width: 250px;
  max-width: 400px; }

.profile-jobs-popover .jobs-items-container {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ced4dd;
  border-radius: 5px; }
  .profile-jobs-popover .jobs-items-container .list-group-item {
    display: flex;
    padding: 6px 3px;
    align-items: center;
    justify-content: space-between; }
    .profile-jobs-popover .jobs-items-container .list-group-item .item-content-container {
      display: flex;
      align-items: center; }
    .profile-jobs-popover .jobs-items-container .list-group-item .text-container {
      padding: 0 5px; }
    .profile-jobs-popover .jobs-items-container .list-group-item .icon-container {
      margin-right: 5px; }
    .profile-jobs-popover .jobs-items-container .list-group-item h6 {
      margin-bottom: 5px; }

.jc-skills-legend-popover .popover-body {
  white-space: pre-wrap; }

.alternative-now-black-list-modal .modal-body {
  padding: 0;
  max-height: calc(100vh - 300px);
  overflow-y: auto; }
  .alternative-now-black-list-modal .modal-body .no-profiles-label {
    margin: 10px 16px; }

.alternative-now-black-list-modal .hidden-profiles-container .hidden-profile-item {
  display: flex;
  justify-content: space-between; }

.requests-status-alert {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 3px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000000;
  max-width: 90vw; }

.request-loading-overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #adadad42; }

.post-job-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; }
  .post-job-container .account-header-container {
    width: 100%;
    position: relative; }
  .post-job-container .tabs-container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center; }
    .post-job-container .tabs-container .tab-pane {
      width: 100%;
      max-width: 700px; }
      .post-job-container .tabs-container .tab-pane .tab-content-container {
        margin-bottom: 30px; }
  .post-job-container .field-error {
    color: #ffc107; }
  .post-job-container .picture-preview {
    width: 50%;
    min-height: 100px; }

.hf1-custom-select .required-field > div {
  border-color: #75b444;
  border-radius: 2px; }

.post-job-page-wrapper {
  display: flex;
  flex-direction: column; }
  .post-job-page-wrapper > .page-subsection-header {
    margin-bottom: 1rem; }
  .post-job-page-wrapper .bold-text {
    font-weight: bold; }
  .post-job-page-wrapper .problem-description-fields-wrapper {
    display: flex;
    flex-direction: column; }
  .post-job-page-wrapper .post-buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 15px; }
  .post-job-page-wrapper .delimiter {
    width: 100%;
    height: 2px;
    background-color: #75b444;
    margin: 1rem 0; }
  .post-job-page-wrapper .spinner {
    align-self: center;
    color: #75b444; }

h6 {
  white-space: break-spaces; }

.post-job-page-wrapper {
  display: flex;
  flex-direction: column; }
  .post-job-page-wrapper > .page-subsection-header {
    margin-bottom: 1rem; }
  .post-job-page-wrapper .bold-text {
    font-weight: bold; }
  .post-job-page-wrapper .problem-description-fields-wrapper {
    display: flex;
    flex-direction: column; }
  .post-job-page-wrapper .post-buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 15px; }
  .post-job-page-wrapper .category-subsection-title {
    border-bottom: 2px solid #000000;
    margin: 1rem 0;
    font-weight: bold; }
  .post-job-page-wrapper input.with-button.right {
    border-radius: 0.25rem 0 0 0.25rem; }
  .post-job-page-wrapper .description-text {
    margin: 1rem 0; }
  .post-job-page-wrapper .salary-container {
    display: flex;
    flex-direction: row; }
    .post-job-page-wrapper .salary-container > .max-salary-container {
      width: 70%; }
      .post-job-page-wrapper .salary-container > .max-salary-container > h6 {
        display: none; }
    .post-job-page-wrapper .salary-container > .salary-currency-container {
      width: 30%;
      margin: 0 1rem; }
      .post-job-page-wrapper .salary-container > .salary-currency-container > h6 {
        display: none; }
  .post-job-page-wrapper .attach-file-container {
    margin: 1rem 0; }
  .post-job-page-wrapper .salary-contract-type-container {
    display: flex; }
    .post-job-page-wrapper .salary-contract-type-container > .salary {
      width: 50%; }
    .post-job-page-wrapper .salary-contract-type-container > .contract-type {
      width: 50%; }
  .post-job-page-wrapper .inputs-container {
    display: flex;
    flex-wrap: wrap; }
    .post-job-page-wrapper .inputs-container.end-aligned {
      align-items: flex-end; }
    .post-job-page-wrapper .inputs-container .location-field-container,
    .post-job-page-wrapper .inputs-container .job-description-field-container {
      width: 40%;
      margin-right: 1rem; }
    .post-job-page-wrapper .inputs-container .checkbox-container {
      width: 30%;
      margin-right: 1rem; }
  .post-job-page-wrapper .range-container,
  .post-job-page-wrapper .select-container {
    width: 50%; }
  .post-job-page-wrapper .date-container,
  .post-job-page-wrapper .number-container {
    width: 40%;
    margin-right: 1rem; }
  .post-job-page-wrapper .work-regime-container {
    width: 100%; }
  .post-job-page-wrapper .additional_descripton_container {
    margin: 1rem 0; }
  .post-job-page-wrapper .location-field-container > input.required,
  .post-job-page-wrapper .date-container > input.required,
  .post-job-page-wrapper .range-container > input.required,
  .post-job-page-wrapper .select-container > input.required,
  .post-job-page-wrapper .number-container > input.required,
  .post-job-page-wrapper .max-salary-container > input.required,
  .post-job-page-wrapper .job-description-field-container > input.required {
    border-color: #75b444;
    border-radius: 2px; }
  .post-job-page-wrapper .submit-buttons-container {
    display: flex;
    justify-content: center; }
    .post-job-page-wrapper .submit-buttons-container > button {
      margin: 1rem; }

.filtered-list-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin: 1rem 0; }
  .filtered-list-container .list-container {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    overflow: auto;
    max-height: 8rem;
    list-style: none;
    padding: 1rem 0;
    margin: 0; }
    .filtered-list-container .list-container.required {
      border-color: #75b444;
      border-radius: 2px; }
    .filtered-list-container .list-container > li {
      -webkit-user-select: none;
              user-select: none;
      cursor: pointer;
      padding: 0 1rem; }
      .filtered-list-container .list-container > li:hover {
        background-color: #75b444;
        color: #ffffff; }
      .filtered-list-container .list-container > li.active {
        background-color: #75b444;
        color: #ffffff; }
    .filtered-list-container .list-container > .fallback {
      padding: 0 1rem; }
  .filtered-list-container > .filter-container {
    width: 40%;
    margin-left: 1rem; }
    .filtered-list-container > .filter-container > button {
      margin: 1rem 0; }
  .filtered-list-container > .select-area {
    display: flex;
    flex-direction: column;
    width: 60%; }

.job-rich-media-container {
  display: flex;
  flex-direction: row;
  margin: 2rem 0;
  height: 200px; }
  .job-rich-media-container .navigation-container {
    display: flex;
    flex-direction: column;
    width: 15%;
    height: 100%; }
    .job-rich-media-container .navigation-container > .navigation-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      -webkit-user-select: none;
              user-select: none;
      height: 50%;
      border: 1px solid #a9a9a9; }
      .job-rich-media-container .navigation-container > .navigation-item:first-child {
        border-bottom: none; }
      .job-rich-media-container .navigation-container > .navigation-item.active {
        background-color: #a9a9a9; }
  .job-rich-media-container .tab-content,
  .job-rich-media-container .tab-pane {
    height: 100%; }
  .job-rich-media-container .dropzone-container {
    overflow: auto;
    margin: 0 1rem;
    height: 100%;
    border: 1px solid #a9a9a9;
    border-radius: 0; }
    .job-rich-media-container .dropzone-container.required {
      border-color: #75b444;
      border-radius: 2px; }
    .job-rich-media-container .dropzone-container label {
      text-align: center; }

.post-job-page-wrapper {
  display: flex;
  flex-direction: column; }
  .post-job-page-wrapper > .page-subsection-header {
    margin: 1rem 0; }

.skill-button {
  width: 40%;
  align-self: center;
  margin: 1rem 0; }

.fields-wrapper {
  margin: 1rem 0; }

.add-job-skills-modal-container {
  width: 90%;
  max-width: none; }

.applicant-jobs-container {
  display: flex;
  flex-direction: column;
  margin: 0 1rem; }
  .applicant-jobs-container > div {
    display: flex;
    flex-direction: row; }
  .applicant-jobs-container__title {
    margin: 2rem 0;
    text-align: center;
    color: #89aa11;
    font-size: 30px;
    font-weight: bold;
    white-space: pre-line; }
  .applicant-jobs-container .inner-block-container {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    margin: 5px;
    height: 100%;
    position: relative; }
  .applicant-jobs-container .delimiter {
    width: 80%;
    height: 2px;
    background-color: #75b444;
    align-self: center;
    margin: 1rem 0; }

.section.application-overview, .section.rich-media, .section.job-details {
  width: 50%; }
  .section.application-overview > .block, .section.rich-media > .block, .section.job-details > .block {
    justify-content: center; }

.section.skills-map {
  width: 50%; }
  .section.skills-map > .block {
    justify-content: space-between; }
    .section.skills-map > .block > .skills-map {
      height: 60%; }

.section.rich-media > .block {
  justify-content: center; }

.section > .block {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 5px;
  padding: 10px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  background-color: #f9f9f9;
  justify-content: flex-start; }
  .section > .block > h6 {
    text-align: center;
    margin: 1rem 0;
    color: #75b444;
    position: absolute;
    top: -25px;
    background: linear-gradient(180deg, white 50%, #f9f9f9 50%);
    padding: 0 1rem; }
  .section > .block .form-group {
    margin: 2rem 0; }

.main-container {
  display: flex;
  flex-direction: row;
  width: 75%; }

.pending-application-container {
  position: relative;
  min-height: 400px;
  width: 100%;
  margin: 2rem; }
  .pending-application-container .deadline-line-container {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    width: 100%;
    position: absolute;
    bottom: 0; }
    .pending-application-container .deadline-line-container > .deadline-line {
      width: 100%;
      height: 2px;
      background-color: black; }
  .pending-application-container > .balls-container {
    display: flex;
    flex-wrap: wrap; }
    .pending-application-container > .balls-container.before-deadline {
      position: relative; }

.feedback-container {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.7rem;
  margin: 2rem 0; }
  .feedback-container > .icon-container {
    margin: 0 0.5rem;
    cursor: pointer; }

.job-ball-container {
  display: flex;
  width: 33%;
  position: relative;
  z-index: 1; }
  .job-ball-container.disabled {
    width: 100%; }
    .job-ball-container.disabled > svg {
      cursor: unset; }
  .job-ball-container > svg {
    cursor: pointer; }
  .job-ball-container .job-circle {
    fill: white;
    stroke: #ffc107;
    stroke-width: 3px; }
    .job-ball-container .job-circle.possible-future {
      fill: white;
      stroke: #75b444;
      stroke-width: 6px; }
    .job-ball-container .job-circle.active {
      fill: #975fa496; }
  .job-ball-container > .icon-container,
  .job-ball-container .company-logo {
    position: absolute; }
    .job-ball-container > .icon-container.red-notification-icon,
    .job-ball-container .company-logo.red-notification-icon {
      top: 35px;
      left: 15px; }
    .job-ball-container > .icon-container.add-to-favorite-jobs,
    .job-ball-container .company-logo.add-to-favorite-jobs {
      top: 22px;
      left: -8px;
      transform: scale(0.8); }
  .job-ball-container .remove-from-view-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 26px;
    left: 37px; }
  .job-ball-container > .company-logo {
    width: 32px;
    height: 32px;
    top: -20px;
    left: 9px; }
  .job-ball-container > .icons-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 38px; }
    .job-ball-container > .icons-container > .icon-container {
      position: absolute;
      width: 18px;
      height: 18px;
      cursor: pointer; }

.legend-container {
  width: 100%;
  position: relative; }
  .legend-container > h6 {
    position: absolute;
    top: -20px;
    background-color: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 0.5rem; }
  .legend-container > .legend-row {
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    margin: 0.5rem 0; }
    .legend-container > .legend-row > .icon-container {
      width: 50px; }
    .legend-container > .legend-row > .text {
      font-size: 0.7rem;
      margin: 0 1rem; }
    .legend-container > .legend-row .possible-future {
      fill: transparent;
      stroke: #75b444;
      stroke-width: 6px; }

.applied-jobs-title {
  position: absolute;
  top: -10px;
  background-color: white;
  background: linear-gradient(180deg, #f9f9f9 50%, white 50%);
  padding: 0 1rem; }

.feedback-info-container {
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
  padding: 2rem 1rem 1rem 1rem;
  height: 150px; }
  .feedback-info-container .feedback-inner {
    display: flex;
    align-items: center; }
    .feedback-info-container .feedback-inner > .meta-info {
      display: flex;
      flex-direction: column;
      border-right: 1px solid black;
      padding-right: 1rem;
      margin-right: 1rem;
      width: 40%; }
    .feedback-info-container .feedback-inner > .feedback-info {
      width: 50%; }
    .feedback-info-container .feedback-inner > button {
      height: 30%; }
  .feedback-info-container > .carousel {
    width: 100%; }

.feedback-controls {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid black;
  border-radius: 50px;
  background-color: #f9f9f9;
  position: absolute;
  height: 50px;
  top: -25px;
  left: 25%; }
  .feedback-controls > .icon-container {
    transform: scale(2);
    margin: 0 1rem; }
    .feedback-controls > .icon-container > svg {
      width: unset;
      height: unset; }
  .feedback-controls > .dots-controls-container {
    margin: 0; }

.job-details-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 1rem;
  word-wrap: break-word;
  width: 75%;
  margin-bottom: 50px; }
  .job-details-block > .sub-section {
    width: 100%; }
    .job-details-block > .sub-section:nth-child(odd) {
      border-right: 1px solid #a9a9a9;
      padding: 0 1rem; }
    .job-details-block > .sub-section:nth-child(even) {
      border-left: 1px solid #a9a9a9;
      padding-left: 1rem; }
  .job-details-block.core > h6, .job-details-block.dates > h6, .job-details-block.status > h6, .job-details-block.rich-media > h6 {
    color: #a9a9a9;
    font-style: italic;
    display: flex;
    align-self: center; }
    .job-details-block.core > h6:before, .job-details-block.dates > h6:before, .job-details-block.status > h6:before, .job-details-block.rich-media > h6:before {
      content: "";
      display: inline-block;
      width: 50px;
      background-color: #75b444;
      height: 2px;
      align-self: center;
      margin: 0 0.5rem; }
    .job-details-block.core > h6:after, .job-details-block.dates > h6:after, .job-details-block.status > h6:after, .job-details-block.rich-media > h6:after {
      content: "";
      display: inline-block;
      width: 50px;
      background-color: #75b444;
      height: 2px;
      align-self: center;
      margin: 0 0.5rem; }
  .job-details-block > div {
    margin: 0.5rem 0; }
  .job-details-block .sub-title {
    color: #a9a9a9;
    font-style: italic; }

.rich-media {
  min-width: 800px; }
  .rich-media .modal-title {
    width: 100%; }
  .rich-media__content {
    width: 20%;
    height: auto; }

.jobs-lifecycle-line-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 2rem 1rem;
  padding: 2rem;
  padding-top: 0;
  background-color: #eff0ee;
  border-radius: 10px;
  border: 1px solid #dadada; }
  .jobs-lifecycle-line-container > div {
    display: flex;
    width: 100%;
    justify-content: space-between; }
  .jobs-lifecycle-line-container > h5 {
    margin: 1rem 0; }

.jobs-milestone.card {
  display: flex;
  border-radius: 10px;
  border: 2px solid #8aaa10;
  padding: 0.5rem;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer; }
  .jobs-milestone.card.main {
    color: #8aaa10;
    font-weight: bold; }
  .jobs-milestone.card:hover {
    transform: scale(1.05); }

.jobs-milestone.divider {
  width: 3rem;
  height: 2px;
  background: linear-gradient(90deg, #e5e64d 18%, #8aaa10 70%); }

.jobs-lifecycle-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%; }
  .jobs-lifecycle-line > .arrow-container {
    display: flex;
    align-items: center; }
    .jobs-lifecycle-line > .arrow-container > .arrow {
      border: 1px solid #8aaa10;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      margin-left: -7px; }
      .jobs-lifecycle-line > .arrow-container > .arrow.right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg); }

.clickable {
  cursor: pointer; }
  .clickable:hover {
    background-color: #e0e0e0; }

.jobs-table__title-link {
  cursor: pointer;
  color: #89aa11; }

.jobs-filter-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 2rem 0; }
  .jobs-filter-container > form {
    width: 50%; }
    .jobs-filter-container > form > .row {
      justify-content: space-between; }

.jobs-filters-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; }

.jobs-filter-button {
  width: 100%;
  margin: 1rem 0; }

.invitations-management {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }

.table-header-row-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap; }

.lock-person-info-icon-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row; }
  .lock-person-info-icon-wrapper > div {
    margin-left: 10px; }

.table-compare-icon {
  margin-left: 10px; }

.quick-compare-wrapper {
  display: flex;
  align-items: center; }

.full-compare-icon-wrapper {
  vertical-align: middle !important; }
  .full-compare-icon-wrapper > div {
    display: flex;
    justify-content: center;
    align-items: center; }
    .full-compare-icon-wrapper > div > svg {
      width: 40px;
      height: 40px; }

.contact-text {
  white-space: pre;
  text-align: center; }

.full-compare {
  width: 180px; }

.custom-select-wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }
  .custom-select-wrapper > div {
    width: 105px; }

.date-wrapper {
  border-radius: 0.25rem;
  background-color: #b7cfe0;
  padding: 6px 12px;
  width: -webkit-max-content;
  width: max-content;
  position: relative; }
  .date-wrapper > h6 {
    padding: 0;
    margin: 0; }
  .date-wrapper .remove-date {
    position: absolute;
    top: -10px;
    right: -10px; }

.plus-outline-icon {
  margin-top: 15px; }

.date-input-wrapper {
  display: flex;
  border: none;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.table-row {
  border-top: 1px solid #dee2e6; }
  .table-row > td {
    border: none !important; }

.table-row-item {
  display: flex;
  align-items: center;
  justify-content: center; }

.table-row-text-area-item {
  display: flex;
  align-items: center;
  justify-content: center; }
  .table-row-text-area-item > textarea {
    max-width: 350px;
    width: 100%; }

.lifecycle-line-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 2rem 1rem;
  padding: 2rem;
  padding-top: 0;
  background-color: #eff0ee;
  border-radius: 10px;
  border: 1px solid #dadada; }
  .lifecycle-line-container > div {
    display: flex;
    width: 100%;
    justify-content: space-between; }
    .lifecycle-line-container > div > button {
      width: 110px; }
  .lifecycle-line-container > h5 {
    margin: 1rem 0;
    align-self: center; }

.milestone.card {
  display: flex;
  border-radius: 10px;
  border: 2px solid #8aaa10;
  padding: 0.5rem;
  -webkit-user-select: none;
          user-select: none; }

.milestone.divider {
  width: 3rem;
  height: 2px;
  background: linear-gradient(90deg, #e5e64d 18%, #8aaa10 70%); }

.lifecycle-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-right: 30px; }
  .lifecycle-line > .lifecycle-line-data-wrapper {
    display: flex;
    align-items: center; }
    .lifecycle-line > .lifecycle-line-data-wrapper > .arrow-container {
      display: flex;
      align-items: center; }
      .lifecycle-line > .lifecycle-line-data-wrapper > .arrow-container > .arrow {
        border: 1px solid #8aaa10;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin-left: -7px; }
        .lifecycle-line > .lifecycle-line-data-wrapper > .arrow-container > .arrow.right {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg); }

.compare-modal {
  max-width: 1000px; }
  .compare-modal .modal-header > h5 {
    width: 100%; }
  .compare-modal .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .compare-modal .modal-body > .legend {
      width: 100%; }
    .compare-modal .modal-body .applicants-info {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 250px;
      margin-bottom: 10px;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2; }
      .compare-modal .modal-body .applicants-info .applicants-info-col {
        display: flex;
        align-items: center;
        border: 1px solid #a9a9a9; }
        .compare-modal .modal-body .applicants-info .applicants-info-col:not(:last-child) {
          border-right: 0; }
        .compare-modal .modal-body .applicants-info .applicants-info-col.job-requirements {
          justify-content: center;
          background: #efefef; }
          .compare-modal .modal-body .applicants-info .applicants-info-col.job-requirements + .applicant-info {
            border-left: 0; }
        .compare-modal .modal-body .applicants-info .applicants-info-col.applicant-info {
          flex-direction: column;
          padding: 8px;
          background-color: #ffffff;
          position: relative; }
          .compare-modal .modal-body .applicants-info .applicants-info-col.applicant-info .applicant-controls {
            display: flex;
            margin-bottom: 5px; }
            .compare-modal .modal-body .applicants-info .applicants-info-col.applicant-info .applicant-controls .buttons-group {
              display: flex;
              align-items: center; }
            .compare-modal .modal-body .applicants-info .applicants-info-col.applicant-info .applicant-controls .icon-container {
              margin: 0 7px; }
          .compare-modal .modal-body .applicants-info .applicants-info-col.applicant-info .user-photo {
            margin: 0 5px; }
          .compare-modal .modal-body .applicants-info .applicants-info-col.applicant-info .applicant-name {
            font-size: 14px;
            text-align: center;
            font-weight: 600;
            line-height: 1.2;
            width: 100%;
            padding: 0 25px;
            margin-bottom: 5px; }

.dropdown-expanded-content {
  height: 250px;
  overflow-y: scroll; }

.App {
  font-family: sans-serif; }

.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-top: 20px;
  margin-bottom: 20px; }

.accept {
  border-color: #107c10 !important; }

.reject {
  border-color: #d83b01 !important; }

.company-rich-media__media {
  width: 100px;
  height: 50px; }

.personal-settings__title {
  padding: 0.5rem 1rem;
  font-size: 16px; }

.personal-settings__tab {
  color: #000000;
  margin-top: 15px;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0 !important; }
  .personal-settings__tab:hover {
    color: #75b444;
    cursor: pointer;
    border-right: none; }
  .personal-settings__tab--active {
    color: #000000;
    border-left: solid #75b444 1px !important;
    border-top: solid #75b444 1px !important;
    border-bottom: solid #75b444 1px !important; }

.olive-green {
  color: #89aa11; }

.qr-code-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px; }

.parameters-container {
  margin-bottom: 20px; }

.parameters-title-container,
.links-title-container {
  display: flex; }

.parameters-title-container h6,
.links-title-container h6 {
  cursor: pointer; }

.parameters-title-icon,
.links-title-icon {
  display: flex;
  cursor: pointer; }

.links-container {
  display: flex;
  flex-direction: column; }

.two-fa-settings-label {
  margin-bottom: 20px; }

.two-fa-modal-success-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; }

.two-fa-modal-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px; }

.teams-list__title {
  width: 400px; }


@import "styles/constants";

$iconTopShift: -4px;
$iconBottomShift: $iconTopShift;
$iconLeftShift: -6px;
$iconRightShift: $iconLeftShift;

.skill {
    display: inline-block;
    min-height: $skillHeight;
    height: auto;
    border: 2px solid $skillNoLevelColor;
    border-radius: $skillHeight / 2;
    color: $skillTextColor;
    padding: 2px 12px;
    position: relative;
    margin: 5px;
    cursor: default;
    font-size: $skillFontSize;
    word-wrap: break-word;

    &.skill-text-overflow-ellipsis {
        .skill-text-container {
            // text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .icon-container {
        position: absolute;

        &.top-left {
            top: $iconTopShift;
            left: $iconLeftShift;
        }

        &.top-right {
            top: $iconTopShift;
            right: $iconRightShift;
        }

        &.bottom-right {
            bottom: $iconBottomShift;
            right: $iconRightShift;
        }

        &.bottom-left {
            bottom: $iconBottomShift;
            left: $iconLeftShift;
        }

        &.info-icon {
            width: 20px;
            height: 20px;
            right: -8px;

            > svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    &.skill-selected {
        color: $skillSelectedTextColor;

        &.cluster {
            color: $skillTextColor;
            background: $clusterSelectedBackgroundColor;
        }
    }

    &.skill-clickable {
        cursor: pointer;
    }

    &.skill-added,
    &.cluster-added {
        border-color: $skillIsAddedColor;
    }

    &.skill-not-added,
    &.cluster-not-added {
        border-color: $skillIsNotAddedColor;
    }

    @each $skillLevelType in $skillLevelTypes {
        &.#{$skillLevelType} {
            border-color: map-get($colorBySkillLevelType, $skillLevelType);

            &.skill-selected {
                background-color: map-get($colorBySkillLevelType, $skillLevelType);

                &.requirement-coefficient-crucial {
                    box-shadow: 0px
                        0px
                        0px
                        2px
                        map-get($borderColorBySkillLevelType, $skillLevelType);
                }
            }

            @each $skillRequirementCoefficientType in $skillRequirementCoefficientTypes {
                &.#{$skillRequirementCoefficientType}.skill-selected {
                    border-color: map-get($borderColorBySkillLevelType, $skillLevelType);
                }
            }

            &.requirement-coefficient-crucial {
                box-shadow: 0px 0px 0px 2px map-get($colorBySkillLevelType, $skillLevelType);
            }
        }

        &.#{$skillLevelType}-secondary {
            border-color: map-get($colorSecondaryBySkillLevelType, $skillLevelType);
        }
    }

    @each $skillStatus in $skillStatuses {
        &.#{$skillStatus} {
            border-color: map-get($colorBySkillStatus, $skillStatus);

            &.skill-selected {
                background-color: map-get($colorBySkillStatus, $skillStatus);
            }
        }
    }

    @each $skillComparisonLevelType in $skillComparisonLevelTypes {
        &.#{$skillComparisonLevelType} {
            border-color: map-get($colorBySkillComparisonLevelType, $skillComparisonLevelType);

            @each $skillRequirementCoefficientType in $skillRequirementCoefficientTypes {
                &.#{$skillRequirementCoefficientType} {
                    border-color: map-get(
                        $colorBySkillComparisonLevelType,
                        $skillComparisonLevelType
                    );
                }
            }

            &.actual-level-ninja {
                &:before {
                    content: " ";
                    position: absolute;
                    z-index: -1;
                    top: -6px;
                    left: -6px;
                    right: -6px;
                    bottom: -6px;
                    border: 4px solid $skillLevelNinjaColor;
                    border-radius: ($skillHeight + 8px) / 2;
                }
            }

            &.requirement-coefficient-crucial {
                box-shadow: 0px
                    0px
                    0px
                    2px
                    map-get($colorBySkillComparisonLevelType, $skillComparisonLevelType);
            }
        }
    }

    &.requirement-coefficient-nice-to-have {
        border-style: dashed;
    }
}

@import "styles/constants";
@import "../../../../node_modules/react-dropzone-uploader/dist/styles.css";

.job-rich-media-container {
    display: flex;
    flex-direction: row;
    margin: 2rem 0;
    height: 200px;

    .navigation-container {
        display: flex;
        flex-direction: column;
        width: 15%;
        height: 100%;

        > .navigation-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;
            height: 50%;
            border: 1px solid $mainLightGreyColor;

            &:first-child {
                border-bottom: none;
            }
            &.active {
                background-color: $mainLightGreyColor;
            }
        }
    }

    .tab-content,
    .tab-pane {
        height: 100%;
    }

    .dropzone-container {
        overflow: auto;
        margin: 0 1rem;
        height: 100%;
        border: 1px solid $mainLightGreyColor;
        border-radius: 0;
        &.required {
            border-color: $brainChainLimeColor;
            border-radius: 2px;
        }
        label {
            text-align: center;
        }
    }
}

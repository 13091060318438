.edit-applicant-skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .loading-save-changes-container {
        display: flex;
        align-items: center;
        margin: 0 10px;
    }

    .save-changes-container {
        position: absolute;
        top: 10px;
    }
}

@import "styles/constants";

.applicant-account {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .account-header-container {
        width: 100%;
        position: relative;

        .save-changes-button {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    &__rich-media-title {
        color: $mainLightGreyColor;
        text-align: center;
    }

    &__rich-media-subtitle {
        white-space: break-spaces;
    }

    .nav {
        .nav-item {
            max-width: 400px;
        }

        .nav-link {
            cursor: pointer;
            color: $defaultFontColor;

            &.active {
                font-weight: bold;
                color: $colorPrimary;
            }
        }
    }

    .nav-tabs {
        width: 100%;
        justify-content: center;
    }

    .applicant-account-content-divider {
        width: 100%;
        margin-top: 20px;
        height: 10px;
        box-shadow: 0px 4px 6px -3px #c2c5cdbf;
        z-index: 1;
    }

    .applicant-account-content {
        height: 100%;
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: center;
        overflow-y: auto;

        .tab-pane {
            width: 100%;
            max-width: $defaultFormWidth;

            .tab-content-container {
                margin-bottom: 30px;

                .olive {
                    color: $colorPrimary;
                }
            }
        }
    }

    .select-in-text {
        display: inline-block;
        width: 200px;
        margin: 0 5px;
        max-width: 100%;

        &.food {
            width: 375px;
        }

        &.currency {
            width: 50px;
        }
    }

    .input-in-text {
        display: inline-block;
        width: 100px;
        margin: 0 5px;
    }

    .work-regime-labels {
        display: flex;
        justify-content: space-between;
    }

    .work-regime-input {
        padding: 0;
    }

    .dream-locations-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;

        .dream-location-card {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 15px;
            border: 2px solid $colorPrimary;
            border-radius: 10px;
            position: relative;
            margin-bottom: 10px;
            height: calc(100% - 10px);
        }

        .preference-badge {
            margin-bottom: 3px;

            &.commute {
                background: #c4ffb1;
            }

            &.work-regime {
                background: #dbc5ff;
            }

            &.start-period {
                background: #c5fffc;
            }
        }

        .dream-location-remove-icon {
            position: absolute;
            right: 4px;
            top: 4px;
        }
    }

    .picture-preview {
        background-size: cover;
        height: 100px;
        width: 100px;
        margin-top: 10px;
        margin-right: 10px;
        position: relative;
    }

    .other-pictures-container {
        display: flex;
        flex-wrap: wrap;
    }

    .preview-video-player {
        margin-top: 10px;
        width: 100%;
        max-width: 100%;
    }

    .preview-audio-player {
        margin-top: 10px;
        width: 100%;
        max-width: 100%;
    }

    .work-regime-row {
        margin-bottom: 1rem;

        > div {
            flex: 1;
            margin: 0 15px;
        }
    }
}

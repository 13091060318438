@import "styles/constants";

h6 {
    white-space: break-spaces;
}
.post-job-page-wrapper {
    display: flex;
    flex-direction: column;

    > .page-subsection-header {
        margin-bottom: 1rem;
    }

    .bold-text {
        font-weight: bold;
    }

    .problem-description-fields-wrapper {
        display: flex;
        flex-direction: column;
    }

    .post-buttons-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .category-subsection-title {
        border-bottom: 2px solid #000000;
        margin: 1rem 0;
        font-weight: bold;
    }

    input {
        &.with-button {
            &.right {
                border-radius: 0.25rem 0 0 0.25rem;
            }
        }
    }

    .description-text {
        margin: 1rem 0;
    }

    .salary-container {
        display: flex;
        flex-direction: row;

        > .max-salary-container {
            width: 70%;
            > h6 {
                display: none;
            }
        }

        > .salary-currency-container {
            width: 30%;
            margin: 0 1rem;
            > h6 {
                display: none;
            }
        }
    }
    .attach-file-container {
        margin: 1rem 0;
    }
    .salary-contract-type-container {
        display: flex;
        > .salary {
            width: 50%;
        }
        > .contract-type {
            width: 50%;
        }
    }
    .inputs-container {
        display: flex;
        flex-wrap: wrap;

        &.end-aligned {
            align-items: flex-end;
        }
        .location-field-container,
        .job-description-field-container {
            width: 40%;
            margin-right: 1rem;
        }
        .checkbox-container {
            width: 30%;
            margin-right: 1rem;
        }
    }
    .range-container,
    .select-container {
        width: 50%;
    }
    .date-container,
    .number-container {
        width: 40%;
        margin-right: 1rem;
    }
    .work-regime-container {
        width: 100%;
    }

    .additional_descripton_container {
        margin: 1rem 0;
    }
    .location-field-container,
    .date-container,
    .range-container,
    .select-container,
    .number-container,
    .max-salary-container,
    .job-description-field-container {
        > input {
            &.required {
                border-color: $brainChainLimeColor;
                border-radius: 2px;
            }
        }
    }
    .submit-buttons-container {
        display: flex;
        justify-content: center;
        > button {
            margin: 1rem;
        }
    }
}

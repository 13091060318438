@import "styles/constants";

.support-page {
    max-width: $defaultFormWidth;
    margin-left: $defaultFormMargin;
    margin-right: $defaultFormMargin;
    margin-top: 30px;

    .back-link-container {
        width: $defaultFormWidth;
        max-width: $defaultFormWidth;
        display: flex;
    }

    h4 {
        margin-bottom: 20px;
    }
}

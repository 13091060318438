@import "styles/constants";

.post-job-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .account-header-container {
        width: 100%;
        position: relative;
    }

    .tabs-container {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: center;

        .tab-pane {
            width: 100%;
            max-width: $defaultFormWidth;

            .tab-content-container {
                margin-bottom: 30px;
            }
        }
    }

    .field-error {
        color: $colorWarning;
    }
    .picture-preview {
        width: 50%;
        min-height: 100px;
    }
}

.hf1-custom-select {
    .required-field {
        > div {
            border-color: $brainChainLimeColor;
            border-radius: 2px;
        }
    }
}

@import "styles/constants";

.skill-notes-popover {
    .popover-inner {
        width: 250px;
        max-height: 400px;
        overflow-y: auto;
    }

    .list-group-item {
        padding: 10px;
    }

    .note-date {
        color: $mainLightGreyColor;
        font-size: 12px;
    }
}

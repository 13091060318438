@import "styles/constants";

.post-job-page-wrapper {
    display: flex;
    flex-direction: column;

    > .page-subsection-header {
        margin-bottom: 1rem;
    }

    .bold-text {
        font-weight: bold;
    }

    .problem-description-fields-wrapper {
        display: flex;
        flex-direction: column;
    }

    .post-buttons-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 15px;
    }
    .delimiter {
        width: 100%;
        height: 2px;
        background-color: $brainChainLimeColor;
        margin: 1rem 0;
    }
    .spinner {
        align-self: center;
        color: $brainChainLimeColor;
    }
}

@import "styles/constants";

.header {
	padding: 0 0.5rem;
	height: 56px;
	min-height: 56px;
	max-height: 56px;

	.logo-container {
		padding: 0;

		.logo {
			height: 40px;
		}
	}

	.navbar-nav .header-link {
		color: $mainLightColor;
		opacity: 0.6;
		padding: 1rem;

		&.active {
			background-color: #41484e;
			opacity: 1;
		}

		&:hover {
			opacity: 1;
		}
	}

	.header-nav-dropdown {
		.dropdown-toggle.nav-link {
			color: $mainLightColor;
			padding: 1rem;
			opacity: 0.6;
			display: flex;
			align-items: center;

			&:hover {
				opacity: 1;
			}
		}

		&.active {
			.dropdown-toggle.nav-link {
				background-color: #41484e;
				opacity: 1;
			}
		}
	}

	.user-menu-dropdown {
		.nav-link {
			color: $mainLightColor;
			padding: 1rem;
			display: flex;
			align-items: center;
		}

		.user-email {
			max-width: 160px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.user-icon {
			margin-right: 5px;
		}
	}

	.header-toggler {
		margin-right: 0.5rem;
	}

	.header-collapse {
		background-color: $mainDarkColor;
		justify-content: center;
	}

	.language-select-container {
		margin-right: 10px;
		order: 1;
	}

	.mantra-container {
		position: absolute;
		left: 0;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		pointer-events: none;

		.mantra {
			height: 42px;
		}
	}

	@media (max-width: 767px) {
		.header-collapse {
			margin-top: 10px;
			z-index: 99999;
			box-shadow: 0 1px 7px 0 #232325bf;
		}

		.language-select-container {
			margin-right: 20px;
		}

		.header-toggler {
			order: 2;
		}

		.mantra-container {
			display: none;
		}
	}

	&.user-unauthorized {
		.unauthorized-menu {
			margin-left: auto;
			margin-right: 10px;
		}
	}

	&.user-data-loading {
		.language-select-container {
			margin-left: auto;
		}
	}
}

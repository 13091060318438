@import "styles/constants";

.post-job-page-wrapper {
    display: flex;
    flex-direction: column;

    > .page-subsection-header {
        margin: 1rem 0;
    }
}
.skill-button {
    width: 40%;
    align-self: center;
    margin: 1rem 0;
}

.fields-wrapper {
    margin: 1rem 0;
}

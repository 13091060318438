@import "styles/constants";

.icon-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &.icon-clickable {
        cursor: pointer;
    }
    &.icon-centered {
        justify-content: center;
    }

    > svg {
        width: 24px;
        height: 24px;
    }

    &.icon-xs > svg {
        width: 16px;
        height: 16px;
    }

    &.icon-sm > svg {
        width: 20px;
        height: 20px;
    }

    &.icon-lg > svg {
        width: 30px;
        height: 30px;
    }

    &.icon-xl > svg {
        width: 36px;
        height: 36px;
    }

    &.icon-xxl > svg {
        width: 42px;
        height: 42px;
    }

    &.icon-plain {
        stroke-width: 0;

        &.icon-primary > svg {
            fill: $iconPrimaryColor;
            stroke: $iconPrimaryColor;
        }

        &.icon-secondary > svg {
            fill: $iconSecondaryColor;
            stroke: $iconSecondaryColor;
        }

        &.icon-success > svg {
            fill: $iconSuccessColor;
            stroke: $iconSuccessColor;
        }

        &.icon-info > svg {
            fill: $iconInfoColor;
            stroke: $iconInfoColor;
        }

        &.icon-warning > svg {
            fill: $iconWarningColor;
            stroke: $iconWarningColor;
        }

        &.icon-danger > svg {
            fill: $iconDangerColor;
            stroke: $iconDangerColor;
        }

        &.icon-link > svg {
            fill: $iconLinkColor;
            stroke: $iconLinkColor;
        }

        &.icon-light > svg {
            fill: $iconLightColor;
            stroke: $iconLightColor;
        }
    }

    .icon-white-path {
        fill: $iconLightColor;
    }
}

.icon-red-path {
    fill: $colorDanger;
}

.st0 {
    fill: url(#SVGID_1_);
}
.st1 {
    fill: url(#SVGID_2_);
}
.st2 {
    fill: url(#SVGID_3_);
}
.st3 {
    fill: url(#SVGID_4_);
}
.st4 {
    fill: url(#SVGID_5_);
}
.st5 {
    fill: url(#SVGID_6_);
}
.st6 {
    fill: url(#SVGID_7_);
}
.st7 {
    fill: url(#SVGID_8_);
}
.st8 {
    fill: url(#SVGID_9_);
}
.st9 {
    fill: url(#SVGID_10_);
}
.st10 {
    fill: url(#SVGID_11_);
}
.st11 {
    fill: url(#SVGID_12_);
}
.st12 {
    fill: url(#SVGID_13_);
}
.st13 {
    fill: url(#SVGID_14_);
}
.st14 {
    fill: url(#SVGID_15_);
}
.st15 {
    fill: url(#SVGID_16_);
}
.st16 {
    fill: url(#SVGID_17_);
}
.st17 {
    fill: url(#SVGID_18_);
}
.st18 {
    fill: url(#SVGID_19_);
}
.st19 {
    fill: url(#SVGID_20_);
}
.st20 {
    fill: url(#SVGID_21_);
}
.st21 {
    fill: url(#SVGID_22_);
}
.st22 {
    fill: url(#SVGID_23_);
}
.st23 {
    fill: url(#SVGID_24_);
}
.st24 {
    fill: url(#SVGID_25_);
}
.st25 {
    fill: url(#SVGID_26_);
}
.st26 {
    fill: url(#SVGID_27_);
}
.st27 {
    fill: url(#SVGID_28_);
}
.st28 {
    fill: url(#SVGID_29_);
}
.st29 {
    fill: url(#SVGID_30_);
}
.st30 {
    fill: url(#SVGID_31_);
}
.st31 {
    fill: url(#SVGID_32_);
}
.st32 {
    fill: url(#SVGID_33_);
}
.st33 {
    fill: url(#SVGID_34_);
}
.st34 {
    fill: url(#SVGID_35_);
}
.st35 {
    fill: url(#SVGID_36_);
}
.st36 {
    fill: url(#SVGID_37_);
}
.st37 {
    fill: url(#SVGID_38_);
}
.st38 {
    fill: url(#SVGID_39_);
}
.st39 {
    fill: url(#SVGID_40_);
}
.st40 {
    fill: url(#SVGID_41_);
}
.st41 {
    fill: url(#SVGID_42_);
}
.st42 {
    fill: url(#SVGID_43_);
}
.st43 {
    fill: url(#SVGID_44_);
}
.st44 {
    fill: url(#SVGID_45_);
}
.st45 {
    fill: url(#SVGID_46_);
}
.st46 {
    fill: url(#SVGID_47_);
}
.st47 {
    fill: url(#SVGID_48_);
}
.st48 {
    fill: url(#SVGID_49_);
}
.st49 {
    fill: url(#SVGID_50_);
}
.st50 {
    fill: url(#SVGID_51_);
}
.st51 {
    fill: url(#SVGID_52_);
}
.st52 {
    fill: url(#SVGID_53_);
}
.st53 {
    fill: url(#SVGID_54_);
}
.st54 {
    fill: url(#SVGID_55_);
}
.st55 {
    fill: url(#SVGID_56_);
}
.st56 {
    fill: url(#SVGID_57_);
}
.st57 {
    fill: url(#SVGID_58_);
}
.st58 {
    fill: url(#SVGID_59_);
}
.st59 {
    fill: url(#SVGID_60_);
}
.st60 {
    fill: url(#SVGID_61_);
}
.st61 {
    fill: url(#SVGID_62_);
}
.st62 {
    fill: url(#SVGID_63_);
}
.st63 {
    fill: url(#SVGID_64_);
}
.st64 {
    fill: url(#SVGID_65_);
}
.st65 {
    fill: url(#SVGID_66_);
}
.st66 {
    fill: url(#SVGID_67_);
}
.st67 {
    fill: url(#SVGID_68_);
}
.st68 {
    fill: url(#SVGID_69_);
}
.st69 {
    fill: url(#SVGID_70_);
}
.st70 {
    fill: url(#SVGID_71_);
}
.st71 {
    fill: url(#SVGID_72_);
}
.st72 {
    fill: url(#SVGID_73_);
}
.st73 {
    fill: url(#SVGID_74_);
}
.st74 {
    fill: url(#SVGID_75_);
}
.st75 {
    fill: url(#SVGID_76_);
}
.st76 {
    fill: url(#SVGID_77_);
}
.st77 {
    fill: url(#SVGID_78_);
}
.st78 {
    fill: url(#SVGID_79_);
}
.st79 {
    fill: url(#SVGID_80_);
}
.st80 {
    fill: url(#SVGID_81_);
}
.st81 {
    fill: url(#SVGID_82_);
}
.st82 {
    fill: url(#SVGID_83_);
}
.st83 {
    fill: url(#SVGID_84_);
}
.st84 {
    fill: #ffffff;
}

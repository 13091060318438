@import "styles/constants";

.modal-dialog.candidate-notes-modal {
    .audio-note-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .audio-player {
            width: 100%;
        }

        .audio-control-icon {
            margin-left: 10px;
            padding: 5px;

            &:hover {
                background-color: $iconHoverColor;
            }
        }
    }
}

@import "styles/constants";

.departments-list {
    width: 80vw;

    .table-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

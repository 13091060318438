.server-error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;

    h2 {
        margin-bottom: 20px;
    }
}

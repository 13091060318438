@import "styles/constants";
@import "../../../../node_modules/rc-slider/assets/index.css";
@import "./DraggableLines/DraggableLines.scss";
@import "./Timelines/JobTimeline/JobTimeline.scss";
@import "./SkillsAndJobsDescription/SkillsAndJobDescription.scss";

.skill-journey-block-container {
    display: flex;
    flex-direction: column;
    margin: 5px;
    padding: 10px;
    border: 1px solid #a9a9a9;
    border-radius: 5px;
    background-color: #f9f9f9;
    justify-content: flex-start;
    &.left {
        flex-direction: column;
        width: 15%;
        background: #f9f9f9;
        max-height: 680px;
        min-height: 680px;
        position: relative;
    }
    &.center {
        width: 70%;
        min-width: 300px;
    }
    &.right {
        width: 15%;
        height: fit-content;
    }

    h6,
    h5,
    h4 {
        text-align: center;
    }
    .inner-block-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #a9a9a9;
        background-color: #ffffff;
        border-radius: 5px;
        padding: 10px;
        margin: 5px;
        height: 100%;
        &.scrolable-content {
            position: relative;
            justify-content: flex-start;
            overflow-y: scroll;
        }
    }
    .icon-container {
        width: fit-content;
        cursor: pointer;
        &#addPastJobButton {
            display: flex;
            align-self: center;
            margin: 1rem 0;
            z-index: 20;
        }
    }
    .description-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 5px 0;
        > h6 {
            align-self: center;
            margin-top: 1rem;
        }
    }

    .skills-map-component-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
        padding: 10px;
        margin: 5px;
        box-shadow: 0 0 0 1px #a9a9a9;
        background-color: #ffffff;
        border-radius: 5px;
        &.hidden {
            visibility: hidden;
        }
        h5 {
            text-align: center;
        }
    }
    .skill-timeline-container {
        height: 400px;
        max-height: 400px;
        min-height: 400px;
        position: relative;

        .icon-container {
            position: absolute;
            top: 10px;
            right: 10px;
        }
        > .inner-block-container {
            align-items: flex-start;
            justify-content: flex-start;
        }
    }
    .slider-mark {
        &.container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0;
        }
        > .label {
            width: max-content;
            margin: 0 0.5rem;
        }
    }
}
.handler-dark-block {
    position: absolute;
    background-color: #40dfcf;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 2;
    filter: blur(10px) opacity(30%);
}
.slider-tooltip {
    position: absolute;
    top: 0;
    left: 85%;
    transform: translate(-50%, -100%);
    z-index: 9999;
    font-size: 10px;
    line-height: 1.5;
    opacity: 0.9;
    user-select: none;
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out 150ms;
    &.active {
        visibility: visible;
        opacity: 1;
        top: -10px;
        &.skill-timeline-tooltip {
            top: 25px;
            left: -25px;

            span {
                &:before {
                    content: none;
                }
                &:after {
                    content: none;
                }
            }
        }
    }
    span {
        display: block;
        text-align: center;
        color: #000000;
        text-decoration: none;
        position: relative;
        font-weight: bold;
        user-select: none;
        padding: 3px 5px;
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px 5px 0;
            border-top-color: #373737;
            left: 50%;
            bottom: -25px;
            transform: rotate(180deg) translate(50%, 80%);
        }
        &:before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
            border-width: 5px 5px 0;
            border-top-color: #373737;
            left: 50%;
            bottom: 0;
            transform: translate(-50%, 100%);
        }
    }
}
.carousel {
    position: unset !important;
}

@import "styles/constants";

.modal-dialog.candidates-group-modal {
    .modal-title {
        display: flex;
        align-items: center;

        .icon-container {
            margin-right: 5px;
        }

        .group-name {
            margin-bottom: 0;
        }

        .badge {
            margin-left: 8px;
        }
    }

    .modal-content {
        max-height: calc(100vh - 150px);
    }

    .modal-body {
        max-height: 600px;
        overflow-y: auto;
    }

    .candidates-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .candidate-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            padding: 5px 10px;
            margin: 0 10px;
            margin-bottom: 10px;
            width: 130px;
            max-width: 130px;
            text-align: center;

            .remove-candidate-icon {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}

@media (min-width: 768px) {
    .modal-dialog.candidates-group-modal {
        max-width: 700px;
    }
}


@media (min-height: 768px) {
    .modal-dialog.candidates-group-modal {
        .modal-body {
            min-height: 400px;
        }
    }
}
// Override default variables before the import
// You can find the complete list of Bootstrap’s variables in scss/_variables.scss

$primary: $colorPrimary;
$secondary: $colorSecondary;
$success: $colorSuccess;
$info: $colorInfo;
$warning: $colorWarning;
$danger: $colorDanger;
$link: $colorLink;
$light: $colorLight;
$columnBackgroundColor: #f9f9f9;
$cardBorder: 1px solid #a9a9a9;
$cardBoxShadow: 0 0 0 1px #a9a9a9;
$cardBorderRadius: 5px;
$cardBackgroundColor: #ffffff;
$cardPadding: 10px;
$cardMargin: 5px;

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

.modal-footer {
    &.content-center {
        justify-content: center;
    }

    &.content-space-between {
        justify-content: space-between;
    }
}

.modal-dialog {
    margin-top: 75px;
}

.input-group-prepend .btn,
.input-group-append .btn {
    z-index: initial;
}

.page-header {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 10px;
}

.page-subheader {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.page-subsection-header {
    border-bottom: 2px solid $colorPrimary;
    color: $colorPrimary;
    padding-bottom: 5px;
    width: 100%;
    margin-bottom: 15px;
}

.page-hint {
    text-align: center;
    font-size: 0.95rem;
    color: $secondaryFontColor;
    margin-bottom: 10px;
}

.subsection-hint {
    font-size: 0.95rem;
    color: $secondaryFontColor;
    margin-bottom: 10px;
}

.page-message {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    margin-bottom: 10px;

    &.content-center {
        justify-content: center;
    }

    &.content-space-between {
        justify-content: space-between;
    }

    &.form-buttons {
        padding: 0;
    }
}

.icon-button {
    padding: 5px;
}

.button-with-icon {
    display: flex;
    align-items: center;

    &.button-with-icon-right {
        .icon-container {
            padding-left: 5px;
        }
    }
}

.form-group-with-hint {
    position: relative;

    .form-group-hint-icon {
        position: absolute;
        left: -20px;
        top: 5px;
        cursor: pointer;
    }
}

table {
    &.clickable-rows {
        tbody tr {
            cursor: pointer;

            &:hover {
                background: $tableRowHoverColor;
            }
        }

        .table-cell-with-icon {
            &:hover {
                background: $tableRowHoverColor;
            }
        }
    }

    .table-cell-with-icon {
        width: 24px;
        cursor: pointer;

        &:hover {
            background: $tableRowHoverColor;
        }
    }

    .table-cell-align-right {
        text-align: right;
    }

    .table-cell-align-center {
        text-align: center;
    }

    .table-row-disabled {
        background-color: #e6e6e6;
    }
}

.col-input-with-right-icon {
    padding-right: 50px;
}

.input-right-icon {
    position: absolute;
    top: 6px;
    right: 16px;
}

.input-group-text-item-with-icon {
    padding: 0;
    background: #ffffff;
}

.input-field-changed {
    border-color: $fieldChanged;
}

.input-field-changed:focus {
    border-color: $fieldChanged;
    box-shadow: 0 0 0 0.2rem $fieldChangedBoxShadow;
}

.input-group-field-changed {
    .input-group-text {
        border-color: $fieldChanged;
    }

    input {
        border-top-color: $fieldChanged;
        border-bottom-color: $fieldChanged;
        border-right-color: $fieldChanged;
    }
}

a,
.btn-link {
    color: $colorLink;

    &:hover,
    &:focus {
        color: $linkHoverColor;
        text-decoration: none;
    }
}

.btn-link,
.btn-link:active,
.btn-link:focus {
    display: inline;
    vertical-align: initial;
    line-height: normal;
    box-shadow: none;
    padding: 0;
    border: 0;
    margin: 0;
}

.small-text {
    font-size: $smallFontSize;
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.text-bold {
    font-weight: bold;
}

.form-check {
    &.form-check-with-left-margin {
        margin-left: 10px;
    }

    &.form-check-with-inputs {
        .form-check-input {
            margin-top: 0.5rem;
        }

        .inputs-in-form-check-container {
            display: inline-block;
            max-width: 100%;
        }

        > label {
            max-width: 100%;
        }
    }
}

.react-tags {
    min-height: 38px;
    position: relative;
    padding: 0 4px;
    border: 1px solid #d1d1d1;
    border-radius: 0.2rem;
    display: flex;
    flex: 1;

    font-size: 1em;
    line-height: 1.2;

    cursor: text;
    display: flex;
    align-items: center;
}

.react-tags.is-focused {
    border-color: #b1b1b1;
}

.react-tags__selected {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;

    > .skill {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    > h5 {
        margin-top: 0;
    }
}

.react-tags__search {
    display: inline-block;

    padding: 4px 8px;

    max-width: 100%;
}

@media screen and (min-width: 30em) {
    .react-tags__search {
        position: relative;
    }
}

.react-tags__search-input {
    max-width: 100%;
    min-width: 200px;
    height: 30px;

    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    font-size: 1rem;
    line-height: 1.5;
}

.react-tags__search-input::-ms-clear {
    display: none;
}

.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;
}

@media screen and (min-width: 30em) {
    .react-tags__suggestions {
        width: 240px;
    }
}

.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}

.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}

.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}

.icon-container {
    &.inline-icon {
        display: inline;
    }
}

.content-divider {
    border-top: 1px solid $colorPrimary;
    display: flex;
    align-items: center;
    margin: 50px 0;
    box-shadow: 0 3px 5px 0 #aeaeae;
    width: 100%;
}

.root-container {
    display: flex;
    flex-direction: column;
    width: auto !important;

    .skills-map-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        overflow-y: auto;
        margin: $cardMargin;
        box-shadow: $cardBoxShadow;
        background-color: $cardBackgroundColor;
        border-radius: $cardBorderRadius;
        border: $cardBorder;

        > .legend {
            background-color: #cfcfcf;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $cardPadding;
            margin: 10px 0 0 0;
        }

        .info-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 5px;
            background-color: #878787;

            > h6 {
                color: white;
            }

            > div {
                display: flex;
                flex-direction: row;
                align-items: center;

                > button {
                    margin-right: 10px;
                    background-color: #75b444;
                    border-radius: 50px;
                }

                > h6 {
                    margin: 0 10px;
                    color: white;

                    > a {
                        color: #75b444;
                        text-decoration: underline;
                    }
                }
            }
        }

        .generated-info-wrapper {
            background-color: #878787;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            padding: 5px;

            > .contact-me {
                a {
                    color: #75b444;
                }
            }

            > h6 {
                color: white;
                margin: 5px 0;

                > b {
                    color: white;
                }

                > i > a {
                    color: white;
                    text-decoration: underline;
                }
            }
        }
    }
}

.loading-wrapper {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.nav-link {
    white-space: nowrap;
}

.loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.static-link {
    margin: 10px 5px;
}

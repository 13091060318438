@import "styles/constants";

.applicants-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  .sorting-menu {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .sorting-menu-buttons {
      display: flex;
      margin-left: 5px;

      .sorting-menu-button {
        border: 1px solid $mainLightGreyColor;
        cursor: pointer;

        &:hover:not(.active) {
          background: #e8e8e8;
        }

        &.active {
          background: $mainLightColor;
        }

        &:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }

        &:not(:last-child) {
          border-right: 0;
        }

        .icon-container {
          padding: 5px;
        }
      }
    }
  }

  .hs-ss-ratio-container {
    display: flex;
    margin-bottom: 10px;

    .hs-ss-ratio-input {
      margin: 0 5px;
    }
  }

  .applicants-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: 200px;
    background: $mainLightColor;
    border: 1px solid $mainLightGreyColor;
    border-radius: 5px;
    padding: 10px;

    .applicant-card {
      margin: 5px;

      .applicant-photo-container {
        padding: 5px 25px;
        position: relative;
        display: flex;

        .remove-from-stack {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .applicant-name {
        text-align: center;

        &.selected {
          font-weight: bold;
        }
      }
    }
  }
}

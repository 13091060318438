@import "styles/constants";

$formWidth: 90vw;
$maxFormWidth: 400px;

.login {
	display: flex;
	flex-direction: column;
	align-items: center;

	.login-form {
		width: $formWidth;
		max-width: $maxFormWidth;
		margin: 60px 10px 15px 10px;
		padding: 30px;
		box-shadow: 0 2px 2px #0000004d;
		border: 1px solid #e7e7e7;
		background: #f7f7f7;
		border-radius: 5px;

		h4 {
			margin-bottom: 20px;
		}
	}

	.additional-actions {
		width: $formWidth;
		max-width: $maxFormWidth;
		margin-bottom: 10px;

		.additional-action-column {
			padding: 0 5px;
		}
	}
}

.jobs-lifecycle-line-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 2rem 1rem;
    padding: 2rem;
    padding-top: 0;
    background-color: #eff0ee;
    border-radius: 10px;
    border: 1px solid #dadada;
    > div {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    > h5 {
        margin: 1rem 0;
    }
}

.jobs-milestone {
    &.card {
        display: flex;
        border-radius: 10px;
        border: 2px solid #8aaa10;
        padding: 0.5rem;
        user-select: none;
        cursor: pointer;

        &.main {
            color: #8aaa10;
            font-weight: bold;
        }

        &:hover {
            transform: scale(1.05);
        }
    }
    &.divider {
        width: 3rem;
        height: 2px;
        background: linear-gradient(90deg, rgba(229, 230, 77, 1) 18%, rgba(138, 170, 16, 1) 70%);
    }
}

.jobs-lifecycle-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    > .arrow-container {
        display: flex;
        align-items: center;
        > .arrow {
            border: 1px solid #8aaa10;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            margin-left: -7px;
            &.right {
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
        }
    }
}

.jobs-filter-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 2rem 0;
    > form {
        width: 50%;
        > .row {
            justify-content: space-between;
        }
    }
}

.jobs-filters-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.jobs-filter-button {
    width: 100%;
    margin: 1rem 0;
}

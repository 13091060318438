@import "styles/constants";

$cardBorder: 1px solid #a9a9a9;
$cardBorderRadius: 5px;
$cardBackgroundColor: #ffffff;
$cardPadding: 10px;

.system-node-details {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;

    .current-node-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 5px;
        width: 100%;

        .current-node-name {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 100%;
            min-width: 0;

            h5 {
                margin-bottom: 0;
                overflow: hidden;
                max-width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                line-height: normal;
            }

            .back-to-list-button {
                white-space: nowrap;
            }
        }
    }

    .current-node-details-content {
        border: $cardBorder;
        background-color: $cardBackgroundColor;
        border-radius: $cardBorderRadius;
        padding: 15px;
        overflow-y: auto;
        margin-bottom: 10px;
        height: 100%;
    }

    .current-node-controls {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .description-textarea {
        height: 110px;
        resize: none;
    }

    .system-node-source-row .system-node-source {
        margin-top: 9px;
        font-size: 14px;
    }
}

.synonyms-items-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 15px;
    margin-bottom: 25px;

    .synonyms-item {
        display: flex;
        align-items: center;
        min-height: 32px;
        max-width: 100%;
        padding: 2px 6px 2px 12px;
        margin: 3px;

        .synonyms-item-delete-icon {
            margin-left: 3px;
        }
    }

    .synonyms-item-content-editable {
        max-width: calc(100% - 16px);
        outline: 0;
        min-width: 10px;

        &:focus {
            box-shadow: 0 0 0 1px rgba(212, 212, 212, 0.5);
        }
    }

    .synonyms-item-add-icon {
        border: 2px solid $iconSuccessColor;
        border-radius: 20px;
        background: #f7f7f7;
        margin: 3px;
    }
}

.synonyms-items-lang-label {
    width: 20%;
}

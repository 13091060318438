@import "styles/constants";

$cardBorder: 1px solid #a9a9a9;
$cardBorderRadius: 5px;

.related-nodes {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0;

    .table-pagination .pagination {
        margin-top: 0;
        justify-content: center;
    }

    .related-nodes-table-container {
        width: 100%;
        min-height: 315px;
        height: 315px;
        max-height: 315px;
        overflow-y: auto;
        border: $cardBorder;
        border-radius: $cardBorderRadius;
        position: relative;
        padding: 10px 5px;
        margin-bottom: 10px;

        &.removed-nodes,
        &.added-nodes {
            min-height: initial;
            height: initial;
            max-height: 200px;
        }

        .table {
            margin-bottom: 0;
            table-layout: fixed;

            .related-node-type-cell {
                width: 58px;

                .node-source {
                    margin: 0;
                }
            }

            .related-node-name-cell {
                width: 100%;
                padding-top: 8px;
                padding-bottom: 8px;

                .related-node-name-cell-content {
                    .node-parent-cluster,
                    .node-domain {
                        color: $secondaryFontColor;
                        font-size: 12px;
                        white-space: nowrap;
                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .node-parent-cluster {
                        margin-bottom: 5px;
                    }

                    .node-name {
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }

                    .node-domain-badge,
                    .node-parent-cluster-badge {
                        font-weight: normal;
                        font-size: 12px;
                        display: inline;
                    }

                    .node-domain-badge {
                        background: #e7ffee;
                    }

                    .node-parent-cluster-badge {
                        background: #e7f4ff;
                    }
                }
            }

            .system-node-source-cell {
                width: 100px;
            }

            .related-node-action-cell {
                width: 48px;
            }

            .related-node-row {
                &.removed-node {
                    background: #ffdada;
                }

                &.added-node {
                    background: #ddffda;
                }
            }
        }
    }

    .nodes-filters {
        .filter-label {
            font-weight: normal;
        }
    }
}

@import "styles/constants";

.welcome-page {
    max-width: 1100px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 30px;
    white-space: pre-wrap;
    li {
        font-size: 16px;
    }
}

.main-color-text {
    color: $brainChainOliveColor;
}

.white-space-normal {
    white-space: normal;
}

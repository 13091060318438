@import "styles/constants";

.invitations-management {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.table-header-row-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.lock-person-info-icon-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;

  > div {
    margin-left: 10px;
  }
}

.table-compare-icon {
  margin-left: 10px;
}

.quick-compare-wrapper {
  display: flex;
  align-items: center;
}

.full-compare-icon-wrapper {
  vertical-align: middle !important;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      width: 40px;
      height: 40px;
    }
  }
}

.contact-text {
  white-space: pre;
  text-align: center;
}

.full-compare {
  width: 180px;
}

.custom-select-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    width: 105px;
  }
}

.date-wrapper {
  border-radius: 0.25rem;
  background-color: #b7cfe0;
  padding: 6px 12px;
  width: max-content;
  position: relative;

  > h6 {
    padding: 0;
    margin: 0;
  }

  .remove-date {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}

.plus-outline-icon {
  margin-top: 15px;
}

.date-input-wrapper {
  display: flex;
  border: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table-row {
  border-top: 1px solid #dee2e6;

  > td {
    border: none !important;
  }
}

.table-row-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-row-text-area-item {
  display: flex;
  align-items: center;
  justify-content: center;

  > textarea {
    max-width: 350px;
    width: 100%;
  }
}

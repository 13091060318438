@import "styles/constants";

.legend {
    display: flex;
    margin: 10px 0;
    &.vertical {
        align-self: flex-start;
    }
    .legend-item {
        display: flex;
        align-items: center;
        margin: 0 5px;
        cursor: default;

        .skill-level {
            width: 35px;
            min-width: 35px;
            height: 20px;
            border: solid 2px $skillNoLevelColor;
            border-radius: 10px;
            margin-right: 5px;
            background: $skillBackgroundColor;

            @each $skillLevelType in $skillLevelTypes {
                &.#{$skillLevelType} {
                    border-color: map-get($colorBySkillLevelType, $skillLevelType);

                    &.light-level {
                        border-color: map-get($colorSecondaryBySkillLevelType, $skillLevelType);
                    }
                }
            }

            &.perfect-match {
                border-color: $skillComparisonLevelPerfectColor;
            }

            &.lower-level {
                border-color: $skillComparisonLevelLowerColor;
            }

            &.missing-skill {
                border-color: $skillComparisonLevelMissingColor;
            }
        }

        .skill-status {
            width: 35px;
            min-width: 35px;
            height: 20px;
            border: solid 2px;
            border-radius: 10px;
            margin-right: 5px;
            background: $skillBackgroundColor;

            @each $skillStatus in $skillStatuses {
                &.#{$skillStatus} {
                    border-color: map-get($colorByStatusType, $skillStatus);
                }
            }
        }

        .not-moderated-icon {
            margin-right: 5px;
        }
    }

    .legend-parts-container {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        &.vertical {
            flex-direction: column;
         }
        .legend-part {
            display: flex;
            flex-direction: column;
            align-items: center;

            .legend-part-title {
                font-size: $smallFontSize;
                font-weight: 600;
            }

            .legend-part-content {
                display: flex;
            }
        }
    }
}

.description {
    height: 150px;
    width: 100%;
    max-height: 150px;
    min-height: 150px;
    margin: 20px 0;
    padding: 10px 0;
    > div {
        display: flex;
        justify-content: center;
        position: relative;
    }
}
.dots-controls-container {
    display: flex;
    margin-bottom: 0.5rem;
    align-self: center;

    .job-dot {
        width: 10px;
        height: 10px;
        margin: 0 0.5rem;
        margin-right: 0.2rem;
        border-radius: 10px;
        cursor: pointer;
        border: 1px solid #40e0d0;
        &.active {
            background-color: #40e0d0;
        }
    }
}

@import "styles/constants";

.employee-account-activation {
    max-width: $defaultFormWidth;
    margin-left: $defaultFormMargin;
    margin-right: $defaultFormMargin;
    margin-top: 30px;

    .employee-account-activation-header {
        margin-bottom: 20px;
    }

    .employee-account-activation-hint {
        margin-bottom: 15px;
    }
}

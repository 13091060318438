@import "styles/constants";

.user-photo {
    background-size: cover;
    width: 90px;
    height: 90px;
    min-width: 90px;
    min-height: 90px;
    border: 8px solid #f1f1f1;
    border-radius: 50%;

    &.is-dragging {
        z-index: 10001;
    }

    &.anonymity-mode {
        box-shadow: inset 0px 0px 2px 1px #00000080;
        background-color: #ffffff;
    }

    &.clickable {
        cursor: pointer;
    }

    &.selected {
        &.single-mode {
            border-color: #74cc80;
        }

        &.multiple-mode {
            border-color: #458afc;
        }
    }

    &.size-sm {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        border-width: 4px;
    }

    &.size-md {
        width: 90px;
        height: 90px;
        min-width: 90px;
        min-height: 90px;
        border-width: 8px;
    }

    &.size-lg {
        width: 120px;
        height: 120px;
        min-width: 120px;
        min-height: 120px;
        border-width: 10px;
    }
}

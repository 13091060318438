@import "styles/constants";

.job-wrapper {
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  max-width: 190px;
  width: 100%;
  position: relative;

  .selected {
    path {
      stroke: #458afc;
      fill: #458afc;
    }
  }

  .job-icon-wrapper {
    width: 90px;
    height: 90px;
    min-width: 90px;
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    > div, svg {
      width: 80px;
      height: 80px;
    }
  }

  .compare-icon-wrapper {
    cursor: pointer;
  }

  .compare-icon {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .job-info {
    text-align: center;
    width: 100%;
    white-space: pre;
    margin: 5px 0 0 0;
  }

  .job-title {
    margin: 0 0 10px 0;
  }

  .job-skills-wrapper {
    > .skill {
      display: inline-block;
      box-sizing: border-box;
      padding: 6px 8px;
      border: solid 2px #a9a9a9;
      border-radius: 30px;
      background-color: white;
      font-size: 10px;
      max-width: 70px;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@import "styles/constants";

.skill-comparison-popover {
    .actual-level,
    .required-level {
        font-weight: 600;

        @each $skillLevelType in $skillLevelTypes {
            &.#{$skillLevelType} {
                color: map-get($colorBySkillLevelType, $skillLevelType);
            }
        }

        &.missing-skill {
            color: $skillComparisonLevelMissingColor;
            font-weight: bold;
        }
    }

    .skill-title {
        font-weight: 600;
    }
}

@import "styles/constants";

$defaultFontColor: #212529;

.hard-skills-compare {
  display: flex;
  flex-direction: column;
  flex: 1;

  .skills-map-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    margin-right: 10px;
    box-shadow: 0 0 0 1px #a9a9a9;
    background-color: #ffffff;
    border-radius: 5px;
  }
}

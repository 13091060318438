@import "styles/constants";

.hard-skills-comparison {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;

    .candidate-info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        margin-bottom: 15px;

        .buttons-group {
            display: flex;

            .icon-container {
                margin: 0 5px;
            }
        }

        .user-photo {
            margin-left: 5px;
            margin-right: 10px;
        }

        .candidate-name {
            border-bottom: 1px solid $defaultFontColor;
        }

        .favorite-icon svg {
            fill: $favoriteColor;
        }
    }

    .skills-map-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        margin: 10px 5px;
        box-shadow: 0 0 0 1px #a9a9a9;
        background-color: #ffffff;
        border-radius: 5px;

        .skills-map {
            .skill {
                .notes-icon {
                    cursor: pointer;
                }
            }
        }
    }

    .legend {
        margin: 0;
    }

    .skills-map-controls {
        display: flex;
        align-items: center;
        justify-content: center;

        .controls-title {
            font-size: $smallFontSize;
            font-weight: 600;
        }

        .control-button {
            display: flex;
            align-items: center;
            font-size: $smallFontSize;
            cursor: pointer;
            margin-left: 10px;

            .icon-container {
                margin-right: 3px;
            }
        }
    }
}

@import "styles/constants";

.candidate-modal-header {
    .modal-title {
        display: flex;
        align-items: center;
    }

    .user-photo {
        margin-left: 5px;
        margin-right: 10px;
    }

    .candidate-buttons {
        .favorite-icon svg {
            fill: $favoriteColor;
        }
    }
}

@import "styles/constants";

#video {
    width: 250px;
    height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: -3;
}

.home-page {
    background-color: $colorBlack;

    &__header {
        color: $mainLightColor;
    }

    &__node-link {
        z-index: 4;
        color: $homePageNodeLinkColor;
        text-decoration: none;
        font-weight: bold;
    }

    &__video-container {
        position: fixed;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        z-index: -5;
    }

    &__icon-wrapper {
        display: flex;
        background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 75%);

        width: 70px;
        height: 70px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }

    &__node-content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__node {
        overflow: hidden;
        cursor: pointer;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: bold;
        width: 230px;
        height: 230px;
        border-radius: 50px;
    }

    &__edge-helper {
        fill: $mainLightColor;
        font-size: 14px;
        text-anchor: middle;
        z-index: 15;
        font-style: italic;
        font-weight: bold;

        &--active {
            filter: url(#activeHelperBg);
        }
    }

    &__edge-notifications {
        font-size: 14px;
        opacity: 1;
        fill: white;
        font-weight: bold;
        font-style: italic;
    }

    &__edge-notifications-bg {
        fill: $colorPrimary;
    }

    &__edge {
        stroke: "grey";
        stroke-width: 2;
        z-index: 100;
        white-space: pre-line;

        &--active {
            opacity: 1;
        }

        &--inactive {
            opacity: 1;
        }
    }
    &__edge-path {
        stroke-width: 1;
    }
}

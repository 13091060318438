.job-timeline-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;

    > .multiple-lines-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: inherit;
        width: 100%;
        z-index: 2;

        .arrow {
            border: solid #cccccc;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 4px;
            transform: rotate(-135deg);
            -webkit-transform: rotate(-135deg);
            position: absolute;
            top: -10px;
            left: 18px;
        }
    }
    > .label {
        padding: 5px;
        white-space: nowrap;
        margin: 1rem 0;
    }
}

@import "../../../styles/constants.scss";

.pending-application-container {
    position: relative;
    min-height: 400px;
    width: 100%;
    margin: 2rem;

    .deadline-line-container {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
        width: 100%;
        position: absolute;
        bottom: 0;
        > .deadline-line {
            width: 100%;
            height: 2px;
            background-color: black;
        }
    }

    > .balls-container {
        display: flex;
        flex-wrap: wrap;
        &.before-deadline {
            position: relative;
        }
    }
}
.feedback-container {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.7rem;
    margin: 2rem 0;
    > .icon-container {
        margin: 0 0.5rem;
        cursor: pointer;
    }
}

@import "styles/constants";

.candidate-job-specific-info-modal {
    .modal-body {
        padding: 0;
        display: flex;
    }

    .modes-buttons {
        border-right: 1px solid #dee2e6;

        .mode-button {
            padding: 20px;
            cursor: pointer;
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: none;
                box-shadow: 0px 1px 0px 0px #dee2e6;
            }

            &:hover:not(.active) {
                background-color: #f1f1f1;
            }

            &.active {
                background-color: #f1f1f1;
            }
        }
    }

    .modes-content {
        width: 100%;

        .mode-content {
            padding: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .video-player {
        width: 100%;
    }

    .audio-player {
        width: 100%;
    }
}

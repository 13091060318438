@import "../../../../styles/constants.scss";

.legend-container {
    width: 100%;
    position: relative;
    > h6 {
        position: absolute;
        top: -20px;
        background-color: white;
        width: fit-content;
        padding: 0 0.5rem;
    }
    > .legend-row {
        display: grid;
        grid-template-columns: 30% 70%;
        align-items: center;
        margin: 0.5rem 0;
        > .icon-container {
            width: 50px;
        }
        > .text {
            font-size: 0.7rem;
            margin: 0 1rem;
        }
        .possible-future {
            fill: transparent;
            stroke: $brainChainLimeColor;
            stroke-width: 6px;
        }
    }
}

@import "styles/constants";

.hard-skills-header {
  margin-bottom: 5px;

  .hard-skills-controls {
    display: flex;
    align-items: center;
    justify-content: center;

    .controls-title {
      font-size: $smallFontSize;
      font-weight: 600;
    }

    .control-button {
      display: flex;
      align-items: center;
      font-size: $smallFontSize;
      cursor: pointer;
      margin-left: 10px;

      .icon-container {
        margin-right: 3px;
      }
    }
  }
}

@import "styles/constants";

.clickable {
    cursor: pointer;

    &:hover {
        background-color: #e0e0e0;
    }
}

.jobs-table {
    &__title-link {
        cursor: pointer;
        color: $colorPrimary;
    }
}

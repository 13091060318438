@import "styles/constants";

.brainchain-check-mark {
    .brainchain-icon {
        cursor: pointer;

        &.no-info {
            .icon-background {
                fill: #de7828;
            }
        }

        &.ok {
            .icon-background {
                fill: $colorPrimary;
            }
        }

        &.not-ok {
            .icon-background {
                fill: #e81223;
            }
        }
    }
}

.brainchain-check-mark-popover {
    .send-invitation-button {
        margin-top: 10px;
    }
}

@import "styles/constants";

.candidates-stack {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    .candidates-number {
        display: flex;
        align-items: center;

        .badge {
            margin: 0 5px;
        }
    }

    .sorting-menu {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .sorting-menu-buttons {
            display: flex;
            margin-left: 5px;

            .sorting-menu-button {
                border: 1px solid $mainLightGreyColor;
                cursor: pointer;

                &:hover:not(.active) {
                    background: #e8e8e8;
                }

                &.active {
                    background: $mainLightColor;
                }

                &:first-child {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }

                &:last-child {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }

                &:not(:last-child) {
                    border-right: 0;
                }

                .icon-container {
                    padding: 5px;
                }
            }
        }
    }

    .hs-ss-ratio-container {
        display: flex;
        margin-bottom: 10px;

        .hs-ss-ratio-input {
            margin: 0 5px;
        }
    }

    .candidates-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
        height: 100%;
        max-height: 100%;
        width: 100%;
        min-width: 200px;
        background: $mainLightColor;
        border: 1px solid $mainLightGreyColor;
        border-radius: 5px;
        padding: 10px;

        .candidate-card {
            margin: 5px;

            .candidate-photo-container {
                padding: 5px 25px;
                position: relative;
                display: flex;

                .favorite-icon {
                    position: absolute;
                    top: 0;
                    left: 0;

                    svg {
                        fill: $favoriteColor;
                    }
                }

                .top-x-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                .candidate-notes-icon {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                }

                .brainchain-check-mark {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }

            .candidate-name {
                text-align: center;

                &.selected {
                    font-weight: bold;
                }
            }
        }
    }
}

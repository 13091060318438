@import "styles/constants";

.skill-comparison-notes-popover {
    .popover-inner {
        width: 250px;
        max-height: 400px;
        overflow-y: auto;
    }

    .notes-group-title {
        font-weight: 600;
        text-align: center;
    }

    .list-group-item {
        padding: 10px;
    }

    .custom-property-title {
        color: #0012ff;
        font-size: 12px;
    }

    .notes-container {
        margin: 5px 0;
    }

    .note-date {
        color: $mainLightGreyColor;
        font-size: 12px;
    }
}

.modal-dialog.license-agreement-modal {
    max-width: 800px;

    .modal-header {
        .modal-title {
            margin-bottom: 0;
        }
    }

    .modal-body {
        height: 60vh;
        max-height: 700px;
        min-height: 300px;
        overflow-y: auto;
        padding: 25px;
        padding-top: 10px;
    }
}

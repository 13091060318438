.resize-element {
    display: flex;
    justify-content: center;
    height: 15px;
    cursor: ns-resize;
    position: absolute;
    left: 0;
    width: 100%;
    user-select: none;
    z-index: 2;
}

@import "styles/constants";

.reset-password {
    max-width: $defaultFormWidth;
    margin-left: $defaultFormMargin;
    margin-right: $defaultFormMargin;
    margin-top: 30px;

    h4 {
        margin-bottom: 20px;
    }
}

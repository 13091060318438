@import "styles/constants";

$formWidth: 90vw;
$maxFormWidth: 400px;

.resend-activation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .resend-activation-form {
        width: $formWidth;
        max-width: $maxFormWidth;
        margin: 15px 10px;
        padding: 30px;
        box-shadow: 0 2px 2px #0000004d;
        border: 1px solid #e7e7e7;
        background: #f7f7f7;
        border-radius: 5px;

        h5 {
            margin-bottom: 20px;
        }
    }

    .back-link-container {
        width: $formWidth;
        max-width: $maxFormWidth;
        display: flex;
    }
}

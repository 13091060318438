@import "styles/constants";

$columnBackgroundColor: #f9f9f9;
$cardBorder: 1px solid #a9a9a9;
$cardBorderRadius: 5px;
$cardPadding: 10px;

.applicants-comparison {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .applicants-comparison-content-container {
    display: flex;
    width: 100%;
    margin-bottom: 15px;

    .content-column {
      max-height: 100%;

      &.candidates-stack-column {
        padding: 0 5px 5px 5px;
        min-width: 200px;
        max-width: 260px;
      }

      &.details-column {
        padding: 0 5px 5px 0;
        max-width: calc(100% - 260px);
      }
    }

    .details-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: $cardBorder;
      background-color: $columnBackgroundColor;
      border-radius: $cardBorderRadius;
      height: 100%;
      max-height: 100%;
      position: relative;
      padding: 65px 110px 10px 110px;

      .dashboard-comparison {
        max-width: 100%;
        overflow-x: auto;
        height: 100%;
        display: flex;
        flex-direction: column;

        .applicants-info {
          display: grid;
          grid-auto-flow: column;
          grid-auto-columns: 250px;
          margin-bottom: 10px;
          position: sticky;
          top: 0;
          z-index: 2;

          .applicants-info-col {
            display: flex;
            align-items: center;
            border: 1px solid #a9a9a9;

            &:not(:last-child) {
              border-right: 0;
            }

            &.job-requirements {
              justify-content: center;
              background: #efefef;

              & + .applicant-info {
                border-left: 0;
              }
            }


            &.applicant-info {
              flex-direction: column;
              padding: 8px;
              background-color: #ffffff;
              position: relative;

              .applicant-controls {
                display: flex;
                margin-bottom: 5px;

                .buttons-group {
                  display: flex;
                  align-items: center;
                }

                .icon-container {
                  margin: 0 7px;
                }
              }

              .user-photo {
                margin: 0 5px;
              }

              .applicant-name {
                font-size: $smallFontSize;
                text-align: center;
                font-weight: 600;
                line-height: 1.2;
                width: 100%;
                padding: 0 25px;
                margin-bottom: 5px;
              }

              .remove-applicant-from-comparison {
                position: absolute;
                top: 0;
                right: 0;
              }
            }
          }
        }
      }
    }

    .applicants-stack-wrapper {
      display: flex;
      flex-direction: column;
      padding: $cardPadding;
      border: $cardBorder;
      background-color: $columnBackgroundColor;
      border-radius: $cardBorderRadius;
      height: 100%;
      max-height: 100%;
    }
  }
}

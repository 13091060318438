@import "styles/constants";

.nodes-graph {
    height: 100%;
    width: 100%;
    position: relative;

    .cytoscape-component {
        height: 100%;
        width: 100%;
    }

    .graph-overlay {
        width: 100%;
        height: 100%;
        z-index: 100;
        background: #ffffff;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 30px;
        border-radius: 5px;

        &.graph-loader {
            align-items: center;
            background: #f8f8f8;
            padding: 0;
        }
    }
}

@import "styles/constants";

$columnBackgroundColor: #f9f9f9;
$cardBorder: 1px solid #a9a9a9;
$cardBorderRadius: 5px;
$cardPadding: 10px;

.compare-modal {
  max-width: 1000px;

  .modal-header {
    > h5 {
      width: 100%;
    }
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > .legend {
      width: 100%;
    }

    .applicants-info {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 250px;
      margin-bottom: 10px;
      position: sticky;
      top: 0;
      z-index: 2;

      .applicants-info-col {
        display: flex;
        align-items: center;
        border: 1px solid #a9a9a9;

        &:not(:last-child) {
          border-right: 0;
        }

        &.job-requirements {
          justify-content: center;
          background: #efefef;

          & + .applicant-info {
            border-left: 0;
          }
        }


        &.applicant-info {
          flex-direction: column;
          padding: 8px;
          background-color: #ffffff;
          position: relative;

          .applicant-controls {
            display: flex;
            margin-bottom: 5px;

            .buttons-group {
              display: flex;
              align-items: center;
            }

            .icon-container {
              margin: 0 7px;
            }
          }

          .user-photo {
            margin: 0 5px;
          }

          .applicant-name {
            font-size: $smallFontSize;
            text-align: center;
            font-weight: 600;
            line-height: 1.2;
            width: 100%;
            padding: 0 25px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

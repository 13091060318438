@import "../../../../styles/constants.scss";

.job-ball-container {
    display: flex;
    width: 33%;
    position: relative;
    z-index: 1;
    &.disabled {
        width: 100%;
        > svg {
            cursor: unset;
        }
    }
    > svg {
        cursor: pointer;
    }
    .job-circle {
        fill: white;
        stroke: $colorWarning;
        stroke-width: 3px;
        &.possible-future {
            fill: white;
            stroke: $brainChainLimeColor;
            stroke-width: 6px;
        }
        &.active {
            fill: #975fa496;
        }
    }
    > .icon-container,
    .company-logo {
        position: absolute;
        &.red-notification-icon {
            top: 35px;
            left: 15px;
        }
        &.add-to-favorite-jobs {
            top: 22px;
            left: -8px;
            transform: scale(0.8);
        }
    }
    .remove-from-view-icon {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 26px;
        left: 37px;
    }
    > .company-logo {
        width: 32px;
        height: 32px;
        top: -20px;
        left: 9px;
    }
    > .icons-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 38px;
        > .icon-container {
            position: absolute;
            width: 18px;
            height: 18px;
            cursor: pointer;
        }
    }
}

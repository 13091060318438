.two-fa-login-label {
    margin-bottom: 20px;
}

.two-fa-login-modal-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.alert.two-fa-alert {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2000;
}
.page-container > .department-details {
    margin-top: 30px;
    width: 80vw;

    .department-info {
        margin-bottom: 20px;

        .edit-icon-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 8px;

            h6 {
                margin: 0 10px 0 0;
            }
        }
    }

    .buttons-container {
        padding: 0;
    }
}

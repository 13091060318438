.requests-status-alert {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 3px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000000;
    max-width: 90vw;
}

.request-loading-overlay {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #adadad42;
}

@import "styles/constants";

.soft-skills-comparison {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;

    .candidate-info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        margin-bottom: 15px;

        .buttons-group {
            display: flex;

            .icon-container {
                margin: 0 5px;
            }
        }

        .user-photo {
            margin-left: 5px;
            margin-right: 10px;
        }

        .candidate-name {
            border-bottom: 1px solid $defaultFontColor;
        }

        .favorite-icon svg {
            fill: $favoriteColor;
        }
    }

    .soft-skills-ffm {
        margin: 20px;
    }

    .soft-skills-test {
        margin: 20px;

        .soft-skills-test-content {
            margin-bottom: 15px;
        }
    }

    .desired-qualities {
        .desired-qualities-items {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .desired-qualities-item {
                border: 2px solid #7fbb8c;
                min-height: 32px;
                height: auto;
                border-radius: 16px;
                color: #6c757c;
                padding: 2px 12px;
                margin: 5px;
                cursor: default;

                &.matched {
                    background: #7fbb8c;
                    color: #ffffff;
                }
                &.missing {
                    border-color: #a9a9a9;
                }
            }
        }
    }

    .ffm-info {
        display: flex;
        align-items: center;
        justify-content: center;

        .ffm-info-icon {
            cursor: pointer;
            display: inline-block;
            margin-left: 5px;
        }
    }

    .ffm-data {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 15px;
    }
}

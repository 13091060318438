$mainDarkColor: #343a40;
$mainLightColor: #ffffff;
$mainLightGreyColor: #a9a9a9;
$mainBlueColor: #007bff;

$brainChainLimeColor: #75b444;
$brainChainOliveColor: #89aa11;

$colorPrimary: $brainChainOliveColor;
$colorSecondary: #6c757d;
$colorSuccess: $brainChainOliveColor;
$colorInfo: #0e9cd8;
$colorWarning: #ffc107;
$colorDanger: #d64021;
$colorLink: #007bff;
$colorLight: #efefef;
$colorBlack: #000000;

$linkHoverColor: #0056b3;

$defaultFontColor: #212529;
$secondaryFontColor: #808080;

$iconPrimaryColor: $colorPrimary;
$iconSecondaryColor: $colorSecondary;
$iconSuccessColor: $colorSuccess;
$iconInfoColor: $colorInfo;
$iconWarningColor: $colorWarning;
$iconDangerColor: $colorDanger;
$iconLinkColor: $colorLink;
$iconLightColor: $mainLightColor;

$fieldError: $colorDanger;
$fieldWarning: $colorWarning;
$fieldSuccess: $colorSuccess;
$fieldChanged: $mainBlueColor;
$fieldErrorBoxShadow: transparentize($fieldError, 0.75);
$fieldWarningBoxShadow: transparentize($fieldWarning, 0.75);
$fieldSuccessBoxShadow: transparentize($fieldSuccess, 0.75);
$fieldChangedBoxShadow: transparentize($fieldChanged, 0.75);

$skillFontFamily: Arial, Helvetica, sans-serif;
$defaultFontSize: 16px;
$smallFontSize: 14px;
$skillFontSize: $defaultFontSize;
$skillHeight: 32px;

$profileNodeColor: #d9c025;
$domainNodeColor: #cb2a7b;
$scNodeColor: #9fc643;
$skillNodeColor: #00acbe;

$defaultFormWidth: 700px;
$defaultFormMargin: 10px;

$skillBackgroundColor: #ffffff;
$skillNoLevelColor: $mainLightGreyColor;
$skillLevelNinjaColor: #333333;
$skillLevelSeniorColor: #ab00de;
$skillLevelMediorColor: #1e90ff;
$skillLevelJuniorColor: #f57900;
$skillLevelNinjaLightColor: rgba(51, 51, 51, 0.125);
$skillLevelSeniorLightColor: rgba(171, 0, 222, 0.125);
$skillLevelMediorLightColor: rgba(30, 144, 255, 0.125);
$skillLevelJuniorLightColor: rgba(245, 121, 0, 0.125);
$skillLevelNinjaSecondaryColor: #797979;
$skillLevelSeniorSecondaryColor: #e48efd;
$skillLevelMediorSecondaryColor: #aed7ff;
$skillLevelJuniorSecondaryColor: #ffcd9b;
$skillNoLevelBorderColor: #707070;
$skillLevelNinjaBorderColor: #a9a9a9;
$skillLevelSeniorBorderColor: #55006f;
$skillLevelMediorBorderColor: #125699;
$skillLevelJuniorBorderColor: #a54600;

$skillComparisonLevelPerfectColor: #00da35;
$skillComparisonLevelLowerColor: #f57900;
$skillComparisonLevelMissingColor: #ff2034;

$skillStatusNewColor: #40e0d0;
$skillStatusEvergreenColor: #75b444;
$skillStatusFadingColor: #333333;

$skillLevelTypes: "no-level", "level-ninja", "level-senior", "level-medior", "level-junior";
$skillStatuses: "status-new", "status-evergreen", "status-fading";

$colorBySkillLevelType: (
    "no-level": $skillNoLevelColor,
    "level-ninja": $skillLevelNinjaColor,
    "level-senior": $skillLevelSeniorColor,
    "level-medior": $skillLevelMediorColor,
    "level-junior": $skillLevelJuniorColor,
);
$colorSecondaryBySkillLevelType: (
    "no-level": $skillNoLevelColor,
    "level-ninja": $skillLevelNinjaSecondaryColor,
    "level-senior": $skillLevelSeniorSecondaryColor,
    "level-medior": $skillLevelMediorSecondaryColor,
    "level-junior": $skillLevelJuniorSecondaryColor,
);
$colorBySkillStatus: (
    "status-new": $skillStatusNewColor,
    "status-evergreen": $skillStatusEvergreenColor,
    "status-fading": $skillStatusFadingColor,
);
$colorByStatusType: (
    "status-new": $skillStatusNewColor,
    "status-evergreen": $skillStatusEvergreenColor,
    "status-fading": $skillStatusFadingColor,
);

$borderColorBySkillLevelType: (
    "no-level": $skillNoLevelBorderColor,
    "level-ninja": $skillLevelNinjaBorderColor,
    "level-senior": $skillLevelSeniorBorderColor,
    "level-medior": $skillLevelMediorBorderColor,
    "level-junior": $skillLevelJuniorBorderColor,
);

$skillComparisonLevelTypes: "perfect-match", "lower-level", "missing-skill";
$colorBySkillComparisonLevelType: (
    "perfect-match": $skillComparisonLevelPerfectColor,
    "lower-level": $skillComparisonLevelLowerColor,
    "missing-skill": $skillComparisonLevelMissingColor,
);

$skillEagernessLoveItLightColor: #e3f3d2;
$skillEagernessOkLightColor: #e6fffc;
$skillEagernessIfNeedBeLightColor: #ffeecb;

$skillRequirementCoefficientCrucialColor: #89aa11;
$skillRequirementCoefficientRequiredColor: #6c757c;
$skillRequirementCoefficientNiceToHaveColor: #c7c7c7;
$skillRequirementCoefficientCrucialLightColor: rgba(137, 170, 17, 0.125);
$skillRequirementCoefficientRequiredLightColor: rgba(108, 117, 124, 0.125);
$skillRequirementCoefficientNiceToHaveLightColor: rgba(199, 199, 199, 0.125);

$skillRequirementCoefficientTypes: "requirement-coefficient-crucial",
    "requirement-coefficient-required", "requirement-coefficient-nice-to-have";
$colorBySkillRequirementCoefficientType: (
    "requirement-coefficient-crucial": $skillRequirementCoefficientCrucialColor,
    "requirement-coefficient-required": $skillRequirementCoefficientRequiredColor,
    "requirement-coefficient-nice-to-have": $skillRequirementCoefficientNiceToHaveColor,
);

$skillIsAddedColor: $mainLightGreyColor;
$skillIsNotAddedColor: #7fbb8c;

$skillTextColor: #6c757c;
$skillSelectedTextColor: #ffffff;

$clusterBorderColor: $mainLightGreyColor;
$clusterSuggestedBorderColor: $skillIsNotAddedColor;
$clusterSelectedBackgroundColor: #eaeaea;
$skillsMapConnectorsColor: $mainLightGreyColor;

$boxedPageWidth: 1100px;

$tableRowHoverColor: rgba(201, 201, 201, 0.5);
$iconHoverColor: rgba(201, 201, 201, 0.5);

$favoriteColor: #ffa500;

$homePageNodeLinkColor: #fbdcd5;

$nodeStatusReferenceModel: $colorPrimary;
$nodeStatusNodeCemetery: #be2525;
$nodeStatusNewIncoming: #17bbab;
$nodeStatuses: "reference-model", "node-cemetery", "new-incoming";
$colorByNodeStatus: (
    "reference-model": $nodeStatusReferenceModel,
    "node-cemetery": $nodeStatusNodeCemetery,
    "new-incoming": $nodeStatusNewIncoming,
);

// ---------------------------------------------

$nodeSourceReferenceModel: $colorPrimary;
$nodeSourceNodeCemetery: #be2525;
$nodeSourceUser: #17bbab;
$nodeSourceStackExchange: #d857d4;
$nodeSourceONet: #333333;
$nodeSourceOther: #888888;
$nodeSourceBorderColor: #422c7b;

// TODO: remove colors
$nodeSourceHF1: #8b098e;
$nodeSourceSE: #d857d4;

// TODO: remove hf1 & se
$nodeSourceTypes: "reference-model", "node-cemetery", "user", "stack-exchange", "o-net", "other",
    "hf1", "se";
$colorByNodeSourceType: (
    "reference-model": $nodeSourceReferenceModel,
    "node-cemetery": $nodeSourceNodeCemetery,
    "user": $nodeSourceUser,
    "stack-exchange": $nodeSourceStackExchange,
    "o-net": $nodeSourceONet,
    "other": $nodeSourceOther,
    "hf1": $nodeSourceHF1,
    "se": $nodeSourceSE,
);

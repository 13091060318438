@import "styles/constants";

.jobs-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  .jobs-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    width: 100%;
    min-width: 200px;
    background: $mainLightColor;
    border: 1px solid $mainLightGreyColor;
    border-radius: 5px;
    padding: 10px;

    .job-card {
      margin: 5px;

      .job-icon-container {
        padding: 5px 25px;
        position: relative;
        display: flex;

        .selected {
          path {
            stroke: #458afc;
            fill: #458afc;
          }
        }

        .job-icon-wrapper {
          width: 90px;
          height: 90px;
          min-width: 90px;
          min-height: 90px;
          display: flex;
          align-items: center;
          justify-content: center;

          > div, svg {
            width: 80px;
            height: 80px;
          }
        }

        .favorite-icon {
          position: absolute;
          top: 0;
          left: 0;

          svg {
            width: 21px;
            height: 21px;
            fill: $favoriteColor;
          }
        }

        .remove-from-stack {
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .job-title {
        text-align: center;

        &.selected {
          font-weight: bold;
        }
      }
    }
  }
}

@import "styles/constants";

.modal-content {
  .modal-body {
    .with-bottom-margin {
      margin-bottom: 10px;
    }

    .dropdown-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > label {
        margin: 0 15px 0 0;
        width: 120px;
      }

      > div {
        width: 100%;
      }
    }
  }
}

@import "styles/constants";

$columnBackgroundColor: #f9f9f9;
$cardBorder: 1px solid #a9a9a9;
$cardBorderRadius: 5px;
$cardPadding: 10px;

.jobs-comparison {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .jobs-comparison-content-container {
        display: flex;
        width: 100%;
        margin-bottom: 15px;

        .content-column {
            max-height: 100%;

            &.jobs-stack-column {
                padding: 0 5px 5px 5px;
                min-width: 200px;
                max-width: 260px;

                .jobs-stack-wrapper {
                    display: flex;
                    flex-direction: column;
                    padding: $cardPadding;
                    border: $cardBorder;
                    background-color: $columnBackgroundColor;
                    border-radius: $cardBorderRadius;
                    height: 100%;
                    max-height: 100%;
                }
            }

            &.details-column {
                padding: 0 5px 5px 0;
                max-width: calc(100% - 260px);
            }
        }

        .details-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: $cardBorder;
            background-color: $columnBackgroundColor;
            border-radius: $cardBorderRadius;
            height: 100%;
            max-height: 100%;
            position: relative;
            padding: 65px 110px 10px 110px;

            .dashboard-comparison {
                max-width: 100%;
                overflow-x: auto;
                height: 100%;
                display: flex;
                flex-direction: column;

                .jobs-info {
                    display: grid;
                    grid-auto-flow: column;
                    grid-auto-columns: 250px;
                    margin-bottom: 10px;
                    position: sticky;
                    top: 0;
                    z-index: 2;

                    .jobs-info-col {
                        display: flex;
                        align-items: center;
                        border: 1px solid #a9a9a9;

                        &:not(:last-child) {
                            border-right: 0;
                        }

                        &.applicant-requirements {
                            justify-content: center;
                            background: #efefef;

                            & + .job-info {
                                border-left: 0;
                            }
                        }

                        &.job-info {
                            flex-direction: column;
                            padding: 8px;
                            background-color: #ffffff;
                            position: relative;

                            .job-controls {
                                display: flex;

                                .job-icon {
                                    margin: 0 10px;
                                }

                                .buttons-group {
                                    display: flex;
                                    align-items: center;

                                    > div {
                                        margin-right: 10px;
                                    }

                                    .favorite-icon svg {
                                        fill: $favoriteColor;
                                    }
                                }
                            }

                            .job-title {
                                font-size: $smallFontSize;
                                text-align: center;
                                font-weight: 600;
                                line-height: 1.2;
                                width: 100%;
                                padding: 0 25px;
                                margin: 5px 0;
                            }

                            .remove-applicant-from-comparison {
                                position: absolute;
                                top: 0;
                                right: 0;
                            }
                        }
                    }
                }

                .apply-all {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    margin-top: 20px;
                }

                .apply-buttons-wrapper {
                    display: grid;
                    grid-auto-flow: column;
                    grid-auto-columns: 250px;

                    .apply-buttons-label {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #a9a9a9;
                        border-right: 0;
                        background: #efefef;

                        h6 {
                            margin: 0;
                        }

                        & + .button-wrapper {
                            border-left: 0;
                        }
                    }
                }
            }
        }
    }

    .button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #a9a9a9;
        background-color: #ffffff;
        padding: 10px;

        &:not(:last-child) {
            border-right: 0;
        }
    }
}

.apply-media__dropzone {
    width: 100%;
    height: 50px;
    border: dashed black 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@import "styles/constants";

.radial-progress-bar-done-mark {
    margin-left: 5px;
    height: 20px;
    width: 20px;
    fill: #11ffe2;
}

.radial-progress-bar {
    transform: rotate(-90deg);
    margin-left: 5px;
    height: 20px;
    width: 20px;

    circle {
        stroke-width: 3px;
        stroke-linecap: round;
        fill: transparent;
    }

    .bar {
        stroke: #11ffe2;
    }
    .warning-circle {
        stroke-width: 5px;
        stroke: $colorDanger;
    }
}

$normalValueColor: #04cc40;
$defaultLevelColor: #1e90ff;
$notMatchLevelColor: #ff2034;
$lowMatchLevelColor: #f57900;
$perfectMatchLevelColor: #00da35;

.ffm-chart {
    .ffm-background {
        fill: #f1f1f1;
        stroke: #eeeeee;
        stroke-width: 1;
    }

    .ffm-empty-area {
        fill: white;
    }

    .ffm-normal-polygon {
        stroke: $normalValueColor;
        stroke-width: 7;
        stroke-dasharray: 0 16.33;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
    }

    .ffm-normal-point {
        fill: $normalValueColor;
        stroke: $normalValueColor;
        stroke-width: 3;
    }

    .ffm-actual-polygon {
        stroke: #3bcae4;
        stroke-width: 7;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
    }

    .ffm-actual-point {
        fill: #03a9f4;
    }

    .range-line {
        stroke: $defaultLevelColor;
        stroke-width: 10;

        &.not-match {
            stroke: $notMatchLevelColor;
        }

        &.low-match {
            stroke: $lowMatchLevelColor;
        }

        &.perfect-match {
            stroke: $perfectMatchLevelColor;
        }
    }

    .range-marker-default {
        fill: $defaultLevelColor;
    }

    .range-marker-not-match {
        fill: $notMatchLevelColor;
    }

    .range-marker-low-match {
        fill: $lowMatchLevelColor;
    }

    .range-marker-perfect-match {
        fill: $perfectMatchLevelColor;
    }
}

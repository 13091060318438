@charset "UTF-8";
.skills-map {
  max-width: 530px;
  width: 100%; }
  .skills-map.сentered {
    align-self: center; }
  .skills-map.with-secondary {
    max-width: 980px; }
  .skills-map .cluster {
    stroke: #a9a9a9;
    fill: none; }
    .skills-map .cluster.cluster-selected {
      fill: #eaeaea; }
    .skills-map .cluster.cluster-suggested {
      stroke: #7fbb8c; }
  .skills-map .cluster-text-container {
    color: #6c757c;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px; }
    .skills-map .cluster-text-container .text-container {
      white-space: nowrap;
      overflow: hidden; }
  .skills-map .secondary-skills-divider {
    stroke: #a9a9a9; }
  .skills-map .skill {
    display: flex;
    background: #ffffff;
    height: 32px;
    width: calc(100% - 10px);
    position: fixed;
    font-family: Arial, Helvetica, sans-serif; }
    .skills-map .skill .skill-text-container {
      max-width: 340px; }
  .skills-map .connector {
    stroke: #a9a9a9; }

@import "styles/constants";

.modal-content {
  .modal-body {
    .change-anchor-title {
      font-weight: 400;
    }
    .employee-dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > label {
        margin: 0 15px 0 0;
      }

      > div {
        width: 100%;
      }
    }
  }
}

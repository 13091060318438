@import "styles/constants";

.system-node-source {
    &.admin-source {
        background: #41a7ff;
    }

    &.user-source {
        background: #12da7d;
    }
}

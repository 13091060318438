@import "styles/constants";

$columnBackgroundColor: #f9f9f9;
$cardBorder: 1px solid #a9a9a9;
$cardBorderRadius: 5px;
$cardBackgroundColor: #ffffff;
$cardPadding: 10px;

.model-management {
    display: flex;
    flex-direction: column;
    align-items: center;

    .nav {
        .nav-link {
            cursor: pointer;
        }
    }

    .nav-tabs {
        width: 100%;
        justify-content: center;
    }

    .tab-content {
        min-height: 1px;
        height: 100%;
        width: 100%;
    }

    .tab-pane {
        height: 100%;
    }

    &__add-btn {
        background: none;
        border: none;
        border-radius: 50%;

        &:hover {
            background: none;
        }

        &:active {
            background: none;
        }
    }

    .model-management-content {
        display: flex;
        width: 100%;
        height: 100%;
        max-height: 100%;
        min-height: 0;

        .model-management-nodes-graph-and-list-col {
            padding: 5px;
        }

        .model-management-nodes-details-col {
            padding: 5px;
            padding-left: 0;
        }

        .model-management-nodes-graph-and-list {
            display: flex;
            flex-direction: column;
            padding: $cardPadding;
            border: $cardBorder;
            background-color: $columnBackgroundColor;
            border-radius: $cardBorderRadius;
            height: 100%;
            max-height: 100%;

            .nodes-graph-content {
                display: flex;
                flex-direction: column;
                height: 100%;
                min-height: 0;
                border: $cardBorder;
                background-color: $cardBackgroundColor;
                border-radius: $cardBorderRadius;
                margin-bottom: 5px;
                position: relative;

                .back-to-list-button {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    z-index: 1;
                }
            }

            .nodes-filters {
                border: $cardBorder;
                background-color: $cardBackgroundColor;
                border-radius: $cardBorderRadius;
                margin-bottom: 5px;
            }

            .nodes-legend {
                display: flex;
                justify-content: space-evenly;
                width: 100%;
                border: $cardBorder;
                background-color: $cardBackgroundColor;
                border-radius: $cardBorderRadius;
                padding: 5px;

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                .legend-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 14px;
                    white-space: pre-wrap;
                    width: 50%;

                    .node-type-short-name {
                        font-weight: bold;
                    }
                }
            }
        }

        .model-management-nodes-details {
            display: flex;
            flex-direction: column;
            padding: $cardPadding;
            border: $cardBorder;
            background-color: $columnBackgroundColor;
            border-radius: $cardBorderRadius;
            height: 100%;
            max-height: 100%;

            .nodes-actions {
                .nodes-actions-buttons {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 30px;

                    .btn {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .nodes-list-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
        min-height: 0;

        .table-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            border: $cardBorder;
            background-color: $cardBackgroundColor;
            border-radius: $cardBorderRadius;
        }

        .nodes-list {
            display: flex;
            flex-direction: column;
            height: 100%;
            min-height: 0;
            max-height: 100%;
            border: $cardBorder;
            background-color: $cardBackgroundColor;
            border-radius: $cardBorderRadius;
            padding: $cardPadding;
            position: relative;
            overflow-y: auto;
            margin-bottom: 5px;

            .table {
                margin-bottom: 0;
                table-layout: fixed;

                .system-node-type-cell {
                    width: 58px;

                    .node-source {
                        margin: 0;
                    }
                }

                .system-node-name-cell {
                    width: 100%;
                    padding-top: 8px;
                    padding-bottom: 8px;

                    .system-node-name-cell-content {
                        .node-parent-cluster,
                        .node-domain {
                            color: $secondaryFontColor;
                            font-size: 12px;
                            white-space: nowrap;
                            max-width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .node-parent-cluster {
                            margin-bottom: 5px;
                        }

                        .node-name {
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                        }

                        .node-domain-badge,
                        .node-parent-cluster-badge {
                            font-weight: normal;
                            font-size: 12px;
                            display: inline;
                        }

                        .node-domain-badge {
                            background: #e7ffee;
                        }

                        .node-parent-cluster-badge {
                            background: #e7f4ff;
                        }
                    }
                }

                .system-node-source-cell {
                    width: 100px;
                }

                .system-node-action-cell {
                    width: 48px;
                }
            }
        }
    }
}

.legend-connector {
    height: 2px;
    width: 50%;

    &__p-d {
        background-color: #d9c025;
    }

    &__d-sc {
        background-color: #cc2a7b;
    }

    &__sc-s {
        background-color: #a0c643;
    }

    &__s-s {
        height: 0;
        border-top: dashed #00acbe 4px;
    }

    &__subtitle {
        align-self: center;
        font-size: 16px;
    }

    &__arrow {
        color: #00acbe;
        font-size: 25px;
    }
}

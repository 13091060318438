@import "styles/constants";

.hard-skills {
  display: flex;
  flex-direction: column;
  align-items: center;

  .hard-skills-row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 250px;
    position: relative;

    .cluster-name {
      position: absolute;
      z-index: 1;
      color: white;
      background-color: #a9a9a9;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 0 5px;
      top: 0;
      font-size: 12px;
      left: 50%;
      transform: translate(-50%, 0);
      height: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% - 40px);
    }

    .cluster {
      border: 1px solid #a9a9a9;
      padding: 17px 5px;

      &:not(:last-child) {
        border-right: 0;
      }

      &.job-cluster {
        background: #efefef;

        & + .candidate-cluster {
          border-left: 0;
        }
      }

      &.candidate-cluster {
        background-color: #ffffff;
        position: relative;
        z-index: 0;
      }
    }

    &:not(:last-child) {
      .job-cluster {
        border-bottom: 0;
      }

      .candidate-cluster {
        border-bottom: 0;
      }
    }

    &:nth-child(2n) {
      .candidate-cluster {
        background-color: #efefef;
      }

      .job-cluster {
        background-color: #e0e0e0;
      }
    }
  }

  .skill {
    max-width: calc(100% - 10px);
    background: #ffffff;

    .notes-icon {
      cursor: pointer;
    }
  }
}

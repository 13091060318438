@import "styles/constants";

$color1: #969ca8;
$color2: #bdc2ce;
$color3: #f0f2f7;

$currentProfileColor: #75b444;
$alternativeJobsColor: #33dfff;
$futureJobsColor: #ffd700;
$skillColor: $currentProfileColor;

.job-compass {
    display: flex;
    align-items: center;
    flex-direction: column;

    .jc-content-container {
        display: flex;
        height: 100%;
        min-height: 0;
        width: 100%;
        justify-content: center;

        .left-col,
        .right-col {
            width: 20%;
            max-width: 20%;

            @media (min-width: 1500px) {
                max-width: 400px;
            }
        }

        .left-col {
            > button {
                width: 100%;
                margin-bottom: 10px;
            }
        }

        .right-col {
            display: flex;
            flex-direction: column;
        }

        .job-compass-col {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            max-width: 60%;
        }
    }

    .jc-menu {
        border: 2px solid #ced4da;
        background-color: #ffffff;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 15px;

        .jc-menu-divider {
            height: 2px;
            width: calc(100% + 20px);
            margin: 10px -10px;
            background-color: #ced4da;
        }

        &.alternative-settings {
            border-color: $alternativeJobsColor;

            .jc-menu-divider {
                background-color: $alternativeJobsColor;
            }
        }

        &.possible-future-jobs-menu {
            border-color: $futureJobsColor;
            max-height: 100%;
            min-height: 0;
        }

        &.common-settings {
            border: none;
            margin: 0;
            padding: 0;

            .jc-menu-divider {
                background-color: $alternativeJobsColor;
            }
        }
    }

    .domains-select {
        .domains-items-container {
            display: flex;
            flex-wrap: wrap;
        }

        .domain-item {
            border: 2px solid $alternativeJobsColor;
            background: #ffffff;
            border-radius: 15px;
            margin: 4px;
            padding: 2px 8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
            font-size: 14px;

            &.selected {
                background: $alternativeJobsColor;
            }
        }
    }

    .alternative-now-matching {
        .alt-job-matching-input {
            padding: 0;
            box-shadow: none;
        }

        .alt-job-matching-value {
            text-align: center;
        }
    }

    .alternative-now-black-list-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
            margin-bottom: 2px;
        }

        .icon-container {
            margin-left: 10px;
        }
    }

    .possible-future-jobs-menu {
        height: 100%;
        display: flex;
        flex-direction: column;

        .search-future-profiles-input-group {
            margin-bottom: 10px;
        }

        .possible-future-jobs-list {
            height: 100%;
            border: 1px solid #ced4da;
            background: #ffffff;
            border-radius: 5px;
            margin-bottom: 20px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;

            .search-profile-items-container {
                .search-profile-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px;
                }

                .profile-icons {
                    display: flex;

                    .icon-container {
                        margin-left: 5px;
                    }
                }

                .profile-data-container {
                    display: flex;
                    flex-direction: column;
                }

                .profile-badges-container {
                    display: flex;
                    flex-wrap: wrap;
                }

                .jobs-number-badge,
                .skills-number-badge {
                    font-weight: normal;
                    font-size: 12px;
                    display: inline;
                    margin-right: 10px;
                    margin-bottom: 5px;
                }

                .jobs-number-badge {
                    background: #fff3af;
                }

                .skills-number-badge {
                    background: #d4e7c4;
                }
            }
        }

        .legend-container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .jc-skill {
                max-width: max-content;
                margin-bottom: 5px;
                max-width: 100%;
            }
        }
    }

    .share-data-switch-container,
    .find-jobs-by-preferences-switch-container {
        display: flex;
        justify-content: center;

        .custom-control-label {
            margin-left: 10px;
        }
    }

    .job-compass-component {
        width: 850px;
        max-height: 100%;
        max-width: 100%;

        .jc-background {
            fill: #fdfdfd;
        }

        .jc-border {
            fill: $color2;
        }

        .jc-border-shadow {
            fill: $color1;
        }

        .jc-border-inside-shadow {
            fill: #f5f5f5;
        }

        .jc-inside-circle {
            fill: #e0e1e4;
        }

        .jc-small-arrows,
        .jc-big-arrows {
            .jc-arrows-dark-sides {
                fill: $color2;
            }

            .jc-arrows-light-sides {
                fill: $color3;
            }
        }

        .jc-needle {
            transform-origin: calc(50% - 30px) calc(50% - 5px);
            transition: all 0.5s ease-in-out;

            .jc-needle-north-side {
                fill: lighten($brainChainOliveColor, 15%);
            }

            .jc-needle-south-side {
                fill: #e0e0e0;
            }

            .jc-needle-shadow {
                opacity: 0.2;
            }

            .jc-needle-point {
                fill: #ffffff;
            }
        }
    }

    .jc-profile-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: fixed;
    }

    .jc-profile {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        height: 40px;
        max-height: 40px;
        background: #ffffff;
        border: 3px solid #a07f5d;
        border-radius: 50px;
        padding: 8px 16px;
        text-align: center;
        min-width: 100px;
        max-width: 180px;
        margin: 8px;

        .jc-profile-title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: bold;
            max-width: 127px;
            width: 100%;
        }

        .jc-profile-jobs-total {
            position: absolute;
            top: -10px;
            right: -10px;
            background: #219c0b;
            color: white;
            padding: 2px 7px;
            font-size: 12px;
            border-radius: 12px;
            font-weight: bold;
            min-width: 25px;
        }

        .jc-profile-remove-icon {
            position: absolute;
            bottom: -6px;
            right: -8px;
        }

        .jc-profile-compare-icon {
            position: absolute;
            bottom: -6px;
            left: -8px;
        }

        &.current {
            border-color: $currentProfileColor;
            background: $currentProfileColor;
            color: white;

            .jc-profile-jobs-total {
                background: darken($currentProfileColor, 15%);
            }
        }

        &.alternative {
            border-color: $alternativeJobsColor;
            background: $alternativeJobsColor;

            .jc-profile-jobs-total {
                background: darken($alternativeJobsColor, 15%);
            }
        }

        &.future {
            border-color: $futureJobsColor;
            background: $futureJobsColor;

            .jc-profile-jobs-total {
                background: darken($futureJobsColor, 15%);
            }
        }
    }

    .jc-skill-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .jc-skill {
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        height: 32px;
        max-height: 32px;
        background: #ffffff;
        border: 3px solid $skillColor;
        border-radius: 5px;
        padding: 2px 5px;
        text-align: center;
        min-width: 50px;
        max-width: 120px;

        &.broadening {
            border-style: dashed;
        }

        .jc-skill-title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .jc-connector {
        stroke: $currentProfileColor;
    }

    .user-photo {
        border-color: $currentProfileColor;
    }
}

.profile-jobs-popover {
    .popover-body {
        padding: 5px;
    }

    .popover-inner {
        min-width: 250px;
        max-width: 400px;
    }

    .jobs-items-container {
        max-height: 300px;
        overflow-y: auto;
        border: 1px solid #ced4dd;
        border-radius: 5px;

        .list-group-item {
            display: flex;
            padding: 6px 3px;
            align-items: center;
            justify-content: space-between;

            .item-content-container {
                display: flex;
                align-items: center;
            }

            .text-container {
                padding: 0 5px;
            }

            .icon-container {
                margin-right: 5px;
            }

            h6 {
                margin-bottom: 5px;
            }
        }
    }
}

.jc-skills-legend-popover {
    .popover-body {
        white-space: pre-wrap;
    }
}

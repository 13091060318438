.skill-node {
    border: solid 5px #00acbe !important;
}

.skill-cluster-node {
    border: solid 5px #9fc643 !important;
}

.domain-node {
    border: solid 5px #cb2a7b !important;
}

.profile-node {
    border: solid 5px #d9c025 !important;
}

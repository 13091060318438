@import "../../../../../styles/constants.scss";

.feedback-info-container {
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
    padding: 2rem 1rem 1rem 1rem;
    height: 150px;
    .feedback-inner {
        display: flex;
        align-items: center;
        > .meta-info {
            display: flex;
            flex-direction: column;
            border-right: 1px solid black;
            padding-right: 1rem;
            margin-right: 1rem;
            width: 40%;
        }
        > .feedback-info {
            width: 50%;
        }
        > button {
            height: 30%;
        }
    }
    > .carousel {
        width: 100%;
    }
}
.feedback-controls {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem;
    width: fit-content;
    border: 1px solid black;
    border-radius: 50px;
    background-color: #f9f9f9;
    position: absolute;
    height: 50px;
    top: -25px;
    left: 25%;
    > .icon-container {
        transform: scale(2);
        margin: 0 1rem;
        > svg {
            width: unset;
            height: unset;
        }
    }
    > .dots-controls-container {
        margin: 0;
    }
}

.table-pagination {
    ul.pagination {
        margin: 10px 0;

        .page-item.active.disabled .page-link {
            background: #a9a9a9;
            color: #ffffff;
        }
    }
}

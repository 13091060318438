@import "styles/constants";

.nodes-filters {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: fit-content;

    .filters-group {
        display: flex;

        &.search-group {
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    .filters-row {
        flex-wrap: nowrap;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .filter-label {
        margin-right: 10px;
    }

    .filter-select {
        width: 100%;
    }

    .select-node-status-option {
        display: flex;
        align-items: center;

        .system-node-status {
            margin: 0;
            margin-right: 5px;
        }
    }

    .input-select-node-status-multi-value {
        .system-node-status {
            margin: 3px;
        }
    }

    .nodes-sorting-mode-select {
        .select-single-value {
            max-width: calc(100% - 35px);
            margin-left: 25px;
        }
    }
}
